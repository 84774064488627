import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FagcTable from '../table/FagcTable'
import withModel from '../../hoc/table/withModel';
import Tag from '../../models/Tag';
import AddNewTag from '../common/AddNewButton';
import * as actions from '../../store/actions/index';
import withPermissions from '../../hoc/withPermissions';

const Table = withPermissions(withModel(FagcTable, Tag));

const TagsTable = ({ path }) => {
   const tagsLoaded = useSelector(state => state.tags.tagsLoaded);
   const tags = useSelector(state => state.tags.tags);
   const dispatch = useDispatch();

   useEffect(() => {
      if (!tagsLoaded) {
         dispatch(actions.tags.fetchTags('all'));
      }
   }, [tagsLoaded, dispatch]);

   return (
      <>
         <AddNewTag text="Add new tag" to={`${path}/new`} requiredPermissions={{ any: ['create_tag'] }} />
         <Table data={tags} loading={!tagsLoaded} actions={{
            eye: { path: data => `${path}/view/${data.id}`, permissions: { any: ['access_all_tags'] } },
            edit: { path: data => `${path}/edit/${data.id}`, permissions: { any: ['update_tag'] } },
         }} requiredPermissions={{ any: ['access_all_tags'] }} />
      </>
   );
}

export default TagsTable;