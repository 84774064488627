import { FETCH_CATEGORIES, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE, FETCH_CATEGORY, FETCH_CATEGORY_SUCCESS, FETCH_CATEGORY_FAILURE, ADD_NEW_CATEGORY, ADD_NEW_CATEGORY_SUCCESS, ADD_NEW_CATEGORY_FAILURE, UPDATE_CATEGORY, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_FAILURE, RESET_CATEGORIES_STATE } from '../actions/actionTypes';

const initialState = {
   categoriesLoaded: false,
   categories: [],
   categoryLoaded: false,
   category: null,
   errors: '',
}

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case FETCH_CATEGORIES:
         return {
            ...state,
            categoriesLoaded: false,
            categories: [],
            errors: '',
         }
      case FETCH_CATEGORIES_SUCCESS:
         return {
            ...state,
            categoriesLoaded: true,
            categories: action.categories,
         }
      case FETCH_CATEGORIES_FAILURE:
         return {
            ...state,
            errors: action.errors,
         }
      case ADD_NEW_CATEGORY:
         return {
            ...state,
            category: null,
            errors: '',
         }
      case ADD_NEW_CATEGORY_SUCCESS:
         return {
            ...state,
            categoriesLoaded: false,
            categories: [],
            category: action.category,
         }
      case ADD_NEW_CATEGORY_FAILURE:
         return {
            ...state,
            errors: action.errors,
         }
      case FETCH_CATEGORY:
         return {
            ...state,
            categoryLoaded: false,
            category: null,
            errors: ''
         }
      case FETCH_CATEGORY_SUCCESS:
         return {
            ...state,
            categoryLoaded: true,
            category: action.category,
         }
      case FETCH_CATEGORY_FAILURE:
         return {
            ...state,
            errors: action.errors,
         }
      case UPDATE_CATEGORY:
         return {
            ...state,
            errors: '',
         }
      case UPDATE_CATEGORY_SUCCESS:
         return {
            ...state,
            categoryLoaded: true,
            category: action.category,
         }
      case UPDATE_CATEGORY_FAILURE:
         return {
            ...state,
            errors: action.errors,
         }
      case RESET_CATEGORIES_STATE:
         return initialState;
      default:
         return state;
   }
}

export default reducer;