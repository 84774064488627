import { validate } from 'validate.js';

class GameDeal {
   source;
   link;
   body;
   image_1;
   image_2;
   image_3;
   id;
   content_id;
   type;
   title;
   description;
   keywords;
   categories;
   tags;
   media;
   projects;
   config;
   status;
   creation_datetime;
   update_datetime;
   scenario = scenarios.DEFAULT;
   labels = { 
		body : 'Body',
		link : 'Link',
		source : 'Source',
		image_1 : 'Image 1 (600 x 350 px)',
		image_2 : 'Image 2 (170 x 170 px)',
		image_3 : 'Image 2 (294 x 360 px)',
		id : 'ID',
		content_id : 'Content ID',
		type : 'Type',
		title : 'Title',
		description : 'Description',
		keywords : 'Keywords',
		categories : 'Categories',
		tags : 'Tags',
		media : 'Media',
		projects : 'Projects',
		config : 'Config',
		status : 'Status',
		creation_datetime : 'Created',
		update_datetime : 'Updated',
	};
   
   constructor (source, link, body, image_1, image_2, image_3, id, content_id, type, title, description, keywords, categories, tags, media, projects, config, status, creation_datetime, update_datetime) {
      this.source = source;
      this.link = link;
      this.body = body;
      this.image_1 = image_1;
      this.image_2 = image_2;
      this.image_3 = image_3;
      this.id = id;
      this.content_id = content_id;
      this.type = type;
      this.title = title;
      this.description = description;
      this.keywords = keywords;
      this.categories = categories;
      this.tags = tags;
      this.media = media;
      this.projects = projects;
      this.config = config;
      this.status = status;
      this.creation_datetime = creation_datetime;
      this.update_datetime = update_datetime;
   }

   isValid = () => {
      const defaultErr = validate(this, constraints[GameDeal.scenarios.DEFAULT]);
      if (this.scenario === GameDeal.scenarios.DEFAULT) return defaultErr;
      const err = validate(this, constraints[this.scenario]);
      if(!defaultErr && !err) return undefined;

      let allErrors = {...defaultErr};
      if (err) {
         Object.keys(err).forEach(key => {
            if(allErrors[key]) allErrors[key] = [...allErrors[key], ...err[key]];
            else allErrors[key] = err[key]; 
         });
      }

      return allErrors;
   }
}

const scenarios = {
   DEFAULT: "DEFAULT",
}

const constraints = {
    "DEFAULT": {
        "status": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "inclusion": {
                "within": [
                    "ACTIVE",
                    "INACTIVE"
                ],
                "message": "is invalid"
            }
        },
        "link": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be at most 45 symbols",
                "maximum": 1024
            }
        },
        "source": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be at most 1024 symbols",
                "maximum": 45
            }
        },
        "body": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be at most 30K symbols",
                "maximum": 30000
            }
        },
        "type": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "title": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be at most 2048 symbols",
                "maximum": 2048
            }
        },
        "description": {
            "length": {
                "message": "should be at most 4096 symbols",
                "maximum": 4096
            }
        },
        "keywords": {
            "length": {
                "message": "should be at most 512 symbols",
                "maximum": 512
            }
        }
    }
}

GameDeal.scenarios = scenarios;
GameDeal.constraints = constraints;

export default GameDeal;
