import React, { useState } from 'react';
import { Form, Segment, Grid, Label } from 'semantic-ui-react';

import GameTechnology from './GameTechnology';
import FileSelect from '../../common/FileSelect';
import Image from '../../common/Image';
import classes from '../Content.module.css';

const GameOptions = ({ loading, game, mode, validationErrors, onChange }) => {
   const [imageSrc, setImageSource] = useState();
   const [previewActive, setPreviewActive] = useState();
   const [mediaUrl, setMediaUrl] = useState();
   const [mediaText, setMediaText] = useState();

   const previewHandler = (name, value) => {
      setPreviewActive(name);
      if (value instanceof File) {
         const reader = new FileReader();
         reader.onload = event => setImageSource(event.target.result);
         reader.readAsDataURL(value);
      } else {
         if (value) {
            if(value.includes(".jpg") || value.includes(".png") || value.includes(".gif")) {
               setImageSource(value);
               setMediaUrl("");
               setMediaText("");
            }
            else {
               setImageSource("");
               if(value.includes(".mp4")) {
                  setMediaUrl(value);
                  setMediaText("Click for video preview");
               }
            }
         } else {
            setImageSource("");
            setMediaUrl("");
            setMediaText("");
         }
      }
   }

   const changeHandler = (event, data) => {
      onChange(data.name, data.value);
   }

   const mediaChangeHandler = (name, value) => {
      setPreviewActive(name);
      onChange(name, value);
   }

   const disabled = mode === 'view';
   return (
      <Form loading={loading} className={classes.Form}>
         <Form.Input label="Width:" type="text" name="width" value={game.width} onChange={changeHandler} error={validationErrors.width || null} disabled={disabled} />
         <Form.Input label="Height:" type="text" name="height" value={game.height} onChange={changeHandler} error={validationErrors.height || null} disabled={disabled} />
         <GameTechnology technology={game.technology} onChange={onChange} game_file={game.game_file || null} html5_zip={game.html5_zip || null} game_url={game.game_url} guide_urls={game.guide_urls} review_urls={game.review_urls} embed_config={game.embed_config} validationErrors={validationErrors} disabled={disabled} />
         <Form.TextArea label="Instructions:" name="instructions" value={game.instructions} onChange={changeHandler} error={validationErrors.instructions || null} disabled={disabled} />
         <Segment className={classes.MediaSegment}>
            <Label className={classes.ReviewLabel}>External videos:</Label>
            <Label className={classes.YouTubeNote} horizontal>
               NOTE: Insert youtube video id
            </Label>
            <Form.Input label="Youtube video 1:" type="text" name="youtube_video_1" value={game.youtube_video_1} onChange={changeHandler} error={validationErrors.youtube_video_1 || null} disabled={disabled} />
            <Form.Input label="Youtube video 2:" type="text" name="youtube_video_2" value={game.youtube_video_2} onChange={changeHandler} error={validationErrors.youtube_video_2 || null} disabled={disabled} />
            <Form.Input label="Youtube video 3:" type="text" name="youtube_video_3" value={game.youtube_video_3} onChange={changeHandler} error={validationErrors.youtube_video_3 || null} disabled={disabled} />
         </Segment>
         <Form.Field>
            <label>Media:</label>
            <Segment as="fieldset" className={classes.MediaSegment}>
               <Grid columns={2}>
                  <Grid.Column>
                     <FileSelect label="Video Walkthrough" name="video" value={game.video} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.video || null} disabled={disabled} />
                     <FileSelect label="Image 1 (100x100):" name="image_1" previewActive={previewActive === 'image_1'} value={game.image_1} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_1 || null} disabled={disabled} />
                     <FileSelect label="Image 2 (200x200):" name="image_2" previewActive={previewActive === 'image_2'} value={game.image_2} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_2 || null} disabled={disabled} />
                     <FileSelect label="Image 3 (260x320):" name="image_3" previewActive={previewActive === 'image_3'} value={game.image_3} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_3 || null} disabled={disabled} />
                     <FileSelect label="Image 4 (556x143):" name="image_4" previewActive={previewActive === 'image_4'} value={game.image_4} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_4 || null} disabled={disabled} />
                     <FileSelect label="Image 5 (300x250):" name="image_5" previewActive={previewActive === 'image_5'} value={game.image_5} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_5 || null} disabled={disabled} />
                     <FileSelect label="Screenshot 1 (425x235):" name="screenshot_1" previewActive={previewActive === 'screenshot_1'} value={game.screenshot_1} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.screenshot_1 || null} disabled={disabled} />
                     <FileSelect label="Screenshot 2:" name="screenshot_2" previewActive={previewActive === 'screenshot_2'} value={game.screenshot_2} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.screenshot_2 || null} disabled={disabled} />
                     <FileSelect label="Screenshot 3:" name="screenshot_3" previewActive={previewActive === 'screenshot_3'} value={game.screenshot_3} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.screenshot_3 || null} disabled={disabled} />
                     <FileSelect label="Screenshot 4:" name="screenshot_4" previewActive={previewActive === 'screenshot_4'} value={game.screenshot_4} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.screenshot_4 || null} disabled={disabled} />
                     <FileSelect label="Screenshot 5:" name="screenshot_5" previewActive={previewActive === 'screenshot_5'} value={game.screenshot_5} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.screenshot_5 || null} disabled={disabled} />
                     <FileSelect label="Screenshot 6:" name="screenshot_6" previewActive={previewActive === 'screenshot_6'} value={game.screenshot_6} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.screenshot_6 || null} disabled={disabled} />
                     <FileSelect label="Gif:" name="gif" previewActive={previewActive === 'gif'} value={game.gif} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.gif || null} disabled={disabled} />
                  </Grid.Column>
                  <Grid.Column textAlign="center" verticalAlign="middle">
                     <Image src={imageSrc} className={classes.PreviewImage} />
                     <a id="mediaUrl" href={mediaUrl} target="_blank" >{mediaText}</a>
                  </Grid.Column>
               </Grid>
            </Segment>
         </Form.Field>
      </Form>
   );
}

export default GameOptions;