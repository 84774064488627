import React from 'react';
import { Button } from 'semantic-ui-react';

import classes from './SubmitButton.module.css';

const SubmitButton = ({ content='Submit', disabled, submitHandler }) => {
   return (
      <Button className={classes.SubmitButton} disabled={disabled} onClick={submitHandler} content={content}></Button>
   );
}

export default SubmitButton;