import {
    FETCH_CDN_ZONES_SUCCESS,
    FETCH_CDN_ZONES_FAILURE,
    CDN_CLEAR_ZONE_SUCCESS,
    CDN_CLEAR_ZONE_FAILURE,
    CDN_CLEAR_FILES_SUCCESS,
    CDN_CLEAR_FILES_FAILURE,
    CDN_CLEAR_ZONE,
} from '../actions/actionTypes';

import { cloneClearCdn } from '../../utilities/common';

const emptyCdnData = { type: '', cdn_zone: '', urls: [] };
const initialState = {
    clearCdn: cloneClearCdn(emptyCdnData),
    cdnZonesLoaded: false,
    cdnZones: [],
    errors: '',
    messages: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CDN_ZONES_SUCCESS:
            return {
                ...state,
                cdnZones: action.cdnZones,
                cdnZonesLoaded: true,
                errors: '',
                messages: '',
            }
        case FETCH_CDN_ZONES_FAILURE:
            return {
                ...state,
                errors: action.errors,
            }
        case CDN_CLEAR_ZONE:
            return {
                ...state,
                errors: '',
                messages: '',
            }
        case CDN_CLEAR_ZONE_SUCCESS:
            return {
                ...state,
                messages: action.messages,
                errors: '',
            }
        case CDN_CLEAR_ZONE_FAILURE:
            return {
                ...state,
                errors: action.errors,
                messages: '',
            }
        case CDN_CLEAR_FILES_SUCCESS:
            return {
                ...state,
                messages: action.messages,
                errors: '',
            }
        case CDN_CLEAR_FILES_FAILURE:
            return {
                ...state,
                errors: action.errors,
                messages: '',
            }
        default:
            return state
    }
}

export default reducer;