import React, { useState } from 'react';
import { Form, Segment, Grid } from 'semantic-ui-react';

import FileSelect from '../../common/FileSelect';
import Image from '../../common/Image';
import classes from '../Content.module.css';

const ToolOptions = ({ tool, onChange, errors, validationErrors, loading, mode }) => {
   const toolTypeOptions = ['Iframe', 'Link'].map(item => ({ text: item, value: item.toLowerCase() }));
   const [imageSrc, setImageSource] = useState();
   const [previewActive, setPreviewActive] = useState();

   const previewHandler = (name, value) => {
      setPreviewActive(name);
      if (value instanceof File) {
         const reader = new FileReader();
         reader.onload = event => setImageSource(event.target.result);
         reader.readAsDataURL(value);
      } else {
         if (value) {
            setImageSource(value);

         } else {
            setImageSource('')
         }
      }
   }

   const changeHandler = (event, data) => {
      onChange(data.name, data.value);
   }

   const mediaChangeHandler = (name, value) => {
      setPreviewActive(name);
      onChange(name, value);
   }

   const disabled = mode === 'view';
   return (
      <Form loading={loading} className={classes.Form}>
         <Form.Input label="Width:" type="text" name="width" value={tool.width} onChange={changeHandler} error={validationErrors.width || null} disabled={disabled} />
         <Form.Input label="Height:" type="text" name="height" value={tool.height} onChange={changeHandler} error={validationErrors.height || null} disabled={disabled} />
         <Form.Input type="text" label="Link" name="link" value={tool.link} onChange={changeHandler} error={validationErrors.link || null} disabled={disabled} />
         <Form.Select label="Tool Type" name="render_type" options={toolTypeOptions} value={tool.render_type} onChange={changeHandler} error={validationErrors.render_type || null} disabled={disabled} />
         <Form.Field>
            <label>Media:</label>
            <Segment as='fieldset' className={classes.MediaSegment}>
               <Grid columns={2}>
                  <Grid.Column>
                     <FileSelect label="Image 1 (200x200):" name="image_1" previewActive={previewActive === 'image_1'} value={tool.image_1} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_1 || null} disabled={disabled} />
                     <FileSelect label="Image 2 (425x235):" name="image_2" previewActive={previewActive === 'image_2'} value={tool.image_2} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_2 || null} disabled={disabled} />
                  </Grid.Column>
                  <Grid.Column textAlign="center" verticalAlign="middle">
                     <Image src={imageSrc} className={classes.PreviewImage} />
                  </Grid.Column>
               </Grid>
            </Segment>
         </Form.Field>
      </Form>
   );
}

export default ToolOptions;