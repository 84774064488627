import React from 'react';

import AccessControl from '../../hoc/AccessControl';
import ContentCount from './ContentCount';
import ProjectCount from './ProjectCount';
import classes from './Dashboard.module.css';

const Dashboard = props => {
   return (
      <div className={classes.DashboardContainer}>
         <AccessControl requiredPermissions={{ any: ['access_all_contents'] }}>
            <ContentCount />
         </AccessControl>
         <AccessControl requiredPermissions={{ any: ['access_all_projects'] }}>
            <ProjectCount />
         </AccessControl>
      </div>
   );
}

export default Dashboard;