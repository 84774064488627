import React, { useState, useEffect } from 'react';
import { Container, Form, Divider, Message, List, Label, Icon } from 'semantic-ui-react';
import CdnCache from '../../models/ClearCdn';

import FagcTable from '../table/FagcTable';
import withModel from '../../hoc/table/withModel';
import withPermissions from '../../hoc/withPermissions';

import SubmitButton from '../common/SubmitButton';
import BackButton from '../common/BackButton';
import classes from './CdnCache.module.css';
import { formatValidationErrors, cloneCnd } from '../../utilities/common';
import * as actions from '../../store/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import ClearCdn from '../../models/ClearCdn';
import { cloneClearCdn, checkUrls } from '../../utilities/common';

const Table = withPermissions(withModel(FagcTable, CdnCache));

const CdnCacheForm = () => {

    const dispatch = useDispatch();
    const backendErrors = useSelector(state => state.cdn.errors);
    const confirmPurge = useSelector(state => state.cdn.messages);
    const newCdn = new ClearCdn('', '', []);
    newCdn.scenario = ClearCdn.scenarios.DEFAULT;
    let [validationErrors, setValidationErrors] = useState({ type: '', urls: '' });
    const { type, urls } = validationErrors;
    const [cdnPurge, setCdn] = useState(newCdn);
    const [title, setTitle] = useState('CDN Cache');
    const [urlsTextArea, setUrlsTextArea] = useState('');

    const cdnZonesLoaded = useSelector(state => state.cdn.cdnZonesLoaded);
    const cdnZones = useSelector(state => state.cdn.cdnZones.filter(cdnZones => urls ? cdnZones.urls === urls : true));

    const typeOptions = [
        'clear cache by resource',
        'clear cache by zone'
    ].map(item => ({ key: item, text: item, value: item }));

    useEffect(() => {
        if (urlsTextArea.length > 0) {
            const updatedCdn = cloneClearCdn(cdnPurge);
            updatedCdn.scenario = ClearCdn.scenarios.DEFAULT;
            updatedCdn['urls'] = urlsTextArea.split("\n").filter(function (item) {
                return item.length > 0;
            });
            setCdn(updatedCdn);
        }
    }, [urlsTextArea]);

    useEffect(() => {
        if (!cdnZonesLoaded) {
            dispatch(actions.cdn.fetchCdnZones());
        }
    }, [cdnZonesLoaded, dispatch]);

    const onSubmit = () => {
        const errors = cdnPurge.isValid();
        setValidationErrors(formatValidationErrors(errors || {}));
        if (errors) return;
        const urlValidation = checkUrls(cdnPurge);
        setValidationErrors(formatValidationErrors(urlValidation || {}));
        if (urlValidation) return;
        dispatch(actions.cdn.cdnClearFiles(cdnPurge));
    }

    const onChange = (name, value) => {
        setValidationErrors(formatValidationErrors({}));
        const updatedCdn = cloneClearCdn(cdnPurge);
        updatedCdn.scenario = ClearCdn.scenarios.DEFAULT;
        if (name === 'type') {
            setTitle('PURGE CloudFlare cache');
            updatedCdn['urls'] = [];
            setUrlsTextArea("");
        }
        if (name === 'urlsTA') {
            setUrlsTextArea(value);
        }
        updatedCdn[name] = value;
        setCdn(updatedCdn);

    }

    const onClickHandler = (zone) => {

        dispatch(actions.cdn.cdnClearZone(zone.zone_id));
    }

    return (
        <>
            <Container>
                <Divider horizontal>{title}</Divider>
            </Container>
            <Container className={classes.FormContainer} >

                <Form className={classes.Form}>
                    <Form.Select label="Type:" placeholder='Type' name="type" value={cdnPurge.type} selection options={typeOptions} onChange={(e, { name, value }) => onChange(name, value)} error={type || null} />
                    {(cdnPurge.type === 'clear cache by resource') ? (
                        <Form.TextArea label='CDN Cache Urls:' name="urlsTA" rows='30' value={urlsTextArea} onChange={(e, { name, value }) => onChange(name, value)} error={urls || null} />
                    ) : null
                    }
                    {(cdnPurge.type === 'clear cache by zone') ? (
                        <Table data={cdnZones} loading={!cdnZonesLoaded} pagination={{ active: 1, pageSize: 20 }} onClickActions={{
                            sync: { onClick: {onClickHandler}, permissions: { any: ['clear_cdn'] } },
                         }} requiredPermissions={{ any: ['clear_cdn'] }} />
                    ) : null
                    }
                </Form>

                <List>
                    <List.Item>
                        <Label className={classes.CdnCachExample} horizontal>
                            Clear cache by resource
                        </Label>
                        Urls should be absolute (example - https://example.com/mpqrtsa/dir/logo.png)
                    </List.Item>
                    <List.Item style={{display: "inline"}}>
                        <Label className={classes.CdnCachExample} horizontal>
                            Clear cache by zone
                        </Label>
                        Find project domain and click <Icon style={{"padding-left": "5px", "padding-right": "5px"}} name="sync" /> icon
                    </List.Item>
                </List>
                {backendErrors ? (
                    <Message
                        error
                        header='Error while purge CDN cache'
                        list={backendErrors.type || backendErrors.urls}
                    ></Message>
                ) : null}
                {confirmPurge ? (
                    <Message
                        success
                        header='Purge done successfully'
                    ></Message>
                ) : null}
                <Container textAlign="right">
                    <BackButton />
                    {(cdnPurge.type === 'clear cache by resource') ? (
                        <SubmitButton submitHandler={onSubmit} content='Purge CDN Cache' />
                    ) : null
                    }
                </Container>
            </Container>
        </>
    );
}

export default CdnCacheForm;