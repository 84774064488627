import User from '../models/User';
import Project from '../models/Project';
import Tag from '../models/Tag';
import Category from '../models/Category';
import Legal from '../models/LegalTemplate';
import Game from '../models/Game';
import GameDeal from '../models/GameDeal';
import GameNews from '../models/GameNews';
import GameWallpaper from '../models/GameWallpaper';
import Quiz from '../models/Quiz';
import Coupon from '../models/Coupon';
import Recipe from '../models/Recipe';
import Tool from '../models/OnlineTool';
import ClearCdn from '../models/ClearCdn';
import AllowedIp from '../models/AllowedIp';

export const parseSearchParams = searchString => {
   const entries = new URLSearchParams(searchString);
   const result = {}
   for (let param of entries) {
      result[param[0]] = param[1];
   }
   return result;
}

export const sortBy = (data, prop, order = 'ascending') => {
   if (!prop) return data;
   if (order === 'ascending') return data.sort((a, b) => (a[prop] > b[prop]) ? 1 : -1);
   if (order === 'descending') return data.sort((a, b) => (a[prop] > b[prop]) ? -1 : 1);
}

export const filterBy = (data, prop, value, caseInSensitive = true) => {
   return data.filter(item => {
      switch (typeof item[prop]) {
         case 'number':
            return item[prop] === Number(value);
         case 'string':
            return containesString(item[prop], value, caseInSensitive);
         case 'object':
            // handling Array search
            item[prop] = arrayElementsContaineString(item[prop], value, caseInSensitive);
            return !!item[prop].length;
         default:
            return false;
      }
   });
}

export const containesString = (value, match, caseInSensitive) => {
   let searchIn = value || '';
   if (caseInSensitive) {
      searchIn = searchIn && searchIn.toLowerCase();
      match = match && match.toLowerCase();
   }
   return searchIn.indexOf(match) > -1;
}

export const arrayElementsContaineString = (arr, match, caseInSensitive) => {
   return arr.filter(item => containesString(item, match, caseInSensitive))
}

export const filter = (data, filters) => {
   let filteredData = data.map(item => {
      const value = { ...item };
      if ('id' in value) value.id = parseInt(value.id);
      if ('factor_id' in value) value.factor_id = parseInt(value.factor_id);
      if ('content_id' in value) value.content_id = parseInt(value.content_id);
      if ('impressions' in value) value.impressions = parseInt(value.impressions);
      if ('project_id' in value) value.project_id = parseInt(value.project_id);
      return value;
   });
   for (const [key, value] of Object.entries(filters)) {
      filteredData = filterBy(filteredData, key, value);
   }
   return filteredData;
}

export const cloneUser = user => {
   return new User(user.id, user.username, user.password, user.status, user.first_name, user.last_name, user.email, user.permissions, user.note);
}

export const cloneProject = project => {
   return new Project(project.id, project.factor_id, project.name, project.tld, project.content_type, project.status, project.config, project.http_locked, project.supports_https);
}

export const cloneProjects = projectsData => {
   return projectsData.map(data => cloneProject(data));
}

export const cloneTag = tag => {
   return new Tag(tag.id, tag.name, tag.level, tag.description || '');
}

export const cloneLegal = legal => {
   return new Legal(legal.id, legal.type, legal.level, legal.title, legal.content, legal.content_types, legal.projects, legal.comment, legal.author, legal.version || '');
}

export const cloneIp = ip => {
   return new AllowedIp(ip.id, ip.ip, ip.description, ip.username, ip.creation_datetime, ip.update_datetime || '');
}

export const cloneTags = tagsData => {
   return tagsData.map(data => cloneTag(data));
}

export const cloneCategory = category => {
   return new Category(category.id, category.name, category.level);
}

export const cloneCategories = categoriesData => {
   return categoriesData.map(data => cloneCategory(data));
}

export const cloneGame = game => {
   return new Game(game.width, game.height, game.technology, game.instructions, game.image_1, game.image_2, game.image_3, game.image_4, game.image_5, game.screenshot_1, game.screenshot_2, game.screenshot_3, game.screenshot_4, game.screenshot_5, game.screenshot_6, game.gif, game.video, game.youtube_video_1, game.youtube_video_2, game.youtube_video_3, game.game_file, game.htmml5_zip, game.game_url, game.embed_config, game.review_urls, game.guide_urls, game.id, game.content_id, 'game', game.title, game.description, game.keywords, game.categories, game.tags, game.media, game.projects, game.config, game.status, game.creation_datetime, game.update_datetime);
}

export const cloneGames = gamesData => {
   return gamesData.map(data => cloneGame(data));
}

export const cloneGameDeal = gameDeal => {
   return new GameDeal(gameDeal.source, gameDeal.link, gameDeal.body, gameDeal.image_1, gameDeal.image_2, gameDeal.image_3, gameDeal.id, gameDeal.content_id, 'gamedeal', gameDeal.title, gameDeal.description, gameDeal.keywords, gameDeal.categories, gameDeal.tags, gameDeal.media, gameDeal.projects, gameDeal.config, gameDeal.status, gameDeal.creation_datetime, gameDeal.update_datetime);
}

export const cloneGameDeals = gameDealsData => {
   return gameDealsData.map(data => cloneGameDeal(data));
}

export const cloneGameNews = gameNews => {
   return new GameNews(gameNews.body, gameNews.source, gameNews.news_date, gameNews.image_1, gameNews.image_2, gameNews.image_3, gameNews.id, gameNews.content_id, 'gamenews', gameNews.title, gameNews.description, gameNews.keywords, gameNews.categories, gameNews.tags, gameNews.media, gameNews.projects, gameNews.config, gameNews.status, gameNews.creation_datetime, gameNews.update_datetime);
}

export const cloneAllGameNews = allGameNewsData => {
   return allGameNewsData.map(data => cloneGameNews(data));
}

export const cloneGameWallpaper = gameWallpaper => {
    return new GameWallpaper(gameWallpaper.image_thumb, gameWallpaper.image_hd, gameWallpaper.image_4k, gameWallpaper.id, gameWallpaper.content_id, 'gamewallpaper', gameWallpaper.title, gameWallpaper.description, gameWallpaper.keywords, gameWallpaper.categories, gameWallpaper.tags, gameWallpaper.media, gameWallpaper.projects, gameWallpaper.config, gameWallpaper.status, gameWallpaper.creation_datetime, gameWallpaper.update_datetime);
 }
 
 export const cloneGameWallpapers = gameWallpapersData => {
    return gameWallpapersData.map(data => cloneGame(data));
 }

export const cloneQuiz = quiz => {
   return new Quiz(quiz.quiz_type, quiz.quiz_script, quiz.quiz_link, quiz.image_1, quiz.image_2, quiz.image_3, quiz.image_4, quiz.id, quiz.content_id, 'quiz', quiz.title, quiz.description, quiz.keywords, quiz.categories, quiz.tags, quiz.media, quiz.projects, quiz.config, quiz.status, quiz.creation_datetime, quiz.update_datetime)
}

export const cloneQuizzes = quizzesData => {
   return quizzesData.map(data => cloneQuiz(data));
}

export const cloneCoupons = couponsData => {
   return couponsData.map(data => cloneCoupon(data));
}

export const cloneCoupon = coupon => {
   return new Coupon(coupon.render_type, coupon.link, coupon.image_1, coupon.image_2, coupon.id, coupon.content_id, 'coupon', coupon.title, coupon.description, coupon.keywords, coupon.categories, coupon.tags, coupon.media, coupon.projects, coupon.config, coupon.status, coupon.creation_datetime, coupon.update_datetime);
}

export const cloneRecipes = recipesData => {
   return recipesData.map(data => cloneRecipe(data));
}

export const cloneRecipe = recipe => {
   return new Recipe(recipe.ingredients, recipe.instructions, recipe.image_1, recipe.image_2, recipe.image_3, recipe.image_4, recipe.embed_video, recipe.id, recipe.content_id, recipe.type, recipe.title, recipe.description, recipe.keywords, recipe.categories, recipe.tags, recipe.media, recipe.projects, recipe.config, recipe.status, recipe.creation_datetime, recipe.update_datetime);
}

export const cloneTools = toolsData => {
   return toolsData.map(tool => cloneTool(tool));
}

export const cloneTool = tool => {
   return new Tool(tool.render_type, tool.link, tool.width, tool.height, tool.image_1, tool.image_2, tool.id, tool.content_id, 'apps', tool.title, tool.description, tool.keywords, tool.categories, tool.tags, tool.media, tool.projects, tool.config, tool.status, tool.creation_datetime, tool.update_datetime);
}

export const cloneClearCdn = cdn => {
   return new ClearCdn(cdn.type, cdn.cdn_zone, cdn.urls);
}

export const getUniqueId = () => '_' + Math.random().toString(36).substr(2, 9);

export const checkPermissions = (requiredPermissions, permissions) => {
   let result = false;
   result = requiredPermissions.any && checkAny(requiredPermissions.any, permissions);
   return result;
}

export const checkAny = (required, permissions) => {
   if (required.includes('public')) return true;

   return required.reduce((value, item) => {
      return value || permissions.includes(item);
   }, false);
}

export const formatValidationErrors = errors => {
   for (let field in errors) {
      if (Array.isArray(errors[field])) {
         errors[field] = errors[field].join(', ')
      }
   }
   return errors;
}

export const strip_tags = (str, allowed_tags) => {
   var key = '';
   var allowed = false;
   var matches = [];
   var allowed_array = [];
   var allowed_tag = '';
   var i = 0;
   var k = '';
   var html = '';
   var replacer = function (search, replace, str) {
      return str.split(search).join(replace);
   };
   // Build allowed tags associative array
   if (allowed_tags) {
      allowed_array = allowed_tags.match(/([a-zA-Z0-9]+)/gi);
   }
   str += '';
   // Match tags
   matches = str.match(/(<\/?[\S][^>]*>)/gi);
   // Go through all HTML tags
   for (key in matches) {
      // Save HTML tag
      html = matches[key].toString();
      // If tag is not in allowed list, remove from str!
      allowed = false;
      // Go through all allowed tags
      for (k in allowed_array) {
         allowed_tag = allowed_array[k];
         i = -1;
         if (i != 0) { i = html.toLowerCase().indexOf('<' + allowed_tag + '>'); }
         if (i != 0) { i = html.toLowerCase().indexOf('<' + allowed_tag + ' '); }
         if (i != 0) { i = html.toLowerCase().indexOf('</' + allowed_tag); }
         // Determine
         if (i == 0) {
            allowed = true;
            break;
         }
      }
      if (!allowed) {
         str = (html === '<li/>') ? replacer(html, "</li><li>", str) : str = replacer(html, "", str);
      }
   }
   return str;
}

export const formatFagcTableCellContent = data => {
   if (Array.isArray(data)) return data.join(', ');
   return data;
}

export const checkUrls = (cdn) => {
   let result = { urls: 'Some of urls are not valid ( urls: ' }
   let arrayUrls = [];
   if (cdn.type === 'cloudflare') {
      //const check = new RegExp('(http|https):\\/\\/www\\.[a-zA-Z]+\\.[a-zA-Z]+|www\\.[a-zA-Z]+\\.[a-zA-Z]+|[a-zA-Z]+\\.[a-z]+');
      const check = new RegExp('^(http|https):\\/\\/[a-zA-Z0-9]+[\\.a-zA-Z0-9]+\\.[a-z]+');
      Object.keys(cdn.urls).forEach(function (key, index) {
         if (!check.test(this[key])) {
            const line = index + 1;
            arrayUrls.push(line);
         }
      }, cdn.urls);
   }
   if (cdn.type === 'maxcdn') {
      const check = new RegExp('^\/[A-z].*');
      Object.keys(cdn.urls).forEach(function (key, index) {
         if (!check.test(this[key])) {
            const line = index + 1;
            arrayUrls.push(line);
         }
      }, cdn.urls);
   }

   result['urls'] = result['urls'] + formatFagcTableCellContent(arrayUrls) + ')';
   if (arrayUrls.length == 0) return;
   return result;
}

export const arrayToObject = items => items.reduce((acc, instruction, index) => {
   acc[index] = instruction;
   return acc;
}, {});

export const getDateBeforeNDays = (date, N) => {
   const newDate = new Date(date.getTime() - N*24*60*60*1000);
   const year = newDate.getFullYear();
   let month = newDate.getMonth() + 1;
   let day = newDate.getDate();
   if (month < 10) month = '0' + month;
   if (day < 10) day = '0' + day;
   return `${year}-${month}-${day}`
}
