import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Accordion, MenuItem, Label, Icon, Divider} from 'semantic-ui-react';

import classes from './SideMenu.module.css';
import withPermissions from '../../hoc/withPermissions';
import AccessControl from '../../hoc/AccessControl';

const getMenuItems = items => {
   return items.map((item, index) => {
      if(item.title === 'br') {
         return (
            <Divider key={item.key}/>
         );
      }
      else {
         return (
            <AccessControl requiredPermissions={item.permissions} key={item.to}>
               <Button as={NavLink} to={item.to} content={item.title} fluid />
            </AccessControl>
         );
      }
   });
};

export const SideMenuItem = ({ title, index, path, active, icon, menuItems, clickHandler }) => {
   if(menuItems.length !== 0) {
      return (
         <MenuItem key={path}>
            <Accordion.Title
               content={<Button icon>
                           <Icon name={icon} size='large'/>
                           <br style={{marginBottom: '8px'}} />
                           <Label horizontal content={title} className={classes.TitleLabel} />
                        </Button>
               
               }
               index={index}
               active={active}
               onClick={clickHandler}
               className={classes.Title}
            />

            <Accordion.Content active={active} content={(
               <Button.Group fluid vertical basic>
                  {getMenuItems(menuItems)}
               </Button.Group>
            )} />
         </MenuItem>
      );
   }

   if(menuItems.length === 0) {
      return (
         <MenuItem  className="noexpand" key={'one-item'}>
            <Accordion.Title
               content={
                  <Button as={NavLink} to={path} icon>
                     <Icon name={icon} size='large'/>
                     <br style={{marginBottom: '8px'}} />
                     <Label horizontal content={title} className={classes.TitleLabel} />
                  </Button>
               }
               index={index}
               active={active}
               onClick={clickHandler}
               className={classes.Title}
            />
         </MenuItem>
      );
   }

}

export default withPermissions(SideMenuItem);