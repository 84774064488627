import { validate } from 'validate.js';

class User {
   id;
   username;
   password;
   status;
   first_name;
   last_name;
   email;
   permissions;
   note;
   scenario = scenarios.DEFAULT;
   labels = { 
		id : 'ID',
		username : 'Username',
		first_name : 'First Name',
		last_name : 'Last Name',
		email : 'Email',
		note : 'Note',
		status : 'Status',
	};
   
   constructor (id, username, password, status, first_name, last_name, email, permissions, note) {
      this.id = id;
      this.username = username;
      this.password = password;
      this.status = status;
      this.first_name = first_name;
      this.last_name = last_name;
      this.email = email;
      this.permissions = permissions;
      this.note = note;
   }

   isValid = () => {
      const defaultErr = validate(this, constraints[User.scenarios.DEFAULT]);
      if (this.scenario === User.scenarios.DEFAULT) return defaultErr;
      const err = validate(this, constraints[this.scenario]);
      if(!defaultErr && !err) return undefined;

      let allErrors = {...defaultErr};
      if (err) {
         Object.keys(err).forEach(key => {
            if(allErrors[key]) allErrors[key] = [...allErrors[key], ...err[key]];
            else allErrors[key] = err[key]; 
         });
      }

      return allErrors;
   }
}

const scenarios = {
   DEFAULT: "DEFAULT",
   LOGIN: "LOGIN",
   CHANGEPASS: "CHANGEPASS",
   CREATE: "CREATE",
   UPDATE: "UPDATE",
}

const constraints = {
    "DEFAULT": {
        "status": {
            "inclusion": {
                "within": [
                    "ACTIVE",
                    "INACTIVE"
                ],
                "message": "is invalid"
            }
        },
        "email": {
            "format": {
                "pattern": "^([a-z0-9_\\.-]+)@([\\da-z\\.-]+)\\.([a-z\\.]{2,6})$",
                "message": "is invalid"
            },
            "length": {
                "message": "should be string at most 45 symbols",
                "maximum": 45
            }
        },
        "username": {
            "length": {
                "message": "should be string at most 45 symbols",
                "maximum": 45
            }
        },
        "password": {
            "length": {
                "message": "should be string at most 45 symbols",
                "maximum": 45
            }
        },
        "first_name": {
            "length": {
                "message": "should be string at most 45 symbols",
                "maximum": 45
            }
        },
        "last_name": {
            "length": {
                "message": "should be string at most 45 symbols",
                "maximum": 45
            }
        },
        "note": {
            "length": {
                "message": "should be string at most 1024 symbols",
                "maximum": 1024
            }
        }
    },
    "LOGIN": {
        "username": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "password": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        }
    },
    "CHANGEPASS": {
        "password": {
            "presence": {
                "allowEmpty": false,
                "message": "New is required"
            },
            "format": {
                "pattern": "^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*-.]).{8,45}$",
                "message": "should contain at least 8 characters. Must include numbers, spceial characters (!,@,#,$,%,^,&,*,- or .), lowercase latters and capital letters."
            }
        }
    },
    "CREATE": {
        "username": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "format": {
                "pattern": "^[A-Za-z][A-Za-z0-9]{4,44}$",
                "message": "can contain letters and numbers only, 5-44 chacracters. Must start with letter."
            }
        },
        "password": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "format": {
                "pattern": "^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*-.]).{8,45}$",
                "message": "should contain at least 8 characters. Must include numbers, spceial characters (!,@,#,$,%,^,&,*,- or .), lowercase latters and capital letters."
            }
        },
        "first_name": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "last_name": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "email": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "status": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "permissions": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        }
    },
    "UPDATE": {
        "username": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "format": {
                "pattern": "^[A-Za-z][A-Za-z0-9]{4,44}$",
                "message": "can contain letters and numbers only, 5-44 chacracters. Must start with letter."
            }
        },
        "first_name": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "last_name": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "email": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "status": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "permissions": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        }
    }
}

User.scenarios = scenarios;
User.constraints = constraints;

export default User;
