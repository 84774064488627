import { call, put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { processResponse } from '../../utilities/fetchUtils';
import { cloneIp } from '../../utilities/common';


export function* fetchIpsSaga() {
   try {
      const response = yield call(fetch, '/allowed-ip-service/get-ips', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         }, 
      });
      const ipsData = yield call(processResponse, response);
      //const legals = yield call(legalsData.data);
      yield put(actions.ips.fetchIpsSuccess(ipsData.data));
   } catch (e) {
      console.log(e);
      yield put(actions.ips.fetchIpsFailure(e.messages || e.message));
   }
}

export function* fetchIpSaga(action) {
   try {
      const response = yield call(fetch, '/allowed-ip-service/get-ip', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            id: action.id,
         })
      });
      const ipData = yield call(processResponse, response);
      const ip = yield call(cloneIp, ipData.data);
      yield put(actions.ips.fetchIpSuccess(ip));
   } catch (e) {
      console.log(e);
      yield put(actions.ips.fetchIpFailure(e.messages || e.message));
   }
}

export function* addNewIpSaga(action) {
   const { ip, description, username, creation_datetime, update_datetime } = action.ip;
   try {
      const response = yield call(fetch, '/allowed-ip-service/create-ip', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            ip,
            description,
         }),
      });
      const ipsData =  yield call(processResponse, response);
      //const legal = yield call(legalsData.data);
      yield put(actions.ips.addNewIpSuccess(ipsData.data));
      yield put(actions.app.redirectTo('/allowed-ips'));
   } catch (e) {
      console.log(e);
      yield put(actions.ips.addNewIpFailure(e.messages || e.message));
   }
}

export function* updateIpSaga(action) {
   const { id, ip, description, username, creation_datetime, update_datetime } = action.ip;
   try {
      const response = yield call(fetch, '/allowed-ip-service/update-ip', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            id,
            ip,
            description,
         }),
      });
      const ipData = yield call(processResponse, response);
      yield put(actions.ips.updateIpSuccess(ipData.data));
      yield put(actions.app.redirectTo('/allowed-ips'));
   } catch (e) {
      console.log(e);
      yield put(actions.ips.updateIpFailure(e.messages || e.message));
   }
}