import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FagcTable from '../table/FagcTable'
import withModel from '../../hoc/table/withModel';
import User from '../../models/User';
import AddNewUser from '../common/AddNewButton';
import * as actions from '../../store/actions/index';
import withPermissions from '../../hoc/withPermissions';

const Table = withPermissions(withModel(FagcTable, User));

const UserHome = ({ path }) => {
   const usersLoaded = useSelector(state => state.users.usersLoaded);
   const users = useSelector(state => state.users.users);
   const dispatch = useDispatch();

   useEffect(() => {
      if (!usersLoaded) {
         dispatch(actions.user.fetchUsers());
      }
   }, [usersLoaded, dispatch]);

   return (
      <>
         <AddNewUser text="Add new user" to={`${path}/new`} requiredPermissions={{ any: ['create_user'] }} />
         <Table data={users} actions={{
            eye: { path: data => `${path}/view/${data.id}`, permissions: { any: ['access_all_users'] } },
            edit: { path: data => `${path}/edit/${data.id}`, permissions: { any: ['update_user'] } },
         }} requiredPermissions={{ any: ['access_all_users'] }} />
      </>
   );
}

export default UserHome;