import { REDIRECT_TO, RESET_REDIRECT, ADD_APP_MESSAGES, DISMISS_APP_MESSAGES, CLEAR_APP_MESSAGES } from '../actions/actionTypes';

const initialState = {
   redirectTo: false,
   messages: [],
}

const reducer = (state = initialState, action) => {
   switch(action.type) {
      case REDIRECT_TO:
         return {
            ...state,
            redirectTo: action.path,
         }
      case RESET_REDIRECT: 
         return {
            ...state,
            redirectTo: false,
         }
      case ADD_APP_MESSAGES:
         return {
            ...state,
            messages: state.messages.concat(action.messages),
         }
      case DISMISS_APP_MESSAGES:
         let newMessages = state.messages;
         action.messageIds.forEach(id => {
            newMessages = newMessages.filter(msg => msg.id !== id);
         });
         return {
            ...state,
            messages: newMessages,
         }
      case CLEAR_APP_MESSAGES: 
         return {
            ...state,
            messages: [],
         }
      default:
         return state;
   }
}

export default reducer;