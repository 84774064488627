import React from 'react';
import { Menu } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

import classes from './Header.module.css';
import Logout from './LoggedUser';
import * as selectors from '../auth/authSelectors';

const Header = props => {
   const loggedUser  = useSelector(selectors.getLoggedUser);
   
   return (
      <Menu className={classes.header} fixed="top" inverted>
         <Menu.Menu position="right">
            <Logout loggedUser={loggedUser}/>
         </Menu.Menu>
      </Menu>
   );
}

export default Header;