import React from 'react';
import { Popup } from 'semantic-ui-react';

import Image from './Image';

const ImagePopup = props => {
   return (
      <Popup
         {...props}
      >
         {/* <Popup.Header>Image 1:</Popup.Header> */}
         <Popup.Content>
            <Image src={props.src} />
         </Popup.Content>
      </Popup>
   );
}

export default ImagePopup;