import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { cloneUser } from '../../utilities/common';
import User from '../../models/User';
import UserForm from './UserForm';
import * as actions from '../../store/actions/index';
import * as selectors from './userSelectors';

const EditUserForm = ({ mode }) => { 
   const userId = useParams().id;
    const selectedUser = useSelector(state => state.users.user);
    selectedUser.scenario = User.scenarios.UPDATE;
    const [user, setUser] = useState(selectedUser);
    const backendErrors = useSelector(state => state.users.errors);
    let [validationErrors, setValidationErrors] = useState(undefined);
    validationErrors = validationErrors || backendErrors;
    const title = mode === 'edit' ? 'Update user' : 'View user'
    const dispatch = useDispatch();
    const permissionsMap = useSelector(selectors.getPermissionsMap);

    useEffect(() => {
      dispatch(actions.user.fetchUser(userId));
      dispatch(actions.user.fetchPermissionsMap());
      return () => dispatch(actions.user.resetUsersState());
   }, []);

   useEffect(() => {
      setUser(selectedUser);
      user.scenario = User.scenarios.UPDATE;
   }, [selectedUser]);

   const updateUser = (field, value) => {
      const updatedUser = cloneUser(user);
      updatedUser[field] = value;
      updatedUser.scenario = User.scenarios.UPDATE;
      setUser(updatedUser);
   }

   const submitHandler = () => {
      const errors = user.isValid();
      setValidationErrors(errors);
      if (errors) return;
      dispatch(actions.user.updateUser(user));

  }

  const updatePermissions = (e, { checked, value }) => {
   let permissions;
   if (checked) {
      permissions = [...user.permissions, value]
   } else {
      permissions = user.permissions.filter(perm => perm !== value);
   }
   updateUser('permissions', permissions);
}

return (
   <>
      <UserForm formTitle={title} user={user} mode={mode} validationErrors={validationErrors} errors={backendErrors.global} permissionsMap={permissionsMap} onSubmit={submitHandler} onChange={updateUser} onPermissionsChange={updatePermissions} disabled={mode === 'view'}/>
   </>
);
}

export default EditUserForm;