import React from 'react';
import { Message } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';

import * as actions from '../../store/actions/index';
import classes from './ApplicationMessages.module.css';

const ApplicationMessages = props => {
   const appMessages = useSelector(state => state.app.messages);
   const dispatch = useDispatch();
   const dismissMessage = id => {
      dispatch(actions.app.dismissAppMessages([id]));
   }

   const messages = appMessages.map((item, index) => {
      return (
         <Message key={item.id} error={item.error} header={item.header} content={item.content} list={item.list} onDismiss={() => dismissMessage(item.id)}/>
      )
   });
   
   return (
      <div className={`${classes.messages_container} ${props.className}`}>
         {messages}
      </div>
   );
}

export default ApplicationMessages;