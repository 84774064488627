import { call, put } from 'redux-saga/effects';
import { processResponse } from '../../utilities/fetchUtils';
import * as actions from '../actions/index';

const noRedirect = actions.app.redirectTo(undefined);

export function* fetchAll(action, config) {
   const { endpointUrl, cloneFn, successActionFn, failureActionFn, redirectAction = noRedirect } = config;
   try {
      const response = yield call(fetch, endpointUrl, {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
      });
      const responseData = yield call(processResponse, response);
      const content = yield call(cloneFn, responseData.data);
      yield put(successActionFn(content, action.cType));
      yield put(redirectAction);
   } catch (e) {
      console.log(e);
      yield put(failureActionFn(e.messages || e.message, action.cType));
   }
}

export function* fetchOne(action, config) {
   const { endpointUrl, cloneFn, successActionFn, failureActionFn, redirectAction = noRedirect } = config;
   try {
      const response = yield call(fetch, endpointUrl, {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({ id: action.id }),
      });
      const responseData = yield call(processResponse, response);
      const content = yield call(cloneFn, responseData.data);
      yield put(successActionFn(content, action.cType));
      yield put(redirectAction);
   } catch (e) {
      console.log(e);
      yield put(failureActionFn(e.messages || e.message, action.cType))
   }
}

export function* updateContent(action, config) {
   const { endpointUrl, cloneFn, successActionFn, failureActionFn, redirectAction = noRedirect } = config;
   const {content, cType} = action;

   const formData = new FormData();
   Object.keys(content).forEach(key => {
      let value = content[key];
      if (Object.prototype.toString.call(value) === '[object Object]') {
         value = JSON.stringify(value);
      }

      if (Object.prototype.toString.call(value) === '[object Array]') {
        if(value.length > 0 && Object.prototype.toString.call(value[0]) === '[object Object]') {
            value = JSON.stringify(value);
        }
     }
      formData.append(key, value);
   });
   // delete FormData keys not needed
   formData.delete('scenario');
   formData.delete('labels');
   formData.delete('isValid');

   try {
      const response = yield call(fetch, endpointUrl, {
         method: 'POST',
         body: formData,
      });
      const responseData = yield call(processResponse, response);
      const content = yield call(cloneFn, responseData.data);
      yield put(successActionFn(content, cType));
      yield put(redirectAction);
   } catch (e) {
      console.log(e);
      yield put(failureActionFn(e.messages || e.message, cType));
   }
}

export function* newContent(action, config) {
   yield call(updateContent, action, config);
}