import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TagForm from './TagForm';
import Tag from '../../models/Tag';
import { cloneTag } from '../../utilities/common';
import * as actions from '../../store/actions/index';

const NewTagForm = props => {
   const newTag = new Tag(undefined, '', [], '');
   const [tag, setTag] = useState(newTag);
   tag.scenario = Tag.scenarios.CREATE;
   const backendErrors = useSelector(state => state.tags.errors);
   let [validationErrors, setValidationErrors] = useState(undefined);
   validationErrors = validationErrors || backendErrors;
   const dispatch = useDispatch();

   useEffect(() => {
      return () => dispatch(actions.tags.resetTagsState());
   }, []);

   const submitHandler = () => {
      const errors = tag.isValid();
      setValidationErrors(errors);
      if (errors) return;
      dispatch(actions.tags.addNewTag(tag));
   }

   const updateTag = (name, value) => {
      const updatedTag = cloneTag(tag);
      updatedTag[name] = value;
      setTag(updatedTag);
   }

   return (
      <TagForm title="Add new tag" tag={tag} errors={backendErrors.global} validationErrors={validationErrors} onChange={updateTag} onSubmit={submitHandler} />
   );
}

export default NewTagForm; 