import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import FagcTable from '../table/FagcTable';
import withModel from '../../hoc/table/withModel';
import Project from '../../models/Project';
import AddNewProject from '../common/AddNewButton';
import { parseSearchParams } from '../../utilities/common';
import * as actions from '../../store/actions/index';
import withPermissions from '../../hoc/withPermissions';

const Table = withPermissions(withModel(FagcTable, Project));

const ProjectsTable = ({ path }) => {
   const location = useLocation();
   const type = (parseSearchParams(location.search)).type;
   const projectsLoaded = useSelector(state => state.projects.projectsLoaded);
   const projects = useSelector(state => state.projects.projects.filter(project => type ? project.content_type === type : true));
   const dispatch = useDispatch();

   useEffect(() => {
      if (!projectsLoaded) {
         dispatch(actions.projects.fetchProjects());
      }
   }, [projectsLoaded, dispatch]);

   return (
      <>
         <AddNewProject text="Add new project" to={`${path}/new`} requiredPermissions={{ any: ['create_project'] }} />
         <Table data={projects} loading={!projectsLoaded} actions={{
            eye: { path: data => `${path}/view/${data.id}`, permissions: { any: ['access_all_projects'] } },
            edit: { path: data => `${path}/edit/${data.id}`, permissions: { any: ['update_project'] } },
            copy: { path: data => `${path}/clone/${data.id}`, permissions: { any: ['create_project'] } }
         }} requiredPermissions={{ any: ['access_all_projects'] }} />
      </>
   );
}

export default ProjectsTable;