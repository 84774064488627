import { call, put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { processResponse } from '../../utilities/fetchUtils';
import { cloneProject, cloneProjects } from '../../utilities/common';


export function* fetchProjectsSaga() {
   try {
      const response = yield call(fetch, '/project-service/get-all-projects',
         {
            method: 'POST',
            headers: {
               'Content-type': 'application/json'
            },
         });
      const projectsData = yield call(processResponse, response);
      const projects = yield call(cloneProjects, projectsData.data);
      yield put(actions.projects.fetchProjectsSuccess(projects));
   } catch (e) {
      console.log(e);
      yield put(actions.projects.fetchProjectsFailure(e.messages || e.message));
   }
}

export function* fetchProjectSaga(action) {
   try {
      const response = yield call(fetch, '/project-service/get-project', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            id: action.id,
         }),
      });
      const projectData = yield call(processResponse, response);
      const project = yield call(cloneProject, projectData.data);
      yield put(actions.projects.fetchProjectSuccess(project));
   } catch (e) {
      console.log(e);
      yield put(actions.projects.fetchProjectFailure(e.messages || e.message));
   }
}

export function* addNewProjectSaga(action) {
   const { name, tld, factor_id, content_type, status, http_locked, supports_https } = action.project;
   try {
      const response = yield call(fetch, '/project-service/create-project', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json'
         },
         body: JSON.stringify({
            name,
            tld,
            factor_id,
            content_type,
            status,
            http_locked,
            supports_https,
         }),
      });
      const projectData = yield call(processResponse, response);
      const project = yield call(cloneProject, projectData.data);
      yield put(actions.projects.addNewProjectSuccess(project));
      yield put(actions.app.redirectTo('/projects'));
   } catch (e) {
      console.log(e);
      yield put(actions.projects.addNewProjectFailure(e.messages || e.message));
   }
}

export function* updateProjectSaga(action) {
   const { id, name, tld, factor_id, content_type, status, http_locked, supports_https } = action.project;
   try {
      const response = yield call(fetch, '/project-service/update-project', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            id,
            name,
            tld,
            factor_id,
            content_type,
            status,
            http_locked,
            supports_https,
         }),
      });
      const projectData = yield call(processResponse, response);
      const project = yield call(cloneProject, projectData.data);
      yield put(actions.projects.updateProjectSuccess(project));
      yield put(actions.app.redirectTo('/projects'));
   } catch (e) {
      console.log(e);
      yield put(actions.projects.updateProjectFailure(e.messages || e.message));
   }
}

export function* cloneProjectSaga(action) {
   const { id, name, tld, factor_id, content_type, status, http_locked, supports_https } = action.project;
   try {
      const response = yield call(fetch, '/project-service/clone-project', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            id,
            name,
            tld,
            factor_id,
            content_type,
            status,
            http_locked,
            supports_https,         
         }),
      });
      const projectData = yield call(processResponse, response);
      const project = yield call(cloneProject, projectData.data);
      yield put(actions.projects.cloneProjectSuccess(project));
      yield put(actions.app.redirectTo('/projects'));
   } catch (e) {
      console.log(e);
      yield put(actions.projects.cloneProjectFailure(e.messages || e.message));
   }
}
