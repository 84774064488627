import {
   FETCH_USERS,
   FETCH_USERS_SUCCESS,
   FETCH_USERS_FAILURE,
   FETCH_USER_SUCCESS,
   FETCH_USER_FAILURE,
   CHANGE_PASSWORD,
   CHANGE_PASSWORD_SUCCESS,
   CHANGE_PASSWORD_FAILURE,
   ADD_NEW_USER_SUCCESS,
   ADD_NEW_USER_FAILURE,
   UPDATE_USER_SUCCESS,
   UPDATE_USER_FAILURE,
   FETCH_PERMISSIONS_MAP_SUCCESS,
   FETCH_PERMISSIONS_MAP_FAILURE,
   RESET_USERS_STATE
} from '../actions/actionTypes';

import User from '../../models/User';
import { cloneUser } from '../../utilities/common'

const emptyUserData = { id: '', username: '', password: '', status: 'ACTIVE', first_name: '', last_name: '', email: '', permissions: [], note: '' };
const initialState = {
   users: [],
   usersLoaded: false,
   user: cloneUser(emptyUserData),
   messages: '',
   errors: '',
   permissions: [],
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case FETCH_USERS_SUCCESS:
         return {
            ...state,
            users: action.users,
            usersLoaded: true,
         }
      case CHANGE_PASSWORD_SUCCESS:
         return {
            ...state,
            messages: action.messages,
            errors: '',
         }
      case CHANGE_PASSWORD_FAILURE:
         return {
            ...state,
            messages: '',
            errors: action.errors,
         }
      case ADD_NEW_USER_SUCCESS:
         return {
            ...state,
            user: action.user,
            errors: ''
         }
      case ADD_NEW_USER_FAILURE:
         return {
            ...state,
            messages: '',
            user: null,
            errors: action.errors
         }
      case UPDATE_USER_SUCCESS:
         return {
            ...state,
            user: action.user,
            errors: ''
         }
      case UPDATE_USER_FAILURE:
         return {
            ...state,
            messages: '',
            errors: action.errors
         }
      case FETCH_PERMISSIONS_MAP_SUCCESS:
         return {
            ...state,
            permissions: action.permissions,
         }
      case FETCH_PERMISSIONS_MAP_FAILURE:
         return {
            ...state,
            errors: action.errors
         }
      case FETCH_USER_SUCCESS:
         return {
            ...state,
            user: action.user,
         }
      case RESET_USERS_STATE:
         return initialState;
      default:
         return state
   }
}


export default reducer;