import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import authReducer from './store/reducers/auth';
import usersReducer from './store/reducers/user';
import appReducer from './store/reducers/app';
import projectsReducer from './store/reducers/projects';
import tagsReducer from './store/reducers/tags';
import categoriesReducer from './store/reducers/categories';
import legalsReducer from './store/reducers/legals';
import contentReducer from './store/reducers/content';
import cdnReducer from './store/reducers/cdn';
import ipsReducer from './store/reducers/ips';
import dashboardReducer from './store/reducers/dashboard';
import statReducer from './store/reducers/stat';

import { watchAuth, watchUsers, watchProjects, watchTags, watchCategories, watchLegals, watchContents, watchClearCdn, watchDashboard, watchStat, watchIps } from './store/sagas/index';

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
   app: appReducer,
   auth: authReducer,
   users: usersReducer,
   projects: projectsReducer,
   tags: tagsReducer,
   categories: categoriesReducer,
   legals: legalsReducer,
   contents: contentReducer,
   cdn: cdnReducer,
   ips: ipsReducer,
   dashboard: dashboardReducer,
   stat: statReducer,
});

const store = createStore(
   rootReducer,
   /* preloadedState, */
   composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchUsers);
sagaMiddleware.run(watchProjects);
sagaMiddleware.run(watchTags);
sagaMiddleware.run(watchCategories);
sagaMiddleware.run(watchLegals);
sagaMiddleware.run(watchContents);
sagaMiddleware.run(watchClearCdn);
sagaMiddleware.run(watchIps);
sagaMiddleware.run(watchDashboard);
sagaMiddleware.run(watchStat);

const app = (
   <Provider store={store} >
      <BrowserRouter>
         <App />
      </BrowserRouter>
   </Provider>
);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
