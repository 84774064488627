import { put, call } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { processResponse } from '../../utilities/fetchUtils';

export function* fetchCdnZonesSaga() {
    try {
        const response = yield call(fetch, '/clear-cdn-service/get-zones', {
            method: 'post',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify({}),
        });
        const cdnZonesData = yield call(processResponse, response);
        yield put(actions.cdn.fetchCdnZonesSuccess(cdnZonesData.data));
    } catch (e) {
        console.log(e);
        yield put(actions.cdn.fetchCdnZonesFailure(e.messages || e.message));
    }
}

export function* clearCdnZoneSaga(action) {
    try {
        const response = yield call(fetch, '/clear-cdn-service/clear-zone', {
            method: 'post',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                'zone_id': action.clearCdn
            }),
        });
        const cdnClearData = yield call(processResponse, response);
        yield put(actions.cdn.cdnClearZoneSuccess(cdnClearData.data));
    } catch (e) {
        console.log(e);
        yield put(actions.cdn.cdnClearZoneFailure(e.messages || e.message));
    }
}

export function* cdnClearFilesSaga(action) {
    const { type, cdn_zone, urls } = action.clearCdn;
    try {
        const response = yield call(fetch, '/clear-cdn-service/clear-files', {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                type,
                cdn_zone,
                urls,
            }),
        });
        const cdnClearData = yield call(processResponse, response);
        yield put(actions.cdn.cdnClearFilesSuccess(cdnClearData.status));
    } catch (e) {
        console.log(e);
        yield put(actions.cdn.cdnClearFilesFailure(e.messages || e.message));
    }
}