import React from 'react';
import AccessControl from './AccessControl';

const withPermissions = (Component) => {
   return ({ requiredPermissions, ...rest }) => {
      return (
         <AccessControl requiredPermissions={requiredPermissions}>
            <Component {...rest} />
         </AccessControl>
      );
   }
}

export default withPermissions