import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import Login from './Login';
import ForgotPassword from './ForgotPassword';
import * as selectors from './authSelectors';
import * as actions from '../../store/actions/index';




const Auth = props => {
   const authInProgress = useSelector(selectors.isAuthInProgress);
   const authMessages = useSelector(selectors.getAuthMessages);
   const authErrors = useSelector(selectors.getAuthErrors);
   const [userName, setUserName] = useState('');
   const [password, setPassword] = useState('');
   const [remember, setRemember] = useState(false);
   const [email, setEmail] = useState('');
   const dispatch = useDispatch();
   const location = useLocation();

   const usernameChangeHandler = (e, { name, value }) => {
      setUserName(value);
   }

   const passwordChangeHandler = (e, { name, value }) => {
      setPassword(value);
   }

   const rememberChangeHandler = (event, { checked }) => {
      setRemember(checked);
   }

   const emailChangeHandler = (event, { name, value }) => {
      setEmail(value);
   }

   const loginHandler = () => {
      dispatch(actions.auth.login(userName, password, remember));
   }

   const forgotPasswordHandler = () => {
      dispatch(actions.auth.passwordReset(email));
   }

   return (
      <Switch>
         <Route path="/login/forgot-password" >
            <ForgotPassword email={email} loading={authInProgress} onChange={emailChangeHandler} onSubmit={forgotPasswordHandler} successMsgs={authMessages && authMessages.global} errorMsgs={authErrors && authErrors.global} errors={{ email: authErrors && authErrors.email }} />
         </Route>
         <Route path="/login" exact>
            <Login username={userName} password={password} remember={remember} loading={authInProgress} errors={authErrors} basePath={location.pathname} onLogin={loginHandler} onUsernameChange={usernameChangeHandler} onPasswordChange={passwordChangeHandler} onRememberChange={rememberChangeHandler} />
         </Route>
      </Switch>
   ); 
}
 
export default Auth;