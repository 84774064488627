export const getPermissionsMap = state => {
   const map = {};
   state.users.permissions.forEach(element => {
      const permissionItem = { name: element['name'], description: element['description']}
      if (element['section'] in map) {
         map[element['section']]['items'].push(permissionItem)
      }
      else {
         map[element['section']] = { items: [permissionItem] };
      }
   });
   return map;
}