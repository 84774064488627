export const REDIRECT_TO = 'REDIRECT_TO';
export const RESET_REDIRECT = 'RESET_REDIRECT';
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";
export const AUTH_CHECK = "AUTH_CHECK";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const RESET_AUTH_STATE = "RESET_AUTH_STATE";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"; 
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const ADD_NEW_USER = "ADD_NEW_USER"; 
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAILURE = "ADD_NEW_USER_FAILURE";
export const UPDATE_USER = "UPDATE_USER"; 
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const FETCH_PERMISSIONS_MAP = "FETCH_USERS_PERMISSIONS";
export const FETCH_PERMISSIONS_MAP_SUCCESS = "FETCH_USERS_PERMISSIONS_SUCCESS";
export const FETCH_PERMISSIONS_MAP_FAILURE = "FETCH_USERS_PERMISSIONS_FAILURE";
export const RESET_USERS_STATE = 'RESET_USERS_STATE';
export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';
export const FETCH_PROJECT = 'FETCH_PROJECT';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE';
export const ADD_NEW_PROJECT = 'ADD_NEW_PROJECT';
export const ADD_NEW_PROJECT_SUCCESS = 'ADD_NEW_PROJECT_SUCCESS';
export const ADD_NEW_PROJECT_FAILURE = 'ADD_NEW_PROJECT_FAILURE';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';
export const RESET_PROJECTS_STATE = 'RESET_PROJECTS_STATE';
export const CLONE_PROJECT = 'CLONE_PROJECT';
export const CLONE_PROJECT_SUCCESS = 'CLONE_PROJECT_SUCCESS';
export const CLONE_PROJECT_FAILURE = 'CLONE_PROJECT_FAILURE';
export const ADD_APP_MESSAGES = 'ADD_APP_MESSAGES'
export const DISMISS_APP_MESSAGES = 'DISMISS_MESSAGES';
export const CLEAR_APP_MESSAGES = 'CLEAR_APP_MESSAGES';
export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';
export const FETCH_TAG = 'FETCH_TAG';
export const FETCH_TAG_SUCCESS = 'FETCH_TAG_SUCCESS';
export const FETCH_TAG_FAILURE = 'FETCH_TAG_FAILURE';
export const ADD_NEW_TAG = 'ADD_NEW_TAG';
export const ADD_NEW_TAG_SUCCESS = 'ADD_NEW_TAG_SUCCESS';
export const ADD_NEW_TAG_FAILURE = 'ADD_NEW_TAG_FAILURE';
export const UPDATE_TAG = 'UPDATE_TAG';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE';
export const RESET_TAGS_STATE = 'RESET_TAGS_STATE';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE';
export const ADD_NEW_CATEGORY = 'ADD_NEW_CATEGORY';
export const ADD_NEW_CATEGORY_SUCCESS = 'ADD_NEW_CATEGORY_SUCCESS';
export const ADD_NEW_CATEGORY_FAILURE = 'ADD_NEW_CATEGORY_FAILURE';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';
export const RESET_CATEGORIES_STATE = 'RESET_CATEGORIES_STATE';
export const FETCH_LEGALS = 'FETCH_LEGALS';
export const FETCH_LEGALS_SUCCESS = 'FETCH_LEGALS_SUCCESS';
export const FETCH_LEGALS_FAILURE = 'FETCH_LEGALS_FAILURE';
export const FETCH_LEGAL = 'FETCH_LEGAL';
export const FETCH_LEGAL_SUCCESS = 'FETCH_LEGAL_SUCCESS';
export const FETCH_LEGAL_FAILURE = 'FETCH_LEGAL_FAILURE';
export const ADD_NEW_LEGAL = 'ADD_NEW_LEGAL';
export const ADD_NEW_LEGAL_SUCCESS = 'ADD_NEW_LEGAL_SUCCESS';
export const ADD_NEW_LEGAL_FAILURE = 'ADD_NEW_LEGAL_FAILURE';
export const UPDATE_LEGAL = 'UPDATE_LEGAL';
export const UPDATE_LEGAL_SUCCESS = 'UPDATE_LEGAL_SUCCESS';
export const UPDATE_LEGAL_FAILURE = 'UPDATE_LEGAL_FAILURE';
export const RESET_LEGAL_STATE = 'RESET_LEGAL_STATE';
export const FETCH_CONTENTS = 'FETCH_CONTENTS';
export const FETCH_CONTENTS_SUCCESS = 'FETCH_CONTENTS_SUCCESS';
export const FETCH_CONTENTS_FAILURE = 'FETCH_CONTENTS_FAILURE';
export const FETCH_CONTENT = 'FETCH_CONTENT';
export const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS';
export const FETCH_CONTENT_FAILURE = 'FETCH_CONTENT_FAILURE';
export const ADD_NEW_CONTENT = 'ADD_NEW_CONTENT';
export const ADD_NEW_CONTENT_SUCCESS = 'ADD_NEW_CONTENT_SUCCESS';
export const ADD_NEW_CONTENT_FAILURE = 'ADD_NEW_CONTENT_FAILURE';
export const UPDATE_CONTENT = 'UPDATE_CONTENT';
export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS';
export const UPDATE_CONTENT_FAILURE = 'UPDATE_CONTENT_FAILURE';
export const RESET_CONTENTS_STATE = 'RESET_CONTENTS_STATE';
export const FETCH_CDN_ZONES = 'FETCH_CDN_ZONES';
export const FETCH_CDN_ZONES_SUCCESS = 'FETCH_CDN_ZONES_SUCCESS';
export const FETCH_CDN_ZONES_FAILURE = 'FETCH_CDN_ZONES_FAILURE';
export const CDN_CLEAR_ZONE = 'CDN_CLEAR_ZONE';
export const CDN_CLEAR_ZONE_SUCCESS = 'CDN_CLEAR_ZONE_SUCCESS';
export const CDN_CLEAR_ZONE_FAILURE = 'CDN_CLEAR_ZONE_FAILURE';
export const CDN_CLEAR_FILES = 'CDN_CLEAR_FILES';
export const CDN_CLEAR_FILES_SUCCESS = 'CDN_CLEAR_FILES_SUCCESS';
export const CDN_CLEAR_FILES_FAILURE = 'CDN_CLEAR_FILES_FAILURE';
export const RESET_CDN_STATE = 'RESET_CDN_STATE';
export const FETCH_CONTENT_COUNTS = 'FETCH_CONTENT_COUNTS';
export const FETCH_CONTENT_COUNTS_SUCCESS = 'FETCH_CONTENT_COUNTS_SUCCESS';
export const FETCH_CONTENT_COUNTS_FAILURE = 'FETCH_CONTENT_COUNTS_FAILURE';
export const FETCH_PROJECT_COUNTS = 'FETCH_PROJECT_COUNTS';
export const FETCH_PROJECT_COUNTS_SUCCESS = 'FETCH_PROJECT_COUNTS_SUCCESS';
export const FETCH_PROJECT_COUNTS_FAILURE = 'FETCH_PROJECT_COUNTS_FAILURE';
export const SET_IMPRESSIONS_FROM_TIME = 'SET_IMPRESSIONS_FROM_TIME';
export const FETCH_IMPRESSIONS = 'FETCH_IMPRESSIONS';
export const FETCH_IMPRESSIONS_SUCCESS = 'FETCH_IMPRESSIONS_SUCCESS';
export const FETCH_IMPRESSIONS_FAILURE = 'FETCH_IMPRESSIONS_FAILURE';
export const FETCH_IPS = 'FETCH_IPS';
export const FETCH_IPS_SUCCESS = 'FETCH_IPS_SUCCESS';
export const FETCH_IPS_FAILURE = 'FETCH_IPS_FAILURE';
export const FETCH_IP = 'FETCH_IP';
export const FETCH_IP_SUCCESS = 'FETCH_IP_SUCCESS';
export const FETCH_IP_FAILURE = 'FETCH_IP_FAILURE';
export const ADD_NEW_IP = 'ADD_NEW_IP';
export const ADD_NEW_IP_SUCCESS = 'ADD_NEW_IP_SUCCESS';
export const ADD_NEW_IP_FAILURE = 'ADD_NEW_IP_FAILURE';
export const UPDATE_IP = 'UPDATE_IP';
export const UPDATE_IP_SUCCESS = 'UPDATE_IP_SUCCESS';
export const UPDATE_IP_FAILURE = 'UPDATE_IP_FAILURE';
export const RESET_IP_STATE = 'RESET_IP_STATE';