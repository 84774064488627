import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { cloneLegal } from '../../utilities/common';
import Legal from '../../models/LegalTemplate';
import LegalForm from './LegalForm';
import * as actions from '../../store/actions/index';

const NewLegalForm = props => { 
    const newLegal = new Legal(undefined, '', '', '', '', [], [], '', '', '', '');
    newLegal.scenario = Legal.scenarios.CREATE;
    const [legal, setLegal] = useState(newLegal);
    const backendErrors = useSelector(state => state.legals.errors);
    let [validationErrors, setValidationErrors] = useState(undefined);
   // // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
    validationErrors = validationErrors || backendErrors;
    const dispatch = useDispatch();

   useEffect(() => {
      return () => dispatch(actions.legals.resetLegalState());
   }, []);

   const submitHandler = () => {
       const errors = legal.isValid();
       setValidationErrors(errors);
       if (errors) return;
       dispatch(actions.legals.addNewLegal(legal));
   }

   const updateLegal = (name, value) => {
      const updatedLegal = cloneLegal(legal);
      if(name === 'level') {
         updatedLegal['content_types'] = [];
         updatedLegal['projects'] = [];
      }
      name = ((name === 'CONTENT_TYPE' || name === 'PROJECT') ? name + 's' : name).toLowerCase();
      updatedLegal[name.toLowerCase()] = value;
      updatedLegal.scenario = Legal.scenarios.CREATE;
      setLegal(updatedLegal);
   }

   const handleEditorChange = (content, editor) => {
      const updatedLegal = cloneLegal(legal);
      updatedLegal.content = content;
      updatedLegal.scenario = Legal.scenarios.CREATE;
      setLegal(updatedLegal);
   }

   return (
      <>
         <LegalForm formTitle="Create new legal" legal={legal} validationErrors={validationErrors} errors={backendErrors} onSubmit={submitHandler} onChange={updateLegal} onEditorChange={handleEditorChange} />
      </>
   );
}

export default NewLegalForm;