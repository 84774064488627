import React, { useRef } from 'react';
import { Button, Icon, Label, Grid, Form } from 'semantic-ui-react';

import classes from './FileSelect.module.css';

const FileSelect = ({ name, label, value, previewActive, disabled, onChange, onPreview, error }) => {
   const fileInputRef = useRef();
   const initialValue = useRef();

   const addHandler = () => {
      // save initial value for removeHandler
      if (initialValue.current === undefined) {
         initialValue.current = value;
      }
      fileInputRef.current.click();
   }

   const removeHandler = () => {
      onPreview(name, initialValue.current);
      onChange(name, initialValue.current);
      initialValue.current = undefined;
      fileInputRef.current.value = '';
   }

   const fileChangeHandler = (value) => {
      onPreview(name, value);
      onChange(name, value);
   }

   return (
      <>
         <Grid >
            <Grid.Column width={6}>
               <Label className={classes.Label}>{label}</Label>
            </Grid.Column>
            <Grid.Column width={3}>
               <Button icon className={`${classes.Plus} ${error ? classes.Error : ''}`} onClick={addHandler} disabled={disabled} >
                  <Icon name="plus" />
               </Button>
            </Grid.Column>
            <Grid.Column width={3}>
               <Button className={classes.Minus} disabled={initialValue.current === undefined} icon onClick={removeHandler} >
                  <Icon name="minus" />
               </Button>
            </Grid.Column>
            <Grid.Column width={4}>
               <Button className={`${classes.Preview} ${previewActive ? classes.Active : ''}`} onClick={() => onPreview(name, value)}>Preview</Button>
            </Grid.Column>
            {error ? (
               <Form.Field className={classes.ErrorField}>
                  <div className="ui pointing above prompt label" role="alert" aria-atomic="true">{error}</div>
               </Form.Field>
            ) : null}
         </Grid>
         <input type="file" hidden ref={fileInputRef} onChange={(ev, data) => fileChangeHandler(fileInputRef.current.files[0])} />
      </>
   );
}

export default FileSelect;