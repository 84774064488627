import { call } from 'redux-saga/effects';

import { fetchAll, fetchOne, updateContent, newContent } from './fagc';
import { cloneGames, cloneGame, cloneGameDeals, cloneGameDeal, cloneAllGameNews, cloneGameNews, cloneGameWallpapers, cloneGameWallpaper, cloneQuizzes, cloneQuiz, cloneCoupons, cloneCoupon, cloneRecipes, cloneRecipe, cloneTools, cloneTool } from '../../utilities/common';
import * as actions from '../actions/index';
import * as acTypes from '../actions/actionTypes';

const config = {
   [acTypes.FETCH_CONTENTS]: {
      games: {
         endpointUrl: '/game-service/get-all-games',
         cloneFn: cloneGames,
         successActionFn: actions.content.fetchContentsSuccess,
         failureActionFn: actions.content.fetchContentsFailure,
      },
      gamedeals: {
        endpointUrl: '/game-deal-service/get-all-game-deals',
        cloneFn: cloneGameDeals,
        successActionFn: actions.content.fetchContentsSuccess,
        failureActionFn: actions.content.fetchContentsFailure,
     },
     allgamenews: {
        endpointUrl: '/game-news-service/get-all-game-news',
        cloneFn: cloneAllGameNews,
        successActionFn: actions.content.fetchContentsSuccess,
        failureActionFn: actions.content.fetchContentsFailure,
     },
     gamewallpapers: {
        endpointUrl: '/game-wallpaper-service/get-all-game-wallpapers',
        cloneFn: cloneGameWallpapers,
        successActionFn: actions.content.fetchContentsSuccess,
        failureActionFn: actions.content.fetchContentsFailure,
     },
      quizzes: {
         endpointUrl: '/quiz-service/get-all-quizzes',
         cloneFn: cloneQuizzes,
         successActionFn: actions.content.fetchContentsSuccess,
         failureActionFn: actions.content.fetchContentsFailure,
      },
      coupons: {
         endpointUrl: '/coupon-service/get-all-coupons',
         cloneFn: cloneCoupons,
         successActionFn: actions.content.fetchContentsSuccess,
         failureActionFn: actions.content.fetchContentsFailure,
      },
      recipes: {
         endpointUrl: '/recipe-service/get-all-recipes',
         cloneFn: cloneRecipes,
         successActionFn: actions.content.fetchContentsSuccess,
         failureActionFn: actions.content.fetchContentsFailure,
      },
      tools: {
         endpointUrl: '/online-tool-service/get-all-online-tools',
         cloneFn: cloneTools,
         successActionFn: actions.content.fetchContentsSuccess,
         failureActionFn: actions.content.fetchContentsFailure,
      },
   },
   [acTypes.FETCH_CONTENT]: {
      game: {
         endpointUrl: '/game-service/get-game',
         cloneFn: cloneGame,
         successActionFn: actions.content.fetchContentSuccess,
         failureActionFn: actions.content.fetchContentFailure,
      },
      gamedeal: {
         endpointUrl: '/game-deal-service/get-game-deal',
         cloneFn: cloneGameDeal,
         successActionFn: actions.content.fetchContentsSuccess,
         failureActionFn: actions.content.fetchContentsFailure,
      },
      gamenews: {
        endpointUrl: '/game-news-service/get-game-news',
        cloneFn: cloneGameNews,
        successActionFn: actions.content.fetchContentsSuccess,
        failureActionFn: actions.content.fetchContentsFailure,
     },
     gamewallpaper: {
        endpointUrl: '/game-wallpaper-service/get-game-wallpaper',
        cloneFn: cloneGameWallpaper,
        successActionFn: actions.content.fetchContentSuccess,
        failureActionFn: actions.content.fetchContentFailure,
     },
      quiz: {
         endpointUrl: '/quiz-service/get-quiz',
         cloneFn: cloneQuiz,
         successActionFn: actions.content.fetchContentSuccess,
         failureActionFn: actions.content.fetchContentFailure,
      },
      coupon: {
         endpointUrl: '/coupon-service/get-coupon',
         cloneFn: cloneCoupon,
         successActionFn: actions.content.fetchContentSuccess,
         failureActionFn: actions.content.fetchContentFailure,
      },
      recipe: {
         endpointUrl: '/recipe-service/get-recipe',
         cloneFn: cloneRecipe,
         successActionFn: actions.content.fetchContentSuccess,
         failureActionFn: actions.content.fetchContentFailure,
      },
      apps: {
         endpointUrl: '/online-tool-service/get-online-tool',
         cloneFn: cloneTool,
         successActionFn: actions.content.fetchContentSuccess,
         failureActionFn: actions.content.fetchContentFailure,
      },
   },
   [acTypes.UPDATE_CONTENT]: {
      game: {
         endpointUrl: '/game-service/update-game',
         cloneFn: cloneGame,
         successActionFn: actions.content.updateContentSuccess,
         failureActionFn: actions.content.updateContentFailure,
         redirectAction: actions.app.redirectTo('/games'),
      },
      gamedeal: {
         endpointUrl: '/game-deal-service/update-game-deal',
         cloneFn: cloneGameDeal,
         successActionFn: actions.content.updateContentSuccess,
         failureActionFn: actions.content.updateContentFailure,
         redirectAction: actions.app.redirectTo('/game-deals'),
      },
      gamenews: {
        endpointUrl: '/game-news-service/update-game-news',
        cloneFn: cloneGameNews,
        successActionFn: actions.content.updateContentSuccess,
        failureActionFn: actions.content.updateContentFailure,
        redirectAction: actions.app.redirectTo('/all-game-news'),
     },
     gamewallpaper: {
        endpointUrl: '/game-wallpaper-service/update-game-wallpaper',
        cloneFn: cloneGameWallpaper,
        successActionFn: actions.content.updateContentSuccess,
        failureActionFn: actions.content.updateContentFailure,
        redirectAction: actions.app.redirectTo('/game-wallpapers'),
     },
      quiz: {
         endpointUrl: '/quiz-service/update-quiz',
         cloneFn: cloneQuiz,
         successActionFn: actions.content.updateContentSuccess,
         failureActionFn: actions.content.updateContentFailure,
         redirectAction: actions.app.redirectTo('/quizzes'),
      },
      coupon: {
         endpointUrl: '/coupon-service/update-coupon',
         cloneFn: cloneCoupon,
         successActionFn: actions.content.updateContentSuccess,
         failureActionFn: actions.content.updateContentFailure,
         redirectAction: actions.app.redirectTo('/coupons'),
      },
      recipe: {
         endpointUrl: '/recipe-service/update-recipe',
         cloneFn: cloneRecipe,
         successActionFn: actions.content.updateContentSuccess,
         failureActionFn: actions.content.updateContentFailure,
         redirectAction: actions.app.redirectTo('/recipes'),
      },
      apps: {
         endpointUrl: '/online-tool-service/update-online-tool',
         cloneFn: cloneTool,
         successActionFn: actions.content.updateContentSuccess,
         failureActionFn: actions.content.updateContentFailure,
         redirectAction: actions.app.redirectTo('/tools'),
      },
   },
   [acTypes.ADD_NEW_CONTENT]: {
      game: {
         endpointUrl: '/game-service/create-game',
         cloneFn: cloneGame,
         successActionFn: actions.content.addNewContentSuccess,
         failureActionFn: actions.content.addNewContentFailure,
         redirectAction: actions.app.redirectTo('/games'),
      },
      gamedeal: {
         endpointUrl: '/game-deal-service/create-game-deal',
         cloneFn: cloneGameDeal,
         successActionFn: actions.content.addNewContentSuccess,
         failureActionFn: actions.content.addNewContentFailure,
         redirectAction: actions.app.redirectTo('/game-deals'),
      },
      gamenews: {
        endpointUrl: '/game-news-service/create-game-news',
        cloneFn: cloneGameNews,
        successActionFn: actions.content.addNewContentSuccess,
        failureActionFn: actions.content.addNewContentFailure,
        redirectAction: actions.app.redirectTo('/all-game-news'),
     },
     gamewallpaper: {
        endpointUrl: '/game-wallpaper-service/create-game-wallpaper',
        cloneFn: cloneGameWallpaper,
        successActionFn: actions.content.addNewContentSuccess,
        failureActionFn: actions.content.addNewContentFailure,
        redirectAction: actions.app.redirectTo('/game-wallpapers'),
     },
      quiz: {
         endpointUrl: '/quiz-service/create-quiz',
         cloneFn: cloneQuiz,
         successActionFn: actions.content.addNewContentSuccess,
         failureActionFn: actions.content.addNewContentFailure,
         redirectAction: actions.app.redirectTo('/quizzes'),
      },
      coupon: {
         endpointUrl: '/coupon-service/create-coupon',
         cloneFn: cloneCoupon,
         successActionFn: actions.content.addNewContentSuccess,
         failureActionFn: actions.content.addNewContentFailure,
         redirectAction: actions.app.redirectTo('/coupons'),
      },
      recipe: {
         endpointUrl: '/recipe-service/create-recipe',
         cloneFn: cloneRecipe,
         successActionFn: actions.content.addNewContentSuccess,
         failureActionFn: actions.content.addNewContentFailure,
         redirectAction: actions.app.redirectTo('/recipes'),
      },
      apps: {
         endpointUrl: '/online-tool-service/create-online-tool',
         cloneFn: cloneTool,
         successActionFn: actions.content.addNewContentSuccess,
         failureActionFn: actions.content.addNewContentFailure,
         redirectAction: actions.app.redirectTo('/tools'),
      },
   }
}

export function* fetchContentsSaga(action) {
   yield call(fetchAll, action, config[action.type][action.cType]);
}

export function* fetchContentSaga(action) {
   yield call(fetchOne, action, config[action.type][action.cType]);
}

export function* updateContentSaga(action) {
   yield call(updateContent, action, config[action.type][action.cType]);
}

export function* addNewContentSaga(action) {
   yield call(newContent, action, config[action.type][action.cType]);
}