import { FETCH_IMPRESSIONS, FETCH_IMPRESSIONS_SUCCESS, FETCH_IMPRESSIONS_FAILURE, SET_IMPRESSIONS_FROM_TIME } from '../actions/actionTypes';

const initialState = {
   from: '',
   impressions: [],
   loading: false,
}

const reducer = (state = initialState, action) => {
   const { cType, impressions, error, from } = action;

   switch (action.type) {
      case SET_IMPRESSIONS_FROM_TIME:
         if (state.from !== from) {
            return {
               ...initialState,
               from,
            }
         } else {
            return state;
         }
      case FETCH_IMPRESSIONS:
         return {
            ...state,
            loading: true,
            error: ''
         };
      case FETCH_IMPRESSIONS_SUCCESS:
         return {
            ...state,
            loading: false,
            impressions,
         }
      case FETCH_IMPRESSIONS_FAILURE:
         return {
            ...state,
            loading: false,
            error,
         }
      default:
         return state;
   }
}

export default reducer;