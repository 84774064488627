import { useSelector, useDispatch } from 'react-redux'

const useContent = (content) => {
   let level = content;
   if (content === 'apps') level = 'tool';
   const projectsLoaded = useSelector(state => state.projects.projectsLoaded);
   const projects = useSelector(state => state.projects.projects.filter(item => item.content_type === content).map(pr => ({ id: pr.id, text: pr.name })));
   const tagsLoaded = useSelector(state => state.tags.tagsLoaded);
   const tags = useSelector(state => state.tags.tags.filter(item => item.level.includes(level)).map(tg => ({ id: tg.id, text: tg.name, description: tg.description })));
   const categoriesLoaded = useSelector(state => state.categories.categoriesLoaded);
   const categories = useSelector(state => state.categories.categories.filter(item => item.level.includes(level)));
   const backendErrors = useSelector(state => state.contents[`${content}Errors`]);
   const contentLoaded = useSelector(state => state.contents[`${content}Loaded`]);
   const selectedContent = useSelector(state => state.contents[content]);
   const requestPending = useSelector(state => state.contents.requestPending);
   const dispatch = useDispatch();

   return {
      projectsLoaded,
      projects,
      tagsLoaded,
      tags,
      categoriesLoaded,
      categories,
      backendErrors,
      contentLoaded,
      selectedContent,
      requestPending,
      dispatch,
   }
}

export default useContent;