import React from 'react';
import { Menu, Label, Icon, Button, Popup } from 'semantic-ui-react';
import classes from './Footer.module.css';

const Footer = props => {
   var d = new Date();
   var year = d.getFullYear();

   const scrollToTop = () => {
      window.scroll({top: 0, left: 0, behavior: 'smooth' });
   }


   return (
      <Menu inverted className={classes.footer} >
         <Menu.Menu position="left">
            <Menu.Item>
               <Label>Copyright &copy; {year + '. All Rights Reserved.'} </Label>
            </Menu.Item>
         </Menu.Menu>
         <Menu.Menu position="right">
            <Menu.Item>
               <Popup 
                  content='Scroll on top of page' 
                  trigger={         
                     <Button id='scroll-on-top-button' onClick={scrollToTop}>
                        <Icon name='angle double up' color='grey' size='large'/>
                     </Button>}
                  flowing
                  basic
               />
            </Menu.Item>
         </Menu.Menu>
      </Menu>
   );
}

export default Footer;