import { validate } from 'validate.js';

class Category {
   id;
   name;
   level;
   scenario = scenarios.DEFAULT;
   labels = { 
		id : 'ID',
		name : 'Name',
		level : 'Levels',
	};
   
   constructor (id, name, level) {
      this.id = id;
      this.name = name;
      this.level = level;
   }

   isValid = () => {
      const defaultErr = validate(this, constraints[Category.scenarios.DEFAULT]);
      if (this.scenario === Category.scenarios.DEFAULT) return defaultErr;
      const err = validate(this, constraints[this.scenario]);
      if(!defaultErr && !err) return undefined;

      let allErrors = {...defaultErr};
      if (err) {
         Object.keys(err).forEach(key => {
            if(allErrors[key]) allErrors[key] = [...allErrors[key], ...err[key]];
            else allErrors[key] = err[key]; 
         });
      }

      return allErrors;
   }
}

const scenarios = {
   DEFAULT: "DEFAULT",
   CREATE: "CREATE",
   UPDATE: "UPDATE",
}

const constraints = {
    "DEFAULT": {
        "name": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be string at most 45 symbols",
                "maximum": 45
            },
            "format": {
                "pattern": "^[A-Za-z][A-Za-z &\\/]{2,44}$",
                "message": "can contain letters, space, & and / signs, 3-44 chacracters. Must start with letter. Example: Fun & Entertainment "
            }
        },
        "level": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        }
    },
    "CREATE": [],
    "UPDATE": []
}

Category.scenarios = scenarios;
Category.constraints = constraints;

export default Category;
