import React, { useState } from 'react';
import { Form, Segment, Grid } from 'semantic-ui-react';

import FileSelect from '../../common/FileSelect';
import Image from '../../common/Image';
import classes from '../Content.module.css';

const CouponOptions = ({ coupon, onChange, errors, validationErrors, loading, mode }) => {
   const couponTypeOptions = ['Frame', 'Link'].map(item => ({ text: item, value: item }));
   const [imageSrc, setImageSource] = useState();
   const [previewActive, setPreviewActive] = useState();

   const previewHandler = (name, value) => {
      setPreviewActive(name);
      if (value instanceof File) {
         const reader = new FileReader();
         reader.onload = event => setImageSource(event.target.result);
         reader.readAsDataURL(value);
      } else {
         if (value) {
            setImageSource(value);

         } else {
            setImageSource('')
         }
      }
   }

   const changeHandler = (event, data) => {
      onChange(data.name, data.value);
   }

   const mediaChangeHandler = (name, value) => {
      setPreviewActive(name);
      onChange(name, value);
   }

   const disabled = mode === 'view';
   return (
      <Form loading={loading} className={classes.Form}>
         <Form.Input type="text" label="Link" name="link" value={coupon.link} onChange={changeHandler} error={validationErrors.link || null} disabled={disabled} />
         <Form.Select label="Coupon Type" name="render_type" options={couponTypeOptions} value={coupon.render_type} onChange={changeHandler} error={validationErrors.render_type || null} disabled={disabled} />
         <Form.Field>
            <label>Media:</label>
            <Segment as='fieldset' className={classes.MediaSegment}>
               <Grid columns={2}>
                  <Grid.Column>
                     <FileSelect label="Image 1 (150x150):" name="image_1" previewActive={previewActive === 'image_1'} value={coupon.image_1} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_1 || null} disabled={disabled} />
                     <FileSelect label="Image 2 (150x70):" name="image_2" previewActive={previewActive === 'image_2'} value={coupon.image_2} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_2 || null} disabled={disabled} />
                  </Grid.Column>
                  <Grid.Column textAlign="center" verticalAlign="middle">
                     <Image src={imageSrc} className={classes.PreviewImage} />
                  </Grid.Column>
               </Grid>
            </Segment>
         </Form.Field>
      </Form>
   );
}

export default CouponOptions;