import { FETCH_USERS,
         FETCH_USERS_SUCCESS,
         FETCH_USERS_FAILURE,
         FETCH_USER,
         FETCH_USER_SUCCESS,
         FETCH_USER_FAILURE,
         CHANGE_PASSWORD, 
         CHANGE_PASSWORD_SUCCESS, 
         CHANGE_PASSWORD_FAILURE,
         ADD_NEW_USER,
         ADD_NEW_USER_SUCCESS,
         ADD_NEW_USER_FAILURE,
         UPDATE_USER,
         UPDATE_USER_SUCCESS,
         UPDATE_USER_FAILURE,
         FETCH_PERMISSIONS_MAP,
         FETCH_PERMISSIONS_MAP_SUCCESS,
         FETCH_PERMISSIONS_MAP_FAILURE,
         RESET_USERS_STATE,
      } from './actionTypes';


export const fetchUsers = () => {
   return {
      type: FETCH_USERS,
   }
}

export const fetchUsersSuccess = (users) => {
   return {
      type: FETCH_USERS_SUCCESS,
      users,
   }
}

export const fetchUsersFailure = () => {
   return {
      type: FETCH_USERS_FAILURE,
   }
}

export const fetchUser = id => {
   return {
      type: FETCH_USER,
      id,
   }
}

export const fetchUserSuccess = (user) => {
   return {
      type: FETCH_USER_SUCCESS,
      user,
   }
}

export const fetchUserFailure = () => {
   return {
      type: FETCH_USER_FAILURE,
   }
}

export const changePassword = (oldPassword, newPassword, confirmPassword) => {
   return {
      type: CHANGE_PASSWORD,
      oldPassword,
      newPassword,
      confirmPassword,
   }
}

export const changePasswordSuccess = status => {
   return {
      type: CHANGE_PASSWORD_SUCCESS,
      messages: status,
   }
}

export const changePasswordFailure = errors => {
   return {
      type: CHANGE_PASSWORD_FAILURE,
      errors,
   }
}

export const addNewUser = user => {
   return {
      type: ADD_NEW_USER,
      user,
   }
}

export const addNewUserSuccess = user => {
   return {
      type: ADD_NEW_USER_SUCCESS,
      user,
   }
}

export const addNewUserFailure = errors => {
   return {
      type: ADD_NEW_USER_FAILURE,
      errors,
   }
}

export const updateUser = user => {
   return {
      type: UPDATE_USER,
      user,
   }
}

export const updateUserSuccess = user => {
   return {
      type: UPDATE_USER_SUCCESS,
      user,
   }
}

export const updateUserFailure = errors => {
   return {
      type: UPDATE_USER_FAILURE,
      errors,
   }
}

export const fetchPermissionsMap = () => {
   return {
      type: FETCH_PERMISSIONS_MAP,
   }
}

export const fetchPermissionsMapSuccess = (permissions) => {
   return {
      type: FETCH_PERMISSIONS_MAP_SUCCESS,
      permissions,
   }
}

export const fetchPermissionsMapFailure = errors => {
   return {
      type: FETCH_PERMISSIONS_MAP_FAILURE,
      errors,
   }
}

export const resetUsersState = () => {
   return {
      type: RESET_USERS_STATE,
   }
}

