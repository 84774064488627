import React from 'react';
import { Message } from 'semantic-ui-react';

const GlobalFormErrors = ({ title, globalErrors=[], validationErrors = {} }) => {
   const allErrorMsgs = [];
   if (Object.keys(validationErrors).length) {
      allErrorMsgs.push('Please check all tabs');
   }
   allErrorMsgs.push(...globalErrors);

   // do not render error messages component if there are not any
   if (!allErrorMsgs.length) return null;

   return (
      <Message
         error
         header={title}
         list={allErrorMsgs}
      />
   );
}

export default GlobalFormErrors;