import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { cloneProject } from '../../utilities/common';
import Project from '../../models/Project';
import ProjectForm from './ProjectForm';
import * as actions from '../../store/actions/index';

const NewProjectForm = props => {
   const newProject = new Project(undefined, '', '', 'com', 'game', 'ACTIVE', '', '', 'NO', 'YES');
   const [project, setProject] = useState(newProject);
   newProject.scenario = Project.scenarios.CREATE;
   const loading = useSelector(state => state.projects.requestPending);
   const backendErrors = useSelector(state => state.projects.errors);
   let [validationErrors, setValidationErrors] = useState(undefined);
   // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
   validationErrors = validationErrors || backendErrors;
   const dispatch = useDispatch();

   useEffect(() => {
      return () => dispatch(actions.projects.resetProjectsState());
   }, []);

   const submitHandler = () => {
      const errors = project.isValid();
      setValidationErrors(errors);
      if (errors) return;
      dispatch(actions.projects.addNewProject(project));
   }

   const updateProject = (name, value) => {
      const updatedProject = cloneProject(project);
      updatedProject[name] = value;
      setProject(updatedProject);
   }

   return (
      <>
         <ProjectForm title="Create new project" project={project} validationErrors={validationErrors} errors={backendErrors.global} loading={loading} onSubmit={submitHandler} onChange={updateProject} />
      </>
   );
}

export default NewProjectForm;