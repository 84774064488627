import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Header, Message, Segment, Image } from 'semantic-ui-react';

import classes from './Auth.module.css';
import logo from '../../assets/logo.png';

const Login = ({ username, password, remember, loading, errors, basePath, onLogin, onUsernameChange, onPasswordChange, onRememberChange }) => {
   let errorMsg = null;
   if (errors) {
      errorMsg = (
         <Message
            error
            header='Login Failed'
            content={errors}
         />
      );
   }

   return (
      <Segment className={classes.login}>
         <Form onSubmit={onLogin} error={!!errors}>
            <Header>Login</Header>
            <Form.Field>
               <Form.Input name="userName" placeholder="Username" value={username} onChange={onUsernameChange} />
            </Form.Field>
            <Form.Field>
               <Form.Input name="password" placeholder="Password" type="password" value={password} onChange={onPasswordChange} />
            </Form.Field>
            <Form.Group inline >
               <Form.Checkbox name="remember" label="Remember me" checked={remember} onChange={onRememberChange} />
               <Link to={basePath + "/forgot-password"}>Forgot password?</Link>
            </Form.Group>
            {errorMsg}
            <Form.Button name='submit' floated="right" primary content='Login' loading={loading} />
         </Form>
         <Segment circular className={classes.logo}>
            <Image src={logo} size="mini" />
         </Segment>
      </Segment>
   );
}

export default Login;