import React, { useState } from 'react';
import { Input, Checkbox, Label, Container, Menu } from 'semantic-ui-react';

import classes from './SelectWithCheckBoxes.module.css'

const SelectWithCheckBoxes = ({ items, onSelectionChange, selectedItemId, onChange, name, value, labelDisabled, checkBoxesDisabled, placeholder }) => {
   const [search, setSearch] = useState('');
   const ddItems = items.filter(item => item.text.toLowerCase().includes(search.toLowerCase()));

   const labelClick = (event, data) => {
      const value = data['data-itemid'];
      onSelectionChange(value);
      if (!checkBoxesDisabled) {
         changeHandler(value, true);
      }
   }

   const listItems = ddItems.map(item => {
      let labelClass = classes.SelectLabel;
      if (item.id == selectedItemId) {
         labelClass = classes.SelectLabelActive;
      }
      if (labelDisabled) {
         labelClass = classes.SelectLabelDisabled;
      }

      return (
         <Menu.Item key={item.id}>
            <Checkbox className={classes.CbSelect} toggle disabled={checkBoxesDisabled} value={item.id} checked={value.includes(item.id)} onChange={(event, { value, checked }) => changeHandler(value, checked)}>
            </Checkbox>
            <Label className={labelClass} disabled={labelDisabled} onClick={labelClick} data-itemid={item.id} title={item.description || item.text}>{item.text}</Label>
         </Menu.Item>
      );
   });

   const searchChangeHandler = (event, value) => {
      setSearch(value);
   }


   const changeHandler = (newValue, checked) => {
      let updatedCheckedItems = value.filter(id => id != newValue);
      if (checked) {
         updatedCheckedItems.push(newValue);
      }
      onChange(name, updatedCheckedItems);
   }

   const fiterInput = (
      <Input
         onChange={(event, { value }) => searchChangeHandler(event, value)}
         value={search}
         placeholder={placeholder}
      />
   )

   return (
      <Container fluid>
         {fiterInput}
         <div className={classes.SelectMenu}>
            {listItems}
         </div>
      </Container>
   );
}

export default SelectWithCheckBoxes;