import React, { useState } from 'react';
import { Form, Segment, Grid } from 'semantic-ui-react';

import FileSelect from '../../common/FileSelect';
import Image from '../../common/Image';
import classes from '../Content.module.css';
import { Editor } from '@tinymce/tinymce-react';
import { strip_tags } from '../../../utilities/common';

const GameDealOptions = ({ loading, gameDeal, mode, validationErrors, onChange, onEditorChange }) => {
   const [imageSrc, setImageSource] = useState();
   const [previewActive, setPreviewActive] = useState();
   const [mediaUrl, setMediaUrl] = useState();
   const [mediaText, setMediaText] = useState();

   const previewHandler = (name, value) => {
      setPreviewActive(name);
      if (value instanceof File) {
         const reader = new FileReader();
         reader.onload = event => setImageSource(event.target.result);
         reader.readAsDataURL(value);
      } else {
         if (value) {
            if(value.includes(".jpg") || value.includes(".png") || value.includes(".gif")) {
               setImageSource(value);
               setMediaUrl('');
               setMediaText("");
            }
         } else {
            setImageSource('');
            setMediaUrl('');
            setMediaText("");
         }
      }
   }

   const changeHandler = (event, data) => {
      onChange(data.name, data.value);
   }

   const mediaChangeHandler = (name, value) => {
      setPreviewActive(name);
      onChange(name, value);
   }

   const disabled = mode === 'view';
   return (
      <Form loading={loading} className={classes.Form}>
        <Form.Input label="Link:" type="text" name="link" value={gameDeal.link} onChange={changeHandler} error={validationErrors.link || null} disabled={disabled} />
        <Form.Input label="Source:" type="text" name="source" value={gameDeal.source} onChange={changeHandler} error={validationErrors.source || null} disabled={disabled} />
        <Form.Field>
            <Form.Input id='editor-tinymce-error' label='Body' error={validationErrors.body || null}
            input = {
                <Editor
                value={gameDeal.body}
                apiKey="4uuto2u1mlgsb5ofwxag9vuppnwl3ybbawhvrsghnl247w3z"
                init={{
                height: 400,
                menubar: true,
                plugins: 'textcolor table paste',
                entity_encoding : 'raw', 
                force_br_newlines : true, 
                force_p_newlines : false, 
                forced_root_block : '',
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                //paste_word_valid_elemnets: "b, strong, i, em, h1, h2, u, p, ol, ul, li, a[href], span, color, mark",
                paste_retain_style_properties: "all",
                paste_preprocess: function(plugin, args) {
                    args.content = strip_tags( args.content,'<b><strong><i><em><h1><h2><u><p><ol><ul><li><a[href]><span><color><mark><br>' );
                    },
                    paste_postprocess: function(plugin, args) {
                    //args.node.setAttribute('id', '42');
                    },
                toolbar:
                    'paste undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help',
                }}
                onEditorChange={onEditorChange}
                disabled= {disabled}
            />}/>
        </Form.Field>
         <Form.Field>
            <label>Media:</label>
            <Segment as="fieldset" className={classes.MediaSegment}>
               <Grid columns={2}>
                  <Grid.Column>
                     <FileSelect label="Image 1 (600x350):" name="image_1" previewActive={previewActive === 'image_1'} value={gameDeal.image_1} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_1 || null} disabled={disabled} />
                     <FileSelect label="Image 2 (170x170):" name="image_2" previewActive={previewActive === 'image_2'} value={gameDeal.image_2} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_2 || null} disabled={disabled} />
                     <FileSelect label="Image 3 (294x360):" name="image_3" previewActive={previewActive === 'image_3'} value={gameDeal.image_3} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_3 || null} disabled={disabled} />     
                  </Grid.Column>
                  <Grid.Column textAlign="center" verticalAlign="middle">
                     <Image src={imageSrc} className={classes.PreviewImage} />
                     <a id="mediaUrl" href={mediaUrl} target="_blank" >{mediaText}</a>
                  </Grid.Column>
               </Grid>
            </Segment>
         </Form.Field>
      </Form>
   );
}

export default GameDealOptions;