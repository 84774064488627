import React, { useEffect } from 'react';
import { Grid, Divider } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';

import CountCard from './CountCard';
import * as actions from '../../store/actions/index';
import classes from './Dashboard.module.css';

const projectLinks = {
   apps: '/projects?type=apps',
   classifieds: '/projects?type=classifieds',
   coupon: '/projects?type=coupon',
   fileconverter: '/projects?type=fileconverter',
   forms: '/projects?type=forms',
   game: '/projects?type=game',
   maps: '/projects?type=maps',
   news: '/projects?type=news',
   package: '/projects?type=package',
   prototype: '/projects?type=prototype',
   quiz: '/projects?type=quiz',
   radio: '/projects?type=radio',
   recipe: '/projects?type=recipe',
   speedtest: '/projects?type=speedtest',
   tv: '/projects?type=tv',
   unknown: '/projects?type=unknown',
   video: '/projects?type=video',
   weather: '/projects?type=weather',
   astrology: '/projects?type=astrology',
   streaming: '/projects?type=streaming',
   sports: '/projects?type=sports',
   quotes: '/projects?type=quotes',
   health: '/projects?type=health',
   books: '/projects?type=books',
   login: '/projects?type=login',
   manuals: '/projects?type=manuals',
   vacation: '/projects?type=vacation',
   qrcode: '/projects?type=qrcode',
}

const ProjectCount = props => {
   const projectCounts = useSelector(state => state.dashboard.project);
   const projectErrors = useSelector(state => state.dashboard.projectErrors);
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(actions.dashboard.fetchProjectCounts());
   }, [dispatch]);

   return (
      <>
         <Divider horizontal className={classes.ProjectDivader}>Projects</Divider>
         <Grid columns={5} className={classes.ProjectGrid}>

            {projectCounts.map(item => {
               return (
                  <Grid.Column key={item.content_type}>
                     <CountCard title={item.content_type} count={item.count} link={projectLinks[item.content_type]} />
                  </Grid.Column>
               );
            })}
         </Grid>
      </>
   );
}

export default ProjectCount;  