import { call, put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { cloneCategory, cloneCategories } from '../../utilities/common';
import { processResponse } from '../../utilities/fetchUtils';

export function* fetchCategoriesSaga(action) {
   const body = JSON.stringify({ level: action.level });
   try {
      const response = yield call(fetch, '/category-service/get-categories', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: body,
      });
      const categoriesData = yield call(processResponse, response);
      const categories = yield call(cloneCategories, categoriesData.data);
      yield put(actions.categories.fetchCategoriesSuccess(categories));
   } catch (e) {
      console.log(e);
      yield put(actions.categories.fetchCategoriesFailure(e.messages || e.message));
   }
}

export function* addNewCategorySaga(action) {
   const { name, level } = action.category;
   try {
      const response = yield call(fetch, '/category-service/create-category', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            name,
            level,
         }),
      });
      const categoryData = yield call(processResponse, response);
      const category = yield call(cloneCategory, categoryData.data);
      yield put(actions.categories.addNewCategorySuccess(category));
      yield put(actions.app.redirectTo('/categories'));
   } catch (e) {
      console.log(e);
      yield put(actions.categories.addNewCategoryFailure(e.message || e.messages));
   }
}

export function* fetchCategorySaga(action) {
   try {
      const response = yield call(fetch, '/category-service/get-category', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            id: action.id,
         }),
      });
      const categoryData = yield call(processResponse, response);
      const category = yield call(cloneCategory, categoryData.data);
      yield put(actions.categories.fetchCategorySuccess(category));
   } catch (e) {
      console.log(e);
      yield put(actions.categories.fetchCategoryFailure(e.message || e.messages));
   }
}

export function* updateCategorySaga(action) {
   const { id, name, level } = action.category;
   try {
      const response = yield call(fetch, '/category-service/update-category', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            id,
            name,
            level,
         }),
      });
      const categoryData = yield call(processResponse, response);
      const category = yield call(cloneCategory, categoryData.data);
      yield put(actions.categories.updateCategorySuccess(category));
      yield put(actions.app.redirectTo('/categories'));

   } catch (e) {
      console.log(e);
      yield put(actions.categories.updateCategoryFailure(e.message || e.messages));
   }
}
