import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ContentForm from '../ContentForm';
import QuizOptions from './QuizOptions';
import { cloneQuiz } from '../../../utilities/common';
import useContent from '../../../hooks/useContent';
import * as actions from '../../../store/actions/index';

const QuizForm = ({ mode }) => {

   const emptyQuiz = cloneQuiz({
      quiz_type: 'IFRAME',
      quiz_script: '',
      quiz_link: '',
      image_1: '',
      image_2: '',
      image_3: '',
      image_4: '',
      id: '',
      content_id: '',
      type: 'quiz',
      title: '',
      description: '',
      keywords: '',
      categories: [],
      tags: {},
      projects: [],
      status: 'ACTIVE'
   });

   const quizId = useParams().id;
   const {
      projectsLoaded,
      projects,
      tagsLoaded,
      tags,
      categoriesLoaded,
      categories,
      backendErrors,
      contentLoaded,
      selectedContent,
      requestPending,
      dispatch
   } = useContent('quiz');

   let [validationErrors, setValidationErrors] = useState(undefined);
   const [selectedProjectId, setSelectedProjectId] = useState();
   const [quiz, setQuiz] = useState(emptyQuiz);
   // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
   validationErrors = validationErrors || backendErrors;

   useEffect(() => {
      if (!projectsLoaded) dispatch(actions.projects.fetchProjects());
      if (!tagsLoaded) dispatch(actions.tags.fetchTags());
      if (!categoriesLoaded) dispatch(actions.categories.fetchCategories());
      if (mode !== 'new' && !contentLoaded) dispatch(actions.content.fetchContent(quizId, 'quiz'));
      return () => dispatch(actions.content.resetContentState());
   }, []);

   useEffect(() => {
      if (selectedContent && mode !== 'new')
         setQuiz(selectedContent);
   }, [selectedContent]);

   const updateQuiz = (name, value) => {

      setQuiz((quiz) => {
         const updatedQuiz = cloneQuiz(quiz);
         if (name === 'tags') {
            updatedQuiz.tags = { ...updatedQuiz.tags, [selectedProjectId]: value }
         } else if (name === 'keywords') {
            updatedQuiz.keywords = value.join(',');
         } else if (name === 'projects') {
            const updatedTags = {};
            value.forEach(projectId => updatedTags[projectId] = updatedQuiz.tags[projectId]);
            updatedQuiz['projects'] = value;
            updatedQuiz['tags'] = updatedTags;
         } else {
            updatedQuiz[name] = value;
         }
         return updatedQuiz;
      });
   }

   const selectedProjectChangeHandler = projectId => {
      setSelectedProjectId(projectId);
   }

   const submitHandler = () => {
      const errors = quiz.isValid();
      setValidationErrors(errors);
      if (errors) return;
      switch (mode) {
         case 'new':
            dispatch(actions.content.addNewContent(quiz, 'quiz'));
            break;
         case 'edit':
            dispatch(actions.content.updateContent(quiz, 'quiz'));
            break;
      }
   }

   const loading = !projectsLoaded || !tagsLoaded || !categoriesLoaded || (mode !== 'new' && !contentLoaded) || requestPending;
   return (
      <ContentForm
         content={quiz}
         contentType="Quiz"
         validationErrors={validationErrors}
         errors={backendErrors.global}
         onChange={updateQuiz}
         onSubmit={submitHandler}
         projects={projects}
         selectedProjectId={selectedProjectId}
         projectSelectedHandler={selectedProjectChangeHandler}
         tags={tags}
         categories={categories}
         loading={loading}
         mode={mode}
      >
         {(content, onChange, errors, validationErrors, loading, mode) => {
            return (
               <QuizOptions
                  quiz={content}
                  onChange={onChange}
                  errors={errors}
                  validationErrors={validationErrors}
                  loading={loading}
                  mode={mode}
               />
            );
         }}
      </ContentForm>
   );
}

export default QuizForm;