import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { cloneUser } from '../../utilities/common';
import User from '../../models/User';
import UserForm from './UserForm';
import * as actions from '../../store/actions/index';
import * as selectors from './userSelectors';

const NewUserForm = props => { 
    const newUser = new User(undefined, '', '', 'ACTIVE', '', '', '', [], '');
    newUser.scenario = User.scenarios.CREATE;
    const [user, setUser] = useState(newUser);
    const backendErrors = useSelector(state => state.users.errors);
    let [validationErrors, setValidationErrors] = useState(undefined);
    // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
    validationErrors = validationErrors || backendErrors;
    const dispatch = useDispatch();
    const permissionsMap = useSelector(selectors.getPermissionsMap);

    useEffect(() => {
      dispatch(actions.user.fetchPermissionsMap());
      return () => dispatch(actions.user.resetUsersState());
   }, []);


   const updateUser = (field, value) => {
      const updatedUser = cloneUser(user);
      updatedUser[field] = value;
      updatedUser.scenario = User.scenarios.CREATE;
      setUser(updatedUser);
   }

   const submitHandler = () => {
      const errors = user.isValid();
      setValidationErrors(errors);
      if (errors) return;
      dispatch(actions.user.addNewUser(user));

  }

  const updatePermissions = (e, { checked, value }) => {
   let permissions;
   if (checked) {
      permissions = [...user.permissions, value]
   } else {
      permissions = user.permissions.filter(perm => perm !== value);
   }
   updateUser('permissions', permissions);
}

return (
   <>
      <UserForm formTitle="Create new user" user={user} mode='create' validationErrors={validationErrors} errors={backendErrors.global} permissionsMap={permissionsMap} onSubmit={submitHandler} onChange={updateUser} onPermissionsChange={updatePermissions} />
   </>
);
}

export default NewUserForm;