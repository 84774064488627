import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Container, Divider, Form, Tab, Button, Accordion, Message, Menu, TextArea, Dropdown } from 'semantic-ui-react';

import SubmitButton from '../common/SubmitButton';
import BackButton from '../common/BackButton';
import classes from './Users.module.css';
import PermissionItem from './PermissionItem';
import * as actions from '../../store/actions/index';
import * as selectors from './userSelectors';
import User from '../../models/User';
import { formatValidationErrors } from '../../utilities/common';

const UserForm = ({ formTitle, user, validationErrors, permissionsMap, errors=[], onSubmit, onChange, loading, mode, disabled, onPermissionsChange }) => {
   const { username, password, first_name, last_name, email, permissions, note } = validationErrors || {};

   const [activeIndex, setActiveIndex] = useState('user');
   const clickHandler = (e, { index }) => {
      setActiveIndex(index === activeIndex ? -1 : index);
   }

   const accordionItems = Object.keys(permissionsMap).map(key => {
      return (
         <PermissionItem
            key={'permission-' + key}
            title={key.charAt(0).toUpperCase() + key.slice(1) + ' permissions'}
            disabled={disabled}
            index={key}
            permissionItems={permissionsMap[key]['items']}
            active={key === activeIndex}
            clickHandler={clickHandler}
            updatePermissions={onPermissionsChange}
            userPermissions={user.permissions}
         />
      );
   });
   if (Object.keys(validationErrors).length) errors.unshift('Please check all tabs');

   const errorMsg = errors.length ? (
      <Message
         error
         header="User not saved"
         list={errors}
      />
   ) : null;

   const panes = [
      {
         menuItem: 'Details', render: () =>
            <Container className={classes.AddNewUserHolder} style={{ marginBottom: 0 }}>
               <Form loading={loading} className={classes.Form} disabled={disabled}>
                  <Form.Field>
                     <label>First name</label>
                     <Form.Input fluid name='first_name' value={user.first_name} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} error={first_name || null} />
                  </Form.Field>
                  <Form.Field>
                     <label>Last name</label>
                     <Form.Input fluid name={'last_name'} value={user.last_name} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} error={last_name || null} />
                  </Form.Field>
                  <Form.Field>
                     <label>Username</label>
                     <Form.Input fluid name={'username'} value={user.username} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} error={username || null} />
                  </Form.Field>
                  <Form.Field>
                     <label>Status</label>
                     <Dropdown fluid selection name={'status'} value={user.status} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} /* error={ validationErrors.username ? { content: validationErrors.username[0]} :  null } */ options={[{ key: 'ACTIVE', text: 'ACTIVE', value: 'ACTIVE' }, { key: 'INACTIVE', text: 'INACTIVE', value: 'INACTIVE' }]} />
                  </Form.Field>
                  {mode === 'create' ? (
                     <Form.Field>
                        <label>Password</label>
                        <Form.Input fluid type="password" name={'password'} value={user.password} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} error={password || null} />
                     </Form.Field>
                  ) : null}
                  <Form.Field>
                     <label>Email</label>
                     <Form.Input fluid name={'email'} value={user.email} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} error={email || null} />
                  </Form.Field>
                  <Form.Field>
                     <label>Note</label>
                     <TextArea name={'note'} value={user.note} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} error={note || null} />
                  </Form.Field>
               </Form>
            </Container>
      },
      {
         menuItem: 'Permissions', render: () =>
            <Container className={classes.AddNewUserHolder} style={{ marginBottom: 0 }}>
               <Accordion className={classes.PermissionAccordion} as={Menu} vertical styled fluid>
                  {accordionItems}
               </Accordion>
               {validationErrors && permissions ? (
                  <Message
                     error
                     //  header="Permissions are required"
                     content={permissions}
                  />
               ) : null}
            </Container>
      }
   ]


   return (
      <Container>
         <Divider horizontal>{formTitle}</Divider>
         <Tab panes={panes} />
         <Container className={classes.AddNewUserHolder} style={{ marginBottom: 0 }}>
            {errorMsg}
         </Container>
         <Container className={classes.AddNewUserHolder} textAlign="right">
            <BackButton />
            <SubmitButton submitHandler={onSubmit} disabled={disabled} />
         </Container>
      </Container>
   );
}
export default UserForm;