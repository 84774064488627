import { FETCH_CATEGORIES, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE, FETCH_CATEGORY, FETCH_CATEGORY_SUCCESS, FETCH_CATEGORY_FAILURE, ADD_NEW_CATEGORY, ADD_NEW_CATEGORY_SUCCESS, ADD_NEW_CATEGORY_FAILURE, UPDATE_CATEGORY, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_FAILURE, RESET_CATEGORIES_STATE } from '../actions/actionTypes';

export const fetchCategories = (level='all') => {
   return {
      type: FETCH_CATEGORIES,
      level,
   }
}

export const fetchCategoriesSuccess = categories => {
   return {
      type: FETCH_CATEGORIES_SUCCESS,
      categories,
   }
}

export const fetchCategoriesFailure = errors => {
   return {
      type: FETCH_CATEGORIES_FAILURE,
      errors,
   }
}

export const fetchCategory = id => {
   return {
      type: FETCH_CATEGORY,
      id,
   }
}

export const fetchCategorySuccess = category => {
   return {
      type: FETCH_CATEGORY_SUCCESS,
      category,
   }
}

export const fetchCategoryFailure = errors => {
   return {
      type: FETCH_CATEGORY_FAILURE,
      errors,
   }
}

export const updateCategory = category => {
   return {
      type: UPDATE_CATEGORY,
      category,
   }
}

export const updateCategorySuccess = category => {
   return {
      type: UPDATE_CATEGORY_SUCCESS,
      category,
   }
}

export const updateCategoryFailure = errors => {
   return {
      type: UPDATE_CATEGORY_FAILURE,
      errors,
   }
}

export const addNewCategory = category => {
   return {
      type: ADD_NEW_CATEGORY,
      category,
   }
}
export const addNewCategorySuccess = category => {
   return {
      type: ADD_NEW_CATEGORY_SUCCESS,
      category,
   }
}
export const addNewCategoryFailure = errors => {
   return {
      type: ADD_NEW_CATEGORY_FAILURE,
      errors,
   }
}

export const resetCategoriesState = () => {
   return {
      type: RESET_CATEGORIES_STATE
   }
}