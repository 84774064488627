import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import CategoryForm from './CategoryForm';
import Category from '../../models/Category';
import * as actions from '../../store/actions/index';
import { cloneCategory } from '../../utilities/common';

const EditCategoryForm = ({ mode }) => {
   const emptyCategory = cloneCategory({ id: undefined, name: 'test', level: [] });
   const categoryId = useParams().id;
   const backendErrors = useSelector(state => state.categories.errors);
   let [validationErrors, setValidationErrors] = useState(undefined);
   const selectedCategory = useSelector(state => state.categories.category) || emptyCategory;
   const loading = !useSelector(state => state.categories.categoryLoaded);
   const [category, setCategory] = useState(selectedCategory);
   category.scenario = Category.scenarios.UPDATE;
   // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
   validationErrors = validationErrors || backendErrors;
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(actions.categories.fetchCategory(categoryId));
      return () => dispatch(actions.categories.resetCategoriesState());
   }, []);

   useEffect(() => {
      setCategory(selectedCategory);
   }, [selectedCategory]);

   const submitHandler = () => {
      const errors = category.isValid();
      setValidationErrors(errors);
      if (errors) return;
      dispatch(actions.categories.updateCategory(category));
   }

   const updateCategory = (name, value) => {
      const updatedCategory = cloneCategory(category);
      updatedCategory[name] = value;
      setCategory(updatedCategory);
   }
   const title = mode === 'view' ? 'View category' : 'Update category';
   return (
      <CategoryForm title={title} category={category} mode={mode} disabled={mode === 'view'} errors={backendErrors.global} validationErrors={validationErrors} loading={loading} onChange={updateCategory} onSubmit={submitHandler} />
   );
}

export default EditCategoryForm;