import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AddNewContent from '../common/AddNewButton';
import FagcTable from '../table/FagcTable';
import withPermissions from '../../hoc/withPermissions';
import * as actions from '../../store/actions/index';
import { imagePopupRenderer } from '../table/cellRenderers';

const labels = {
   content_id: 'Content ID',
   title: 'Title',
   categories: 'Categories',
   projects: 'Projects',
   status: 'Status',
   image_1: 'Img'
};

const CTable = withPermissions(FagcTable);

const ContentTable = ({ cType, path, addNewText }) => {
   cType = cType.slice(1);
   cType = cType.replaceAll("-", "");
   const dispatch = useDispatch();
   const contentLoaded = useSelector(state => state.contents[`${cType}Loaded`]);
   const content = useSelector(state => { return state.contents[cType]; });

   useEffect(() => {
      dispatch(actions.content.fetchContents(cType))
   }, [cType]);

   return (
      <div id="content_table_container">
         <AddNewContent text={addNewText} to={`${path}/new`} requiredPermissions={{ any: ['create_content'] }} />
         <CTable data={content} columns={labels} actions={{
            eye: { path: data => `${path}/view/${data.id}`, permissions: { any: ['access_all_contents'] } },
            edit: { path: data => `${path}/edit/${data.id}`, permissions: { any: ['update_content'] } },
         }}
            pagination={{ active: 1, pageSize: 20 }}
            loading={!contentLoaded}
            requiredPermissions={{ any: ['access_all_contents'] }}
            renderer={imagePopupRenderer}
         />
      </div>
   );
}

export default ContentTable;