import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ContentForm from '../ContentForm';

import { cloneGameNews } from '../../../utilities/common';
import useContent from '../../../hooks/useContent';
import * as actions from '../../../store/actions/index';
import GameNewsOptions from './GameNewsOptions';

const GameNewsForm = ({ mode }) => {
   const emptyGameNews = cloneGameNews({
      body: "",
      source: "",
      image_1: "",
      image_2: "",
      image_3: "",
      id: "", 
      content_id: "",
      title: "", 
      categories: [], 
      tags: {}, 
      media: "", 
      projects: [], 
      status: 'ACTIVE',
      keywords: "",
      news_date: null,
   });

   const gameNewsId = useParams().id;
   const {
      projectsLoaded,
      projects,
   } = useContent('game');

   const {
      backendErrors,
      contentLoaded,
      tagsLoaded,
      tags,
      categoriesLoaded,
      categories,
      selectedContent,
      requestPending,
      dispatch
   } = useContent('gamenews');

   let [validationErrors, setValidationErrors] = useState(undefined);
   const [selectedProjectId, setSelectedProjectId] = useState();
   const [gameNews, setGameNews] = useState(emptyGameNews);
   // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
   validationErrors = validationErrors || backendErrors;

   useEffect(() => {
      if (!projectsLoaded) dispatch(actions.projects.fetchProjects());
      if (!tagsLoaded) dispatch(actions.tags.fetchTags());
      if (!categoriesLoaded) dispatch(actions.categories.fetchCategories());
      if (mode !== 'new' && !contentLoaded) dispatch(actions.content.fetchContent(gameNewsId, 'gamenews'));
      return () => dispatch(actions.content.resetContentState());
   }, []);

   useEffect(() => {
      if (selectedContent && mode !== 'new')
      setGameNews(selectedContent);
   }, [selectedContent]);

   const updateGameNews = (name, value) => {

    setGameNews((gameNews) => {
         const updatedGameNews = cloneGameNews(gameNews);
         if (name === 'tags') {
            updatedGameNews.tags = { ...updatedGameNews.tags, [selectedProjectId]: value }
         } else if (name === 'keywords') {
            updatedGameNews.keywords = value.join(',');
         } else if (name === 'projects') {
            const updatedTags = {};
            value.forEach(projectId => updatedTags[projectId] = updatedGameNews.tags[projectId]);
            updatedGameNews['projects'] = value;
            updatedGameNews['tags'] = updatedTags;
         } else {
            updatedGameNews[name] = value;
         }
         return updatedGameNews;
      });
   }

   const selectedProjectChangeHandler = projectId => {
      setSelectedProjectId(projectId);
   }

   const submitHandler = () => {
      const errors = gameNews.isValid();
      setValidationErrors(errors);
      if (errors) return;
      switch (mode) {
         case 'new':
            dispatch(actions.content.addNewContent(gameNews, 'gamenews'));
            break;
         case 'edit':
            dispatch(actions.content.updateContent(gameNews, 'gamenews'));
            break;
      }
   }

   const handleEditorChange = (content, editor) => {
    const updatedGameNews = cloneGameNews(gameNews);
    updatedGameNews.body = content;
    setGameNews(updatedGameNews);
 } 

   const loading = !projectsLoaded || !tagsLoaded || !categoriesLoaded || (mode !== 'new' && !contentLoaded) || requestPending;
   return (
      <ContentForm
         content={gameNews}
         contentType="Game News"
         validationErrors={validationErrors}
         errors={backendErrors.global}
         onChange={updateGameNews}
         onSubmit={submitHandler}
         projects={projects}
         selectedProjectId={selectedProjectId}
         projectSelectedHandler={selectedProjectChangeHandler}
         tags={tags}
         categories={categories}
         loading={loading}
         mode={mode}
      >
         {(content, onChange, errors, validationErrors, loading, mode) => {
            return (
               <GameNewsOptions
                  gameNews={content}
                  onChange={onChange}
                  errors={errors}
                  validationErrors={validationErrors}
                  loading={loading}
                  mode={mode}
                  onEditorChange = {handleEditorChange}
               />
            );
         }}
      </ContentForm>
   );
}

export default GameNewsForm;