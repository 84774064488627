import React, { useState } from 'react';
import { Button, Form, Label, Item, Segment } from 'semantic-ui-react';

import classes from '../Content.module.css';


const GameReviewContent = props => {
   const { content, onChange, validationErrors, disabled } = props;

   const [reviewUrls, setReviewUrls] = useState(content ?? [{ name: "", url: "", rating: ""}]);

   const addReviewerHandler = event => {
      event.preventDefault();
      setReviewUrls([
         ...reviewUrls,
         { name: "", url: "", rating: ""}
      ]);

      onChange('review_urls', reviewUrls)
   }

   const removeReviewUrl = index => {
      reviewUrls.splice(index, 1);
      setReviewUrls(reviewUrls);
      onChange('review_urls', reviewUrls);

   }

   const handleFieldChange = event => {
      if ( ['name', 'url', 'rating'].includes(event.target.name)) {
         let reviewUrlsArray = [ ...reviewUrls ];
         reviewUrlsArray[event.target.id][event.target.name] = event.target.value;
         setReviewUrls(reviewUrlsArray);
         onChange('review_urls', reviewUrls);
      }
   }

   return (
      <Item className={`${classes.ReviewContainer} ${validationErrors.review_urls ? classes.Error : ''}`}>
         <Item className={classes.itemsContainer}>
            <Label className={classes.ReviewLabel} horizontal>
               Review urls and score:
            </Label>
            <Button className={classes.ChooseTech} onClick={(e) => addReviewerHandler(e)} disabled={disabled}>Add Reviewer</Button>
         </Item>
         <Segment className={classes.ReviewSegment}>
            {reviewUrls.map((reviewUrl, index) => {
               return (
                  <Form.Field name="review_urls" className={classes.ReviewField}>
                     <Form.Input className={classes.ReviewInput} label="Name:" id={index} type="text" name="name" value={reviewUrl.name} onChange={handleFieldChange} disabled={disabled} />
                     <Form.Input className={classes.ReviewInput} label="Url:" id={index} type="text" name="url" value={reviewUrl.url} onChange={handleFieldChange} disabled={disabled} />
                     <Form.Input className={classes.ReviewInput} label="Rating (%):" id={index} type="text" name="rating" value={reviewUrl.rating} onChange={handleFieldChange} disabled={disabled} />
                     <Button className={classes.deleteReviewButton} onClick={() => removeReviewUrl(index)} disabled={disabled}>Remove</Button>
                  </Form.Field>
               )
            })}
         </Segment>
         {validationErrors.review_urls ? (
               <Form.Field>
                  <div class="ui pointing above prompt label" role="alert" aria-atomic="true">{validationErrors.review_urls}</div>
               </Form.Field>
            ) : null}
      </Item>
   );
}

export default GameReviewContent;