import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import AllowedIp from '../../models/AllowedIp';
import IpForm from './IpForm';
import * as actions from '../../store/actions/index';
import { cloneIp } from '../../utilities/common';

const EditIpForm = ({ mode }) => {
   const ipId = useParams().id;
   const backendErrors = useSelector(state => state.ips.errors);
   let [validationErrors, setValidationErrors] = useState(undefined);
   const selectedIp = useSelector(state => state.ips.ip);
   const loading = !useSelector(state => state.ips.ipLoaded);
   const [ip, setIp] = useState(selectedIp);
   // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
   validationErrors = validationErrors || backendErrors;
   const title = mode === 'edit' ? 'Update IP address' : 'View IP address'
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(actions.ips.fetchIp(ipId));
      return () => dispatch(actions.ips.resetIpState());
   }, []);

   useEffect(() => {
      setIp(selectedIp);
      //legal.scenario = Legal.scenarios.UPDATE;
   }, [selectedIp]);

   const submitHandler = () => {
      const errors = ip.isValid();
     setValidationErrors(errors);
     if (errors) return;
      if (mode === 'edit') {
         dispatch(actions.ips.updateIp(ip));
       }
   }

   const updateIp = (name, value) => {
      const updatedIp = cloneIp(ip);
      updatedIp[name.toLowerCase()] = value;
      setIp(updatedIp);
   }

   const handleEditorChange = (content, editor) => {
      const updatedIp = cloneIp(ip);
      updatedIp.content = content;
      setIp(updatedIp);
   }

   return (
      <IpForm formTitle={title} ip_add={ip} validationErrors={validationErrors} errors={backendErrors} loading={loading} onSubmit={submitHandler} onChange={updateIp} mode={mode} onEditorChange={handleEditorChange} disabled={mode === 'view'}/>
   );
}

export default EditIpForm;