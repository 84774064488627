import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import CategoriesTable from './CategoriesTable';
import NewCategory from './NewCategoryForm';
import EditCategory from './EditCategoryForm';

const Categories = props => {

   const {path, url} = useRouteMatch();

   return (
      <Switch>
         <Route path={`${path}/new`}>
            <NewCategory />
         </Route>
         <Route path={`${path}/view/:id`}>
            <EditCategory mode="view" />
         </Route>
         <Route path={`${path}/edit/:id`}>
            <EditCategory mode="edit" />
         </Route>
         <Route path={`${path}`}>
            <CategoriesTable path={path} />
         </Route>
      </Switch>
   );
}

export default Categories;