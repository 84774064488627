import React from 'react';
import { Form, Segment, Label, Grid } from 'semantic-ui-react';

import SelectWithCheckBoxes from '../common/SelectWithCheckBoxes';
import classes from './Content.module.css';

const GeneralOptions = ({ content, onChange, onProjectSelected, projects, selectedProjectId, tags, categories, validationErrors, mode, loading }) => {
   const selectedProject = projects.find(proj => proj.id === selectedProjectId) || {};
   const keywordsOptions = content.keywords.split(',').map(word => ({ text: word, value: word }));
   const keywords = content.keywords ? content.keywords.split(',') : [];
   const statusOptions = [{ text: 'ACTIVE', value: 'ACTIVE' }, { text: 'INACTIVE', value: 'INACTIVE' }];
   const categoriesOptions = categories.map(cat => ({ text: cat.name, value: cat.id }))
   const disabled = mode === 'view';

   const projectsLabelStyle = {
      color: '#888',
      backgroundColor: 'transparent',
   }
   const tagsLabelStyle = {
      color: '#ff4040',
      backgroundColor: 'transparent',
   }

   const changeHandler = (event, data) => {
      onChange(data.name, data.value);
   }

   const contentIncludesProject = () => {
      return content.projects.includes(selectedProjectId);
   }

   const projectsLabel = 'Select projects:';
   let tagsLabel = 'Please select project first';
   if(contentIncludesProject()) {
      if (selectedProject.text) {
         tagsLabelStyle.color = '#888';
         if (disabled) {
            tagsLabel = `View tags for ${selectedProject.text}`;
         } else {
            tagsLabel = `Selects tags for ${selectedProject.text}`;
         }
      }
   } else {
      if (selectedProject.text) {
         if (disabled) {
            tagsLabel = `Project ${selectedProject.text} is not included, no tags to show`;
         } else {
            tagsLabel = `Please include project ${selectedProject.text} to select tags`;
         }
      }
   }

   return (
      <Form loading={loading} className={classes.Form}>
         {mode !== 'new' ? <Form.Input label="ID:" type="text" name="id" value={content.id} disabled /> : null}
         {mode !== 'new' ? <Form.Input label="Content ID:" type="text" name="content_id" value={content.content_id} disabled /> : null}
         <Form.Input label="Title:" type="text" name="title" value={content.title} onChange={changeHandler} error={validationErrors.title || null} disabled={disabled} />
         <Form.TextArea label="Description:" name="description" value={content.description} onChange={changeHandler} error={validationErrors.description || null} disabled={disabled} />
         <Form.Dropdown className={classes.Keywords} label="Keywords:" name="keywords" allowAdditions search options={keywordsOptions} multiple selection value={keywords} onChange={changeHandler} error={validationErrors.keywords || null} disabled={disabled} />
         <Form.Select label="Status:" options={statusOptions} name="status" value={content.status} onChange={changeHandler} error={validationErrors.status || null} disabled={disabled} />
         <Form.Dropdown className={classes.Categories} label="Categories:" name="categories" search options={categoriesOptions} multiple selection value={content.categories} onChange={changeHandler} error={validationErrors.categories || null} disabled={disabled} />
         <Form.Field>
            <label>Projects:</label>
            <Segment as="fieldset" className={classes.ProjectsFieldset}>
               <Grid columns={2}>
                  <Grid.Column>
                     <Label style={projectsLabelStyle}>{projectsLabel}</Label>
                     <SelectWithCheckBoxes
                        name="projects"
                        items={projects}
                        onChange={onChange}
                        onSelectionChange={onProjectSelected}
                        selectedItemId={selectedProjectId}
                        placeholder={'Filter projects'}
                        value={content.projects}
                        labelDisabled={false}
                        checkBoxesDisabled={disabled}
                     />
                  </Grid.Column>
                  <Grid.Column>
                     <Label style={tagsLabelStyle}>{tagsLabel}</Label>
                     <SelectWithCheckBoxes
                        disabled={!selectedProjectId}
                        name="tags"
                        items={tags}
                        onChange={onChange}
                        onSelectionChange={() => { }}
                        placeholder={`Filter tags`}
                        value={(content.tags && content.tags[selectedProjectId] || [] || [])}
                        labelDisabled={true}
                        checkBoxesDisabled={disabled || !contentIncludesProject()}
                     />
                  </Grid.Column>
               </Grid>
            </Segment>
         </Form.Field>
      </Form>
   );
}

export default GeneralOptions;