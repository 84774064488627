import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CategoryForm from './CategoryForm';
import Category from '../../models/Category';
import { cloneCategory } from '../../utilities/common';
import * as actions from '../../store/actions/index';

const NewCategoryForm = props => {
   const newCategory = new Category(undefined, '', []);
   const [category, setCategory] = useState(newCategory);
   category.scenario = Category.scenarios.CREATE;
   const backendErrors = useSelector(state => state.categories.errors);
   let [validationErrors, setValidationErrors] = useState(undefined);
   validationErrors = validationErrors || backendErrors;
   const dispatch = useDispatch();

   useEffect(() => {
      return () => dispatch(actions.categories.resetCategoriesState());
   }, []);

   const submitHandler = () => {
      const errors = category.isValid();
      setValidationErrors(errors);
      if (errors) return;
      dispatch(actions.categories.addNewCategory(category));
   }

   const updateCategory = (name, value) => {
      const updatedCategory = cloneCategory(category);
      updatedCategory[name] = value;
      setCategory(updatedCategory);
   }

   return (
      <CategoryForm title="Add new category" category={category} errors={backendErrors.global} validationErrors={validationErrors} onChange={updateCategory} onSubmit={submitHandler} />
   );
}

export default NewCategoryForm; 