import { validate } from 'validate.js';

class ClearCdn {
   type;
   cdn_zone;
   urls;
   zone_id;
   scenario = scenarios.DEFAULT;
   labels = { 
		zone_id : 'Zone ID',
		domain : 'Domain',
	};
   
   constructor (type, cdn_zone, urls, zone_id) {
      this.type = type;
      this.cdn_zone = cdn_zone;
      this.urls = urls;
      this.zone_id = zone_id;
   }

   isValid = () => {
      const defaultErr = validate(this, constraints[ClearCdn.scenarios.DEFAULT]);
      if (this.scenario === ClearCdn.scenarios.DEFAULT) return defaultErr;
      const err = validate(this, constraints[this.scenario]);
      if(!defaultErr && !err) return undefined;

      let allErrors = {...defaultErr};
      if (err) {
         Object.keys(err).forEach(key => {
            if(allErrors[key]) allErrors[key] = [...allErrors[key], ...err[key]];
            else allErrors[key] = err[key]; 
         });
      }

      return allErrors;
   }
}

const scenarios = {
   DEFAULT: "DEFAULT",
}

const constraints = {
    "DEFAULT": []
}

ClearCdn.scenarios = scenarios;
ClearCdn.constraints = constraints;

export default ClearCdn;
