import React, { useState } from 'react';
import { Form, Segment, Grid } from 'semantic-ui-react';

import FileSelect from '../../common/FileSelect';
import Image from '../../common/Image';
import classes from '../Content.module.css';

const QuizOptions = ({ quiz, onChange, errors, validationErrors, loading, mode }) => {
   const quizTypeOptions = ['IFRAME', 'SCRIPT'].map(item => ({ text: item, value: item }));
   const [imageSrc, setImageSource] = useState();
   const [previewActive, setPreviewActive] = useState();

   const previewHandler = (name, value) => {
      setPreviewActive(name);
      if (value instanceof File) {
         const reader = new FileReader();
         reader.onload = event => setImageSource(event.target.result);
         reader.readAsDataURL(value);
      } else {
         if (value) {
            setImageSource(value);

         } else {
            setImageSource('')
         }
      }
   }

   const changeHandler = (event, data) => {
      onChange(data.name, data.value);
   }

   const mediaChangeHandler = (name, value) => {
      setPreviewActive(name);
      onChange(name, value);
   }
   
   const disabled = mode === 'view';
   return (
      <Form loading={loading} className={classes.Form}>
         <Form.Select label="Quiz Type" name="quiz_type" options={quizTypeOptions} value={quiz.quiz_type} onChange={changeHandler} error={validationErrors.quiz_type || null} disabled={disabled} />
         {quiz.quiz_type === 'IFRAME' ? <Form.Input type="text" label="Iframe Link" name="quiz_link" value={quiz.quiz_link} onChange={changeHandler} error={validationErrors.quiz_link || null} disabled={disabled} /> : null}
         {quiz.quiz_type === 'SCRIPT' ? <Form.TextArea label="Quiz Script" name="quiz_script" value={quiz.quiz_script} onChange={changeHandler} error={validationErrors.quiz_script || null} disabled={disabled} rows={7} /> : null}
         <Form.Field>
            <label>Media:</label>
            <Segment as='fieldset' className={classes.MediaSegment}>
               <Grid columns={2}>
                  <Grid.Column>
                     <FileSelect label="Image 1 (240x220):" name="image_1" previewActive={previewActive === 'image_1'} value={quiz.image_1} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_1 || null} disabled={disabled} />
                     <FileSelect label="Image 2 (728x380):" name="image_2" previewActive={previewActive === 'image_2'} value={quiz.image_2} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_2 || null} disabled={disabled} />
                     <FileSelect label="Image 3 (420x220):" name="image_3" previewActive={previewActive === 'image_3'} value={quiz.image_3} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_3 || null} disabled={disabled} />
                     <FileSelect label="Image 4 (1700x700):" name="image_4" previewActive={previewActive === 'image_4'} value={quiz.image_4} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_4 || null} disabled={disabled} />
                  </Grid.Column>
                  <Grid.Column textAlign="center" verticalAlign="middle">
                     <Image src={imageSrc} className={classes.PreviewImage} />
                  </Grid.Column>
               </Grid>
            </Segment>
         </Form.Field>
      </Form>
   );
}

export default QuizOptions;