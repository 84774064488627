export default [
   {
      title: 'Projects',
      icon: 'clipboard',
      path: '/projects',
      items: [],
      permissions: {
         any: ['create_project', 'update_project', 'access_all_projects'],
      }
   },
   {
      title: 'Content',
      icon: 'content',
      items: [
         { title: 'Games', to: '/games', permissions: { any: ['create_content', 'update_content', 'access_all_contents']} },
         { title: 'Game Deals', to: '/game-deals', permissions: { any: ['create_content', 'update_content', 'access_all_contents']} },
         { title: 'Game News', to: '/all-game-news', permissions: { any: ['create_content', 'update_content', 'access_all_contents']} },
         { title: 'Game Wallpapers', to: '/game-wallpapers', permissions: { any: ['create_content', 'update_content', 'access_all_contents']} },
         { title: 'Quizzes', to: '/quizzes', permissions: { any: ['create_content', 'update_content', 'access_all_contents']} },
         { title: 'Recipes', to: '/recipes', permissions: { any: ['create_content', 'update_content', 'access_all_contents']} },
         { title: 'Coupons', to: '/coupons', permissions: { any: ['create_content', 'update_content', 'access_all_contents']} },
         { title: 'Tools', to: '/tools', permissions: { any: ['create_content', 'update_content', 'access_all_contents']} },
         { title: 'br', key: 'coupon-categories', permissions: {} },
         { title: 'Categories', to: '/categories', permissions: { any: ['create_category', 'update_category', 'access_all_categories'] } },
         { title: 'Tags', to: '/tags', permissions: { any: ['create_tag', 'update_tag', 'access_all_tags'] } },
      ],
      permissions: {
         any: ['create_content', 'update_content', 'access_all_contents', 'create_category', 'update_category', 'access_all_categories', 'create_tag', 'update_tag', 'access_all_tags'],
      }
   },
   {
      title: 'Legal Docs',
      icon: 'legal',
      path: '/legal-docs',
      items: [],
      permissions: {
         any: ['manage_legal_template', 'access_all_legals'],
      }
   },
   {
      title: 'Tools',
      icon: 'cogs',
      items: [
         { title: 'CDN Cache', to: '/cdn-cache', permissions: { any: ['clear_cdn'] } },
         { title: 'Allowed IPs', to: '/allowed-ips', permissions: { any: ['allowed_ip'] } },
      ],
      permissions: {
         any: ['clear_cdn', 'allowed_ip'],
      }
   },
   {
      title: 'Users',
      icon: 'users',
      path: '/users',
      items: [],
      permissions: { any: ['create_user', 'update_user', 'access_all_users'] }
   },
   {
      title: 'Reports',
      icon: 'chart line',
      path: '/reports',
      items: [
         { title: 'Games', to: '/reports/game', permissions: { any: ['content_report']} },
         { title: 'Recipes', to: '/reports/recipe', permissions: { any: ['content_report']} },
      ],
      permissions: { any: ['content_report'] }
   },
];