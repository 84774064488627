import { FETCH_CONTENT_COUNTS, FETCH_CONTENT_COUNTS_SUCCESS, FETCH_CONTENT_COUNTS_FAILURE, FETCH_PROJECT_COUNTS, FETCH_PROJECT_COUNTS_SUCCESS, FETCH_PROJECT_COUNTS_FAILURE } from '../actions/actionTypes';

const initialState = {
   content: [],
   contentErrors: '',
   project: [],
   projectErrors: '',
}

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case FETCH_CONTENT_COUNTS:
         return {
            ...state,
            content: [],
            contentErrors: '',
         }
      case FETCH_CONTENT_COUNTS_SUCCESS:
         return {
            ...state,
            content: action.data
         }
      case FETCH_CONTENT_COUNTS_FAILURE:
         return {
            ...state,
            contentErrors: action.errors,
         }
      case FETCH_PROJECT_COUNTS:
         return {
            ...state,
            project: [],
            projectErrors: '',
         }
      case FETCH_PROJECT_COUNTS_SUCCESS:
         return {
            ...state,
            project: action.data
         }
      case FETCH_PROJECT_COUNTS_FAILURE:
         return {
            ...state,
            projectErrors: action.errors,
         }
      default:
         return state;
   }
}

export default reducer;