import { put, call } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { status, json, processResponse } from '../../utilities/fetchUtils';

export function* fetchUsersSaga(action) {
   try {
      const response = yield call(fetch, './user-service/get-all-users', {
         method: 'post',
         headers: {
            "Content-type": "application/json"
         },
      });

      const usersData = yield call(processResponse, response);
      yield put(actions.user.fetchUsersSuccess(usersData.data));
   } catch (e) {
      console.log(e);
      yield put(actions.user.fetchUsersFailure(e.messages || e.message));
   }
}

export function* changePasswordSaga(action) {
   try {
      const response = yield call(fetch, './user-service/change-my-password', {
         method: 'post',
         headers: {
            "Content-type": "application/json"
         },
         body: JSON.stringify({
            password: action.newPassword,
            passwordRetype: action.confirmPassword,
            oldPassword: action.oldPassword,
         }),
      });

      const changePasswordData = yield call(processResponse, response);
      yield put(actions.user.changePasswordSuccess(changePasswordData.status));
   } catch (e) {
      console.log(e);
      //const key=Object.keys(e.messages)[0];
      yield put(actions.user.changePasswordFailure(e.messages || e.message));
   }
}

export function* addNewUserSaga(action) {
   const { username, password, status, first_name, last_name, email, permissions, note } = action.user;
   try {
      const response = yield call(fetch, '/user-service/create-user', {
         method: 'POST',
         headers: {
            "Content-type": "application/json"
         },
         body: JSON.stringify({
            username,
            password,
            email,
            first_name,
            last_name,
            status,
            note,
            permissions,
         }),
      });
      const addNewUserData = yield call(processResponse, response);
      yield put(actions.user.addNewUserSuccess(addNewUserData.data));
      yield put(actions.app.redirectTo('/users'));
   } catch (e) {
      console.log(e);
      yield put(actions.user.addNewUserFailure(e.messages || e.message));
   }
}

export function* updateUserSaga(action) {
   const { id, username, password, status, first_name, last_name, email, permissions, note } = action.user;
   try {
      const response = yield call(fetch, '/user-service/update-user', {
         method: 'POST',
         headers: {
            "Content-type": "application/json"
         },
         body: JSON.stringify({
            id,
            username,
            password,
            email,
            first_name,
            last_name,
            status,
            note,
            permissions,
         }),
      });
      const updateUserData = yield call(processResponse, response);
      yield put(actions.user.updateUserSuccess(updateUserData.data));
      yield put(actions.app.redirectTo('/users'));
   } catch (e) {
      console.log(e);
      yield put(actions.user.updateUserFailure(e.messages || e.message));
   }
}

export function* fetchPermissionsMapSaga(action) {
   try {
      const response = yield call(fetch, '/user-service/get-permission-map', {
         method: 'post',
         headers: {
            "Content-type": "application/json"
         },
      });

      const usersPermissionsData = yield call(processResponse, response);
      yield put(actions.user.fetchPermissionsMapSuccess(usersPermissionsData.data));
   } catch (e) {
      console.log(e);
      yield put(actions.user.fetchPermissionsMapFailure(e.messages || e.message));
   }
}

export function* fetchUserSaga(action) {
   try{
      const response = yield call(fetch, '/user-service/get-user', {
         method: 'post',
         headers: {
            "Content-type": "application/json"
         },
         body: JSON.stringify({
            id: action.id,
         }),
      });
      const userData = yield call(processResponse, response);
      yield put(actions.user.fetchUserSuccess(userData.data));
   } catch (e) {
      console.log(e);
      yield put(actions.user.fetchUserFailure())
   }
}
