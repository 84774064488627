import {
    FETCH_CDN_ZONES,
    FETCH_CDN_ZONES_SUCCESS,
    FETCH_CDN_ZONES_FAILURE,
    CDN_CLEAR_FILES,
    CDN_CLEAR_FILES_SUCCESS,
    CDN_CLEAR_FILES_FAILURE,
    RESET_CDN_STATE,
    CDN_CLEAR_ZONE,
    CDN_CLEAR_ZONE_SUCCESS,
    CDN_CLEAR_ZONE_FAILURE,
} from '../actions/actionTypes';

export const fetchCdnZones = () => {
    return {
        type: FETCH_CDN_ZONES,
    }
}
export const fetchCdnZonesSuccess = (cdnZones) => {
    return {
        type: FETCH_CDN_ZONES_SUCCESS,
        cdnZones,
    }
}
export const fetchCdnZonesFailure = (errors) => {
    return {
        type: FETCH_CDN_ZONES_FAILURE,
        errors,
    }
}

export const cdnClearZone = clearCdn => {
    return {
        type: CDN_CLEAR_ZONE,
        clearCdn
    }
}

export const cdnClearZoneSuccess = messages => {
    return {
        type: CDN_CLEAR_ZONE_SUCCESS,
        messages,
    }
}

export const cdnClearZoneFailure = errors => {
    return {
        type: CDN_CLEAR_ZONE_FAILURE,
        errors,
    }
}

export const cdnClearFiles = clearCdn => {
    return {
        type: CDN_CLEAR_FILES,
        clearCdn
    }
}

export const cdnClearFilesSuccess = messages => {
    return {
        type: CDN_CLEAR_FILES_SUCCESS,
        messages,
    }
}

export const cdnClearFilesFailure = errors => {
    return {
        type: CDN_CLEAR_FILES_FAILURE,
        errors,
    }
}

export const resetCdnState = () => {
    return {
        type: RESET_CDN_STATE,
    }
}