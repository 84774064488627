import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FagcTable from '../table/FagcTable'
import withModel from '../../hoc/table/withModel';
import Category from '../../models/Category';
import AddNewCategory from '../common/AddNewButton';
import * as actions from '../../store/actions/index';
import withPermissions from '../../hoc/withPermissions';

const Table = withPermissions(withModel(FagcTable, Category));

const CategoriesTable = ({ path }) => {
   const categoriesLoaded = useSelector(state => state.categories.categoriesLoaded);
   const categories = useSelector(state => state.categories.categories);
   const dispatch = useDispatch();

   useEffect(() => {
      if (!categoriesLoaded) {
         dispatch(actions.categories.fetchCategories('all'));
      }
   }, [categoriesLoaded, dispatch]);

   return (
      <>
         <AddNewCategory text="Add new category" to={`${path}/new`} requiredPermissions={{ any: ['create_category'] }} />
         <Table data={categories} loading={!categoriesLoaded} actions={{
            eye: { path: data => `${path}/view/${data.id}`, permissions: { any: ['access_all_categories'] } },
            edit: { path: data => `${path}/edit/${data.id}`, permissions: { any: ['update_category'] } },
         }} requiredPermissions={{ any: ['access_all_categories'] }} />
      </>
   );
}

export default CategoriesTable;