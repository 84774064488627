import { validate } from 'validate.js';

class Recipe {
   ingredients;
   instructions;
   image_1;
   image_2;
   image_3;
   image_4;
   embed_video;
   id;
   content_id;
   type;
   title;
   description;
   keywords;
   categories;
   tags;
   media;
   projects;
   config;
   status;
   creation_datetime;
   update_datetime;
   scenario = scenarios.DEFAULT;
   labels = { 
		ingredients : 'Ingredients',
		instructions : 'Instructions',
		image_1 : 'Image 1 (110 x 110 px)',
		image_2 : 'Image 2 (280 x 245 px)',
		image_3 : 'Image 3 (1000 x 335 px)',
		image_4 : 'Image 4 (1200 x 420 px)',
		id : 'ID',
		content_id : 'Content ID',
		type : 'Type',
		title : 'Title',
		description : 'Description',
		keywords : 'Keywords',
		categories : 'Categories',
		tags : 'Tags',
		media : 'Media',
		projects : 'Projects',
		config : 'Config',
		status : 'Status',
		creation_datetime : 'Created',
		update_datetime : 'Updated',
	};
   
   constructor (ingredients, instructions, image_1, image_2, image_3, image_4, embed_video, id, content_id, type, title, description, keywords, categories, tags, media, projects, config, status, creation_datetime, update_datetime) {
      this.ingredients = ingredients;
      this.instructions = instructions;
      this.image_1 = image_1;
      this.image_2 = image_2;
      this.image_3 = image_3;
      this.image_4 = image_4;
      this.embed_video = embed_video;
      this.id = id;
      this.content_id = content_id;
      this.type = type;
      this.title = title;
      this.description = description;
      this.keywords = keywords;
      this.categories = categories;
      this.tags = tags;
      this.media = media;
      this.projects = projects;
      this.config = config;
      this.status = status;
      this.creation_datetime = creation_datetime;
      this.update_datetime = update_datetime;
   }

   isValid = () => {
      const defaultErr = validate(this, constraints[Recipe.scenarios.DEFAULT]);
      if (this.scenario === Recipe.scenarios.DEFAULT) return defaultErr;
      const err = validate(this, constraints[this.scenario]);
      if(!defaultErr && !err) return undefined;

      let allErrors = {...defaultErr};
      if (err) {
         Object.keys(err).forEach(key => {
            if(allErrors[key]) allErrors[key] = [...allErrors[key], ...err[key]];
            else allErrors[key] = err[key]; 
         });
      }

      return allErrors;
   }
}

const scenarios = {
   DEFAULT: "DEFAULT",
}

const constraints = {
    "DEFAULT": {
        "description": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be at most 4096 symbols",
                "maximum": 4096
            }
        },
        "ingredients": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "instructions": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "embed_video": {
            "length": {
                "message": "should be at most 256 symbols",
                "maximum": 256
            }
        },
        "status": {
            "inclusion": {
                "within": [
                    "ACTIVE",
                    "INACTIVE"
                ],
                "message": "is invalid"
            },
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "type": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "title": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be at most 2048 symbols",
                "maximum": 2048
            }
        },
        "keywords": {
            "length": {
                "message": "should be at most 512 symbols",
                "maximum": 512
            }
        }
    }
}

Recipe.scenarios = scenarios;
Recipe.constraints = constraints;

export default Recipe;
