import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Divider } from 'semantic-ui-react';
import * as actions from '../../store/actions/index';

import CountCard from './CountCard';
import classes from './Dashboard.module.css';

const contentLinks = {
   apps: '/tools',
   coupon: '/coupons',
   game: '/games',
   quiz: '/quizzes',
   recipe: '/recipes',
   gamedeal: '/game-deals',
   gamenews: '/all-game-news',
   gamewallpaper: '/game-wallpapers'
}

const ContentCount = props => {
   const contentCounts = useSelector(state => state.dashboard.content);
   const contentErrors = useSelector(state => state.dashboard.contentErrors);
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(actions.dashboard.fetchContentCounts());
   }, [dispatch]);

   return (
      <>
         <Divider horizontal className={classes.ContentDivader}>Content</Divider>
         <Grid columns={5} className={classes.ContentGrid}>

            {contentCounts.map(item => {
               return (
                  <Grid.Column key={item.content_type}>
                     <CountCard title={item.content_type} count={item.count} link={contentLinks[item.content_type]} />
                  </Grid.Column>
               );
            })}

         </Grid>
      </>
   );
}

export default ContentCount;