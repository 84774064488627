import React, { useRef } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';

import GameReviewContent from './GameReviewContent';

import classes from '../Content.module.css';
{/* 
* On unity game game_file is required, html5_zip, game_url and embed_config aree not allowed
* On external games game_url is required but embed_config, html5_zip and game_file not allowed
* On iframe games embed_config is required but game_url, html5_zip and game_file not allowed
* On html5 game html5_zip is required but embed_config, game_file not and game_url not allowed 
* */}

const technologyOptions = [
   ['UNITY', 'unity'], ['HTML 5', 'html5'], ['IFRAME', 'iframe'], ['EXTERNAL LINK', 'external']
].map(item => ({ text: item[0], value: item[1] }));

const GameTechnology = props => {
   const { technology, onChange, game_file, html5_zip, game_url, guide_urls, review_urls, embed_config, disabled, validationErrors } = props;
   const gameFileInput = useRef();
   const html5FileInput = useRef();
   const gameFile = useRef();
   const html5File = useRef();

   const guideUrls = guide_urls ?? [];
   const guideUrlsOptions = guideUrls.map(word => ({ text: word, value: word }));

   const clickHandler = (fileInput) => {
      fileInput.current.click();
   }

   const removeHandler = (name, ref, input) => {
      onChange(name, ref.current);
      ref.current = undefined;
      input.current.value = '';

   }

   const technologyChangeHandler = (event, data) => {
      onChange(data.name, data.value);
   }

   const fileChangeHandler = (name, value, ref) => {
      if (ref.current === undefined) {
         ref.current = props[name];
      }
      onChange(name, value);
   }

   let techDetails;
   switch (technology) {
      case 'unity':
         techDetails = (
            <>
               <Button className={`${classes.ChooseTech} ${validationErrors.game_file ? classes.Error : ''}`} onClick={() => clickHandler(gameFileInput)} disabled={disabled} >Choose unity game file</Button>
               <Button className={classes.RemoveTech} onClick={() => removeHandler('game_file', gameFile, gameFileInput)} disabled={disabled || gameFile.current === undefined} >Reset file change</Button>
               {validationErrors.game_file ? (
                  <Form.Field>
                     <div class="ui pointing above prompt label" role="alert" aria-atomic="true">{validationErrors.game_file}</div>
                  </Form.Field>
               ) : null}
               <input hidden key="game_file" name="game_file" type="file" ref={gameFileInput} onChange={() => fileChangeHandler("game_file", gameFileInput.current.files[0], gameFile)} />
            </>
         );
         break;
      case 'html5':
         techDetails = (
            <>
               <Button className={`${classes.ChooseTech} ${validationErrors.html5_zip ? classes.Error : ''}`} onClick={() => clickHandler(html5FileInput)} disabled={disabled} >Choose HTML5 zip file</Button>
               <Button className={classes.RemoveTech} onClick={() => removeHandler('html5_zip', html5File, html5FileInput)} disabled={disabled || html5File.current === undefined} >Reset file change</Button>
               {validationErrors.html5_zip ? (
                  <Form.Field>
                     <div class="ui pointing above prompt label" role="alert" aria-atomic="true">{validationErrors.html5_zip}</div>
                  </Form.Field>
               ) : null}
               <input hidden key="html5_zip" name="html5_zip" type="file" ref={html5FileInput} onChange={() => fileChangeHandler("html5_zip", html5FileInput.current.files[0], html5File)} />
            </>
         );
         break;
      case 'iframe':
         techDetails = (
            <Form.TextArea label="IFRAME tag:" name="embed_config" type="text" value={game_url} onChange={(data, { value }) => onChange('embed_config', value)} value={embed_config || ''} error={validationErrors.embed_config || null} disabled={disabled} />
         );
         break;
      case 'external':
         techDetails = (
            <>
               <Form.Input label="Game URL:" name="game_url" type="text" value={game_url} onChange={(data, { value }) => onChange('game_url', value)} value={game_url || ''} error={validationErrors.game_url || null} disabled={disabled} />
               <Form.Dropdown className={classes.Keywords} label="Guide URLs (Optional):" name="guide_urls" allowAdditions search options={guideUrlsOptions} multiple selection value={guide_urls || []} onChange={(data, { value }) => onChange('guide_urls', value)} error={validationErrors.guide_urls || null} disabled={disabled} />
               <GameReviewContent content={review_urls} onChange={onChange} validationErrors={validationErrors} disabled={disabled}/>
            </>
         );
         break;
   }

   return (
      <>
         <Form.Select label="Technology:" name="technology" options={technologyOptions} value={technology} onChange={technologyChangeHandler} error={validationErrors.technology || null} disabled={disabled} />
         {techDetails}
      </>
   );
}

export default GameTechnology;