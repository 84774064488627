import { FETCH_LEGALS, 
         FETCH_LEGALS_SUCCESS, 
         FETCH_LEGALS_FAILURE,
         FETCH_LEGAL, 
         FETCH_LEGAL_SUCCESS, 
         FETCH_LEGAL_FAILURE,  
         RESET_LEGAL_STATE, 
         ADD_NEW_LEGAL, 
         ADD_NEW_LEGAL_SUCCESS, 
         ADD_NEW_LEGAL_FAILURE,
         UPDATE_LEGAL,
         UPDATE_LEGAL_SUCCESS,
         UPDATE_LEGAL_FAILURE,
      } from '../actions/actionTypes';

export const fetchLegals = () => {
   return {
      type: FETCH_LEGALS,
   }
}

export const fetchLegalsSuccess = legals => {
   return {
      type: FETCH_LEGALS_SUCCESS,
      legals,
   }
}

export const fetchLegalsFailure = errors => {
   return {
      type: FETCH_LEGALS_FAILURE,
      errors,
   }
}

export const fetchLegal = id => {
   return {
      type: FETCH_LEGAL,
      id,
   }
}

export const fetchLegalSuccess = legal => {
   return {
      type: FETCH_LEGAL_SUCCESS,
      legal,
   }
}

export const fetchLegalFailure = errors => {
   return {
      type: FETCH_LEGAL_FAILURE,
      errors,
   }
}

export const addNewLegal = legal => {
   return {
      type: ADD_NEW_LEGAL,
      legal,
   }
}

export const addNewLegalSuccess = legal => {
   return {
      type: ADD_NEW_LEGAL_SUCCESS,
      legal,
   }
}

export const addNewLegalFailure = errors => {
   return {
      type: ADD_NEW_LEGAL_FAILURE,
      errors,
   }
}

export const updateLegal = legal => {
   return {
      type: UPDATE_LEGAL,
      legal,
   }
}

export const updateLegalSuccess = legal => {
   return {
      type: UPDATE_LEGAL_SUCCESS,
      legal,
   }
}

export const updateLegalFailure = errors => {
   return {
      type: UPDATE_LEGAL_FAILURE,
      errors,
   }
}

export const resetLegalState = () => {
   return {
      type: RESET_LEGAL_STATE,
   }
}