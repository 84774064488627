import {
   FETCH_LEGALS_SUCCESS,
   FETCH_LEGALS_FAILURE,
   RESET_LEGAL_STATE,
   FETCH_LEGAL_SUCCESS,
   FETCH_LEGAL_FAILURE,
   ADD_NEW_LEGAL_SUCCESS,
   ADD_NEW_LEGAL_FAILURE,
   UPDATE_LEGAL_SUCCESS,
   UPDATE_LEGAL_FAILURE,
} from '../actions/actionTypes';

import LegalTemplate from '../../models/LegalTemplate';
import { cloneLegal } from '../../utilities/common';

const emptyLegalData = { id: undefined, type: '', lebel: '', title: '', content: '', content_types: [], projects: [], comment: '', author: '', version: '', creation_datetime: '' };
const initialState = {
   legals: [],
   legalsLoaded: false,
   legalLoaded: false,
   legal: cloneLegal(emptyLegalData),
   messages: '',
   errors: '',
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case FETCH_LEGALS_SUCCESS:
         return {
            ...state,
            legals: action.legals,
            legalsLoaded: true,
         }
      case FETCH_LEGALS_FAILURE:
         return {
            ...state,
            legals: [],
            messages: '',
            errors: action.errors
         }
      case FETCH_LEGAL_SUCCESS:
         return {
            ...state,
            legal: action.legal,
            legalLoaded: true,
         }
      case FETCH_LEGAL_FAILURE:
         return {
            ...state,
            messages: '',
            errors: action.errors,
            legalLoaded: false,
         }
      case ADD_NEW_LEGAL_SUCCESS:
         return {
            ...state,
            legal: action.legal,

         }
      case ADD_NEW_LEGAL_FAILURE:
         return {
            ...state,
            errors: action.errors,
         }
      case UPDATE_LEGAL_SUCCESS:
         return {
            ...state,
            legal: action.legal,

         }
      case UPDATE_LEGAL_FAILURE:
         return {
            ...state,
            errors: action.errors,
         }
      case RESET_LEGAL_STATE:
         return initialState;
      default:
         return state
   }
}

export default reducer;