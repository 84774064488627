import { call, put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { processResponse } from '../../utilities/fetchUtils';
import { cloneLegal } from '../../utilities/common';


export function* fetchLegalsSaga() {
   try {
      const response = yield call(fetch, '/legal-template-service/get-current-templates', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         }, 
      });
      const legalsData = yield call(processResponse, response);
      //const legals = yield call(legalsData.data);
      yield put(actions.legals.fetchLegalsSuccess(legalsData.data));
   } catch (e) {
      console.log(e);
      yield put(actions.legals.fetchLegalsFailure(e.messages || e.message));
   }
}

export function* fetchLegalSaga(action) {
   try {
      const response = yield call(fetch, '/legal-template-service/get-template', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            id: action.id,
         })
      });
      const legalData = yield call(processResponse, response);
      const legal = yield call(cloneLegal, legalData.data);
      yield put(actions.legals.fetchLegalSuccess(legal));
   } catch (e) {
      console.log(e);
      yield put(actions.legals.fetchLegalFailure(e.messages || e.message));
   }
}

export function* addNewLegalSaga(action) {
   const { title, content, type, level, content_types, projects, comment } = action.legal;
   try {
      const response = yield call(fetch, '/legal-template-service/create-template', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            title,
            content,
            type,
            level,
            content_types,
            projects,
            comment,
         }),
      });
      const legalsData =  yield call(processResponse, response);
      //const legal = yield call(legalsData.data);
      yield put(actions.legals.addNewLegalSuccess(legalsData.data));
      yield put(actions.app.redirectTo('/legal-docs'));
   } catch (e) {
      console.log(e);
      yield put(actions.legals.addNewLegalFailure(e.messages || e.message));
   }
}

export function* updateLegalSaga(action) {
   const { id, title, content, type, level, content_types, projects, comment } = action.legal;
   try {
      const response = yield call(fetch, '/legal-template-service/update-template', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            id,
            title,
            content,
            type,
            level,
            content_types,
            projects,
            comment,
         }),
      });
      const legalData = yield call(processResponse, response);
      yield put(actions.legals.updateLegalSuccess(legalData.data));
      yield put(actions.app.redirectTo('/legal-docs'));
   } catch (e) {
      console.log(e);
      yield put(actions.legals.updateLegalFailure(e.messages || e.message));
   }
}