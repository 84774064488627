import { FETCH_TAGS, FETCH_TAGS_SUCCESS, FETCH_TAGS_FAILURE, FETCH_TAG, FETCH_TAG_SUCCESS, FETCH_TAG_FAILURE, ADD_NEW_TAG, ADD_NEW_TAG_SUCCESS, ADD_NEW_TAG_FAILURE, UPDATE_TAG, UPDATE_TAG_SUCCESS, UPDATE_TAG_FAILURE, RESET_TAGS_STATE } from '../actions/actionTypes';

const initialState = {
   tagsLoaded: false,
   tags: [],
   tagLoaded: false,
   tag: null,
   errors: '',
}

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case FETCH_TAGS:
         return {
            ...state,
            tagsLoaded: false,
            tags: [],
            errors: '',
         }
      case FETCH_TAGS_SUCCESS:
         return {
            ...state,
            tagsLoaded: true,
            tags: action.tags,
         }
      case FETCH_TAGS_FAILURE:
         return {
            ...state,
            errors: action.errors,
         }
      case ADD_NEW_TAG:
         return {
            ...state,
            tag: null,
            errors: '',
         }
      case ADD_NEW_TAG_SUCCESS:
         return {
            ...state,
            tagsLoaded: false,
            tags: [],
            tag: action.tag,
         }
      case ADD_NEW_TAG_FAILURE:
         return {
            ...state,
            errors: action.errors,
         }
      case FETCH_TAG:
         return {
            ...state,
            tagLoaded: false,
            tag: null,
            errors: ''
         }
      case FETCH_TAG_SUCCESS:
         return {
            ...state,
            tagLoaded: true,
            tag: action.tag,
         }
      case FETCH_TAG_FAILURE:
         return {
            ...state,
            errors: action.errors,
         }
      case UPDATE_TAG:
         return {
            ...state,
            errors: '',
         }
      case UPDATE_TAG_SUCCESS:
         return {
            ...state,
            tagLoaded: true,
            tag: action.tag,
         }
      case UPDATE_TAG_FAILURE:
         return {
            ...state,
            errors: action.errors,
         }
      case RESET_TAGS_STATE:
         return initialState;
      default:
         return state;
   }
}

export default reducer;