import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
const NotAuthorized = props => {
   
   const style = {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
   }

   return (
      <Header as="h2" icon style={style}>
         <Icon name="hand paper" />
         Not Authorized
         <Header.Subheader>
            Please check your user permissions!
         </Header.Subheader>
      </Header>
   );
}

export default NotAuthorized;