import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { checkPermissions } from '../../utilities/common';
import { getUserPermissions } from '../auth/authSelectors';
import NotAuthorized from './NotAuthorized';

const AuthorizedRoute = ({ requiredPermissions, path, component, children }) => {
   const userPermissions = useSelector(getUserPermissions);

   if (!checkPermissions(requiredPermissions, userPermissions)) {
      component = NotAuthorized;
      children = children && NotAuthorized;
   }

   return (
      <Route path={path} component={children ? null : component}>
         {children && children}
      </Route>
   );
}

export default AuthorizedRoute;