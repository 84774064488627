import React, { useState } from 'react';
import { Form, Segment, Grid } from 'semantic-ui-react';

import Instructions from './Instructions';
import FileSelect from '../../common/FileSelect';
import Image from '../../common/Image';
import classes from '../Content.module.css';

const RecipeOptions = ({ recipe, onChange, errors, validationErrors, loading, mode }) => {
   const [imageSrc, setImageSource] = useState();
   const [previewActive, setPreviewActive] = useState();
   const previewHandler = (name, value) => {
      setPreviewActive(name);
      if (value instanceof File) {
         const reader = new FileReader();
         reader.onload = event => setImageSource(event.target.result);
         reader.readAsDataURL(value);
      } else {
         if (value) {
            setImageSource(value);

         } else {
            setImageSource('')
         }
      }
   }

   const mediaChangeHandler = (name, value) => {
      setPreviewActive(name);
      onChange(name, value);
   }

   const disabled = mode === 'view';
   return (
      <Form loading={loading} className={classes.Form}>
         <Instructions name="ingredients" label="Ingredients" items={recipe.ingredients} onChange={onChange} error={validationErrors.ingredients} disabled={disabled} />
         <Instructions name="instructions" label="Instructions" items={recipe.instructions} onChange={onChange} error={validationErrors.instructions} disabled={disabled} />
         <Form.Field style={{marginTop: '1rem'}}>
            <label>Media:</label>
            <Segment as='fieldset' className={classes.MediaSegment}>
               <Grid columns={2}>
                  <Grid.Column>
                     <FileSelect label="Image 1 (110x110):" name="image_1" previewActive={previewActive === 'image_1'} value={recipe.image_1} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_1 || null} disabled={disabled} />
                     <FileSelect label="Image 2 (280x245):" name="image_2" previewActive={previewActive === 'image_2'} value={recipe.image_2} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_2 || null} disabled={disabled} />
                     <FileSelect label="Image 3 (1000x335):" name="image_3" previewActive={previewActive === 'image_3'} value={recipe.image_3} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_3 || null} disabled={disabled} />
                     <FileSelect label="Image 4 (1200x420):" name="image_4" previewActive={previewActive === 'image_4'} value={recipe.image_4} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_4 || null} disabled={disabled} />
                  </Grid.Column>
                  <Grid.Column textAlign="center" verticalAlign="middle">
                     <Image src={imageSrc} className={classes.PreviewImage} />
                  </Grid.Column>
               </Grid>
            </Segment>
         </Form.Field>
      </Form>
   );
}

export default RecipeOptions;