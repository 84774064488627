import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import AuthorizedRoute from '../common/AuthorizedRoute';
import ContentTable from './ContentTable';
import GameForm from './games/GameForm';
import QuizForm from './quizzes/QuizForm';
import CouponForm from './coupons/CouponForm';
import RecipeForm from './recipes/RecipeForm';
import ToolForm from './tools/ToolForm';
import GameDealForm from './game-deals/GameDealForm';
import GameNewsForm from './game-news/GameNewsForm';
import GameWallpaperForm from './game-wallpaper/GameWallpaperForm';

export const contentConfig = {
   '/games': {
      Form: GameForm,
      addNewText: 'Add new game'
   },
   '/quizzes': {
      Form: QuizForm,
      addNewText: 'Add new quiz'
   },
   '/coupons': {
      Form: CouponForm,
      addNewText: 'Add new coupon'
   },
   '/recipes': {
      Form: RecipeForm,
      addNewText: 'Add new recipe'
   },
   '/tools': {
      Form: ToolForm,
      addNewText: 'Add new tool'
   },
   '/game-deals': {
      Form: GameDealForm,
      addNewText: 'Add new game deal'
   },
   '/all-game-news': {
      Form: GameNewsForm,
      addNewText: 'Add new game news'
   },
   '/game-wallpapers': {
    Form: GameWallpaperForm,
    addNewText: 'Add new game wallpapers'
 },
};

const Content = ({ config = contentConfig }) => {
   const { path, url } = useRouteMatch();
   const { Form } = config[path];

   return (
      <Switch>
         <AuthorizedRoute path={`${path}/new`} requiredPermissions={{ any: ['create_content'] }} >
            <Form mode="new" />
         </AuthorizedRoute>
         <AuthorizedRoute path={`${path}/edit/:id`} requiredPermissions={{ any: ['update_content'] }} >
            <Form mode='edit' />
         </AuthorizedRoute>
         <AuthorizedRoute path={`${path}/view/:id`} requiredPermissions={{ any: ['access_all_contents'] }} >
            <Form mode='view' />
         </AuthorizedRoute>
         <AuthorizedRoute path={path} requiredPermissions={{ any: ['access_all_contents'] }} >
            <ContentTable config={contentConfig} cType={path} path={path} addNewText={contentConfig[path].addNewText} />
         </AuthorizedRoute>
      </Switch>
   );
}

export default Content;