import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ProjectsTable from './ProjectsTable';
import NewProject from './NewProjectForm';
import EditProject from './EditProjectForm';

const Projects = props => {

   const {path, url} = useRouteMatch();

   return (
      <Switch>
         <Route path={`${path}/new`}>
            <NewProject />
         </Route>
         <Route path={`${path}/view/:id`}>
            <EditProject mode="view"/>
         </Route>
         <Route path={`${path}/edit/:id`}>
            <EditProject mode="edit"/>
         </Route>
         <Route path={`${path}/clone/:id`}>
            <EditProject mode="clone"/>
         </Route>
         <Route path={`${path}`}>
            <ProjectsTable path={path} />
         </Route>
      </Switch>
   );
}

export default Projects;