import * as acTypes from '../actions/actionTypes';

const initialState = {
   gamesLoaded: false,
   games: [],
   gameLoaded: false,
   game: undefined,
   gameErrors: '',
   gamedealsLoaded: false,
   gamedeals: [],
   gamedealLoaded: false,
   gamedeal: undefined,
   gamedealErrors: '',
   allgamenewsLoaded: false,
   allgamenews: [],
   gamenewsLoaded: false,
   gamenews: undefined,
   gamenewsErrors: '',
   gamewallpapersLoaded: false,
   gamewallpapers: [],
   gamewallpaperLoaded: false,
   gamewallpaper: undefined,
   gamewallpaperErrors: '',
   quizzesLoaded: false,
   quizzes: [],
   quizLoaded: false,
   quiz: undefined,
   quizErrors: '',
   couponsLoaded: false,
   coupons: [],
   couponLoaded: false,
   coupon: undefined,
   couponErrors: '',
   recipesLoaded: false,
   recipes: [],
   recipeLoaded: false,
   recipe: undefined,
   recipeErrors: '',
   toolsLoaded: false,
   tools: [],
   appsLoaded: false,
   apps: undefined,
   appsErrors: '',
   requestPending: false,
}

const reducer = (state = initialState, action) => {
   const { type, cType, content, errors } = action;
   
   switch (type) {
      case acTypes.FETCH_CONTENTS:
         return {
            ...state,
            [`${cType}Loaded`]: false,
            [`${cType}Errors`]: undefined,
            [cType]: [],

         }
      case acTypes.FETCH_CONTENTS_SUCCESS:
         return {
            ...state,
            [`${cType}Loaded`]: true,
            [cType]: content,
         }
      case acTypes.FETCH_CONTENTS_FAILURE:
         return {
            ...state,
            [`${cType}Errors`]: errors,
         }
      case acTypes.FETCH_CONTENT:
         return {
            ...state,
            [`${cType}Loaded`]: false,
            [`${cType}Errors`]: '',
            // [cType]: [],
         }
      case acTypes.FETCH_CONTENT_SUCCESS:
         return {
            ...state,
            [`${cType}Loaded`]: true,
            [cType]: content,
         }
      case acTypes.FETCH_CONTENT_FAILURE:
         return {
            ...state,
            [`${cType}Errors`]: errors,
         }
      case acTypes.UPDATE_CONTENT:
         return {
            ...state,
            [`${cType}Errors`]: '',
            requestPending: true,
         }
      case acTypes.UPDATE_CONTENT_SUCCESS:
         return {
            ...state,
            [cType]: content,
            requestPending: false,
         }
      case acTypes.UPDATE_CONTENT_FAILURE:
         return {
            ...state,
            [`${cType}Errors`]: errors,
            requestPending: false,
         }
      case acTypes.ADD_NEW_CONTENT:
         return {
            ...state,
            [`${cType}Errors`]: '',
            requestPending: true,
         }
      case acTypes.ADD_NEW_CONTENT_SUCCESS:
         return {
            ...state,
            [cType]: content,
            requestPending: false,
         }
      case acTypes.ADD_NEW_CONTENT_FAILURE:
         return {
            ...state,
            [`${cType}Errors`]: errors,
            requestPending: false,
         }
      case acTypes.RESET_CONTENTS_STATE:
         return initialState;
      default:
         return state;
   }
}

export default reducer;