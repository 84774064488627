import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ContentForm from '../ContentForm';
import ToolOptions from './ToolOptions';
import { cloneTool } from '../../../utilities/common';
import useContent from '../../../hooks/useContent';
import * as actions from '../../../store/actions/index';

const ToolForm = ({ mode }) => {

   const emptyTool = cloneTool({
      width: '',
      height: '',
      render_type: 'Frame',
      link: '',
      image_1: '',
      image_2: '',
      id: '',
      content_id: '',
      type: 'apps',
      title: '',
      description: '',
      keywords: '',
      categories: [],
      tags: {},
      projects: [],
      status: 'ACTIVE',
   });

   const toolId = useParams().id;
   const {
      projectsLoaded,
      projects,
      tagsLoaded,
      tags,
      categoriesLoaded,
      categories,
      backendErrors,
      contentLoaded,
      selectedContent,
      requestPending,
      dispatch
   } = useContent('apps');

   let [validationErrors, setValidationErrors] = useState(undefined);
   const [selectedProjectId, setSelectedProjectId] = useState();
   const [tool, setTool] = useState(emptyTool);
   // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
   validationErrors = validationErrors || backendErrors;

   useEffect(() => {
      if (!projectsLoaded) dispatch(actions.projects.fetchProjects());
      if (!tagsLoaded) dispatch(actions.tags.fetchTags());
      if (!categoriesLoaded) dispatch(actions.categories.fetchCategories());
      if (mode !== 'new' && !contentLoaded) dispatch(actions.content.fetchContent(toolId, 'apps'));
      return () => dispatch(actions.content.resetContentState());
   }, []);

   useEffect(() => {
      if (selectedContent && mode !== 'new')
         setTool(selectedContent);
   }, [selectedContent]);

   const updateTool = (name, value) => {

      setTool((tool) => {
         const updatedTool = cloneTool(tool);
         if (name === 'tags') {
            updatedTool.tags = { ...updatedTool.tags, [selectedProjectId]: value }
         } else if (name === 'keywords') {
            updatedTool.keywords = value.join(',');
         } else if (name === 'projects') {
            const updatedTags = {};
            value.forEach(projectId => updatedTags[projectId] = updatedTool.tags[projectId]);
            updatedTool['projects'] = value;
            updatedTool['tags'] = updatedTags;
         } else {
            updatedTool[name] = value;
         }
         return updatedTool;
      });
   }

   const selectedProjectChangeHandler = projectId => {
      setSelectedProjectId(projectId);
   }

   const submitHandler = () => {
      const errors = tool.isValid();
      setValidationErrors(errors);
      if (errors) return;
      switch (mode) {
         case 'new':
            dispatch(actions.content.addNewContent(tool, 'apps'));
            break;
         case 'edit':
            dispatch(actions.content.updateContent(tool, 'apps'));
            break;
      }
   }

   const loading = !projectsLoaded || !tagsLoaded || !categoriesLoaded || (mode !== 'new' && !contentLoaded) || requestPending;
   return (
      <ContentForm
         content={tool}
         contentType="Tool"
         validationErrors={validationErrors}
         errors={backendErrors.global}
         onChange={updateTool}
         onSubmit={submitHandler}
         projects={projects}
         selectedProjectId={selectedProjectId}
         projectSelectedHandler={selectedProjectChangeHandler}
         tags={tags}
         categories={categories}
         loading={loading}
         mode={mode}
      >
         {(content, onChange, errors, validationErrors, loading, mode) => {
            return (
               <ToolOptions
                  tool={content}
                  onChange={onChange}
                  errors={errors}
                  validationErrors={validationErrors}
                  loading={loading}
                  mode={mode}
               />
            );
         }}
      </ContentForm>
   );
}

export default ToolForm;