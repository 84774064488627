import { call, put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { processResponse } from '../../utilities/fetchUtils';

export function* fetchContentCounts(action) {
   try {
      const response = yield call(fetch, '/dashboard-service/get-content-counts', {
         method: 'POST',
         headers: {
            'accept': '*/*',
         }
      });
      const contentData = yield call(processResponse, response);
      yield put(actions.dashboard.fetchContentCountsSuccess(contentData.data));
   } catch (e) {
      console.log(e);
      yield put(actions.dashboard.fetchContentCountsFailure (e.messages || e.message));
   }
}

export function* fetchProjectCounts(action) {
   try {
      const response = yield call(fetch, '/dashboard-service/get-project-counts', {
         method: 'POST',
         headers: {
            'accept': '*/*',
         }
      });
      const projectData = yield call(processResponse, response);
      yield put(actions.dashboard.fetchProjectCountsSuccess(projectData.data));
   } catch (e) {
      console.log(e);
      yield put(actions.dashboard.fetchProjectCountsFailure (e.messages || e.message));
   }
}