import { validate } from 'validate.js';

class AllowedIp {
   id;
   ip;
   description;
   username;
   creation_datetime;
   update_datetime;
   scenario = scenarios.DEFAULT;
   labels = { 
		id : 'ID',
		ip : 'IP',
		description : 'Description',
		username : 'User',
		creation_datetime : 'Created',
		update_datetime : 'Edited',
	};
   
   constructor (id, ip, description, username, creation_datetime, update_datetime) {
      this.id = id;
      this.ip = ip;
      this.description = description;
      this.username = username;
      this.creation_datetime = creation_datetime;
      this.update_datetime = update_datetime;
   }

   isValid = () => {
      const defaultErr = validate(this, constraints[AllowedIp.scenarios.DEFAULT]);
      if (this.scenario === AllowedIp.scenarios.DEFAULT) return defaultErr;
      const err = validate(this, constraints[this.scenario]);
      if(!defaultErr && !err) return undefined;

      let allErrors = {...defaultErr};
      if (err) {
         Object.keys(err).forEach(key => {
            if(allErrors[key]) allErrors[key] = [...allErrors[key], ...err[key]];
            else allErrors[key] = err[key]; 
         });
      }

      return allErrors;
   }
}

const scenarios = {
   DEFAULT: "DEFAULT",
}

const constraints = {
    "DEFAULT": {
        "ip": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be string at most 18 symbols",
                "maximum": 18
            }
        },
        "description": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be string at most 45 symbols",
                "maximum": 45
            }
        },
        "username": {
            "length": {
                "message": "should be string at most 45 symbols",
                "maximum": 45
            }
        }
    }
}

AllowedIp.scenarios = scenarios;
AllowedIp.constraints = constraints;

export default AllowedIp;
