import React from 'react';
import { Form } from 'semantic-ui-react';

export const legalLevels = [
   'global',
   'content_type',
   'project',
].map(content => ({ text: content.toUpperCase(), value: content.toUpperCase() }));

const LegalLevelSelect = ({name='level', value='global', label='Level:', error, disabled, onChange}) => {
   return (
      <Form.Select error={error} label={label} name={name} value={value} options={legalLevels} disabled={disabled} onChange={(e, {name, value}) => onChange(name, value)} />
   );
}

export default LegalLevelSelect;