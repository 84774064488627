import React from 'react';
import {Link} from 'react-router-dom';
import { Container, Button, Label, Icon } from 'semantic-ui-react';
import withPermissions from '../../hoc/withPermissions';

import classes from './AddNewButton.module.css';

export const AddNewButton = ({ text, to }) => {
   return (
      <Container fluid className="add-new" textAlign="right">
         <Button as={Link} to={to} icon
            id='my-ui-button'
            className={classes.AddNewButton}
            labelPosition='left'>
            <Label>{text}</Label>
            <Icon name='plus' color='green' size='large' />
         </Button>
      </Container>
   );
}

export default withPermissions(AddNewButton);