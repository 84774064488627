import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { cloneIp } from '../../utilities/common';
import Ip from '../../models/AllowedIp';
import IpForm from './IpForm';
import * as actions from '../../store/actions/index';
import AllowedIp from '../../models/AllowedIp';

const NewAllowedIpForm = props => { 
    const newIp = new AllowedIp(undefined, '', '', '', '', '');
    newIp.scenario = AllowedIp.scenarios.CREATE;
    const [ip, setIp] = useState(newIp);
    const backendErrors = useSelector(state => state.ips.errors);
    let [validationErrors, setValidationErrors] = useState(undefined);
    // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
    validationErrors = validationErrors || backendErrors;
    const dispatch = useDispatch();

   useEffect(() => {
      return () => dispatch(actions.ips.resetIpState());
   }, []);

   const submitHandler = () => {
       const errors = ip.isValid();
       setValidationErrors(errors);
       if (errors) return;
       dispatch(actions.ips.addNewIp(ip));
   }

   const updateIp = (name, value) => {
      const updatedIp = cloneIp(ip);
      updatedIp[name.toLowerCase()] = value;
      updatedIp.scenario = AllowedIp.scenarios.CREATE;
      setIp(updatedIp);
   }

   const handleEditorChange = (content, editor) => {
      const updatedIp = cloneIp(ip);
      updatedIp.content = content;
      updatedIp.scenario = AllowedIp.scenarios.CREATE;
      setIp(updatedIp);
   }

   return (
      <>
         <IpForm formTitle="Add new IP" ip_add={ip} validationErrors={validationErrors} errors={backendErrors} onSubmit={submitHandler} onChange={updateIp} onEditorChange={handleEditorChange} />
      </>
   );
}

export default NewAllowedIpForm;