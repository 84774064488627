import React, { useState, useRef, useEffect } from 'react';
import { Table, Icon, Input, Segment, Container, Pagination, Button } from 'semantic-ui-react';

import { sortBy, filter, formatFagcTableCellContent } from '../../utilities/common';
import { NavLink } from 'react-router-dom';
import AccessControl from '../../hoc/AccessControl';
import classes from './Table.module.css';

const FagcTable = ({ data, columns, actions, loading, pagination, renderer = {}, className, onClickActions }) => {

   const { active, pageSize = 1 } = pagination || {}
   const [sortColumn, setSortColumn] = useState();
   const [searchColumn, setSearchColumn] = useState();
   const [sortDirection, setSortsortDirection] = useState('ascending');
   const [filters, setFilters] = useState({});
   const [activePage, setActivePage] = useState(active);
   const searchInputRef = useRef(false);
   let gridData = filter(data, filters);
   gridData = sortBy(gridData, sortColumn, sortDirection);

   let totalPages;
   if (pagination && gridData.length > pageSize) {
      totalPages = Math.ceil(gridData.length / pageSize);
      const begin = (activePage - 1) * pageSize;
      const end = begin + pageSize;
      gridData = gridData.slice(begin, end);
   }

   let paginationCmp = null;
   if (totalPages) {
      paginationCmp = <Pagination activePage={activePage} totalPages={totalPages} siblingRange={3} onPageChange={(event, { activePage }) => pageChangeHandler(activePage)} />
   }

   useEffect(() => {
      if (searchInputRef.current) searchInputRef.current.focus();
   }, [searchColumn]);

   useEffect(() => {
      setFilters({});
   }, [loading, setFilters]);

   const sortHandler = column => {
      if (sortColumn !== column) {
         setSortsortDirection('ascending');
         setSortColumn(column);
         return;
      } else {
         setSortsortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
         setSortColumn(column);
      }
   }

   const searchClikcHandler = column => {
      setSearchColumn(column);
      // this handles click on close incon inside search folder
      if (filters[column]) {
         let newFilters = { ...filters }
         delete newFilters[column];
         setFilters(newFilters);
         setSearchColumn();
      }
   }

   const searchHandler = (event, { value }) => {
      let newFilters = { ...filters };
      newFilters[searchColumn] = value;
      setFilters(newFilters);
   }

   const searchInputBlurHandler = col => {
      // if user clicks on other search icon then don't change searchColumn state
      setTimeout(() => {
         if (col === searchColumn) {
            setSearchColumn(sc => {
               if (sc === col) {
                  return null;
               } else {
                  return sc;
               }
            });
         }
      }, 100);
   }

   const pageChangeHandler = (page) => {
      setActivePage(page);
   }

   const headers = Object.keys(columns).map(key => {
      let headerCell;
      if (key in renderer && 'header' in renderer[key]) {
         headerCell = renderer[key]['header'](key, columns[key])
      } else {
         headerCell = (
            <Table.HeaderCell
               key={key}
               className={key}
               singleLine
               sorted={sortColumn === key ? sortDirection : null}
               onClick={() => sortHandler(key)}
            >
               {<Icon color="grey" name={filters[key] ? 'close' : 'search'} onClick={(event) => {
                  event.stopPropagation();
                  searchClikcHandler(key);
               }} />}

               {searchColumn === key ? <Input style={{ maxWidth: "65%" }} transparent ref={searchInputRef} onChange={searchHandler} onBlur={() => searchInputBlurHandler(key)} /> : columns[key]}

            </Table.HeaderCell>
         )
      }
      return headerCell;
   });

   // put action buttons in first column
   if (actions) {
      headers.unshift(<Table.HeaderCell className="actions" key="actions" >Actions</Table.HeaderCell>);
   }

   // put action buttons in first column
   if (onClickActions) {
      headers.unshift(<Table.HeaderCell key="click-actions" ></Table.HeaderCell>);
   }

   const rows = gridData.map((rowData, index) => {
      const cels = [];

      if (actions) {
         const actionIcons = Object.keys(actions).map(key => {
            return (
               <AccessControl key={key} requiredPermissions={actions[key].permissions}>
                  <NavLink to={actions[key].path(rowData)}>
                     <Icon name={key} />
                  </NavLink>
               </AccessControl>
            );
         });
         cels.push(
            <Table.Cell key="actions" textAlign="center">
               {actionIcons}
            </Table.Cell>
         );
      }

      if (onClickActions) {
         const actionIcons = Object.keys(onClickActions).map(key => {
            return (
               <AccessControl key={key} requiredPermissions={onClickActions[key].permissions}>
                  <Button className={classes.TableCellButton} onClick={() => onClickActions[key].onClick.onClickHandler(rowData)}>
                     <Icon className={classes.TableCellIcon} name={key} />
                  </Button>
               </AccessControl>
            );
         });
         cels.push(
            <Table.Cell className={classes.ClickActions} key="actions" textAlign="center">
               {actionIcons}
            </Table.Cell>
         );
      }

      for (const column in columns) {
         let positive = false;
         let negative = false;
         let warning = false;
         if (column === 'status') {
            rowData[column] === 'ACTIVE' ? positive = true : (rowData[column] === 'INACTIVE' ? negative = true : warning = true)
         }
         const cell = (
            <Table.Cell positive={positive} negative={negative} warning={warning} singleLine key={column}
               title={[rowData[column]].join(' ')}
            >
               {formatFagcTableCellContent(rowData[column])}
            </Table.Cell>
         )
         if (column in renderer && 'body' in renderer[column]) {
            cels.push(renderer[column]['body'](rowData, column, cell))
         } else {
            cels.push(cell);
         }
      }
      return (
         <Table.Row key={index}>{cels}</Table.Row>
      );
   });

   return (
      <>
         <Segment loading={loading} basic style={{ padding: 0, minHeight: '300px' }}>
            <Table fixed sortable celled className={className}>
               <Table.Header>
                  <Table.Row>
                     {headers}
                  </Table.Row>
               </Table.Header>
               <Table.Body>
                  {rows}
               </Table.Body>
            </Table>
         </Segment>
         <Container textAlign='center'>
            {paginationCmp}
         </Container>
      </>
   );
}

export default FagcTable;