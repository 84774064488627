import React from 'react';
import { Container, Divider, Tab } from 'semantic-ui-react';

import GeneralOptions from './GeneralOptions';
import BackButton from '../common/BackButton';
import SubmitButton from '../common/SubmitButton';
import GlobalFormErrors from '../common/GlobalFormErrors';
import { formatValidationErrors } from '../../utilities/common';
import classes from './Content.module.css';

const ContentForm = ({ content, contentType, projects, tags, categories, selectedProjectId, errors, validationErrors, mode, onChange, projectSelectedHandler, loading, onSubmit, children }) => {
    
   let title;
   switch (mode) {
      case 'view':
         title = `VIEW ${contentType.toUpperCase()}`;
         break;
      case 'edit':
         title = `EDIT ${contentType.toUpperCase()}`;
         break;
      case 'new':
         title = `NEW ${contentType.toUpperCase()}`;
         break;
   }
   validationErrors = formatValidationErrors(validationErrors || {});
   const panes = [
      {
         menuItem: 'General Options',
         render: () => <Tab.Pane className={classes.Tab}><GeneralOptions
            content={content}
            onChange={onChange}
            onProjectSelected={projectSelectedHandler}
            projects={projects}
            selectedProjectId={selectedProjectId}
            tags={tags}
            categories={categories}
            errors={errors}
            validationErrors={validationErrors}
            loading={loading}
            mode={mode}
         /></Tab.Pane>
      },
      {
         menuItem: `${contentType} Options`,
         render: () => <Tab.Pane className={classes.Tab}>
            {children(content, onChange, errors, validationErrors, loading, mode)}
         </Tab.Pane>
      },
   ];

   let errorList;
   if (Array.isArray(errors)) {
      errorList = errors
   } else if (errors) {
      errorList = [errors];
   }

   return (
      <>
         <Container>
            <Divider horizontal>{title}</Divider>
         </Container>
         <Container className={classes.FormContainer}>
            <Tab panes={panes} />
            <GlobalFormErrors title={`${contentType} not saved`} globalErrors={errorList} validationErrors={validationErrors} />
            <Container textAlign="right">
               <BackButton />
               <SubmitButton submitHandler={onSubmit} disabled={mode === 'view'} />
            </Container>
         </Container>
      </>
   );
}

export default ContentForm;