import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import IpsHome from './IpsHome';
import NewAllowedIpForm from './NewAllowedIpForm';
import EditIpForm from './EditIpForm';

const AllowedIps = props => {

   const {path, url} = useRouteMatch();

   return (
      <Switch>
         <Route path={`${path}/new`}>
            <NewAllowedIpForm />
         </Route>
         <Route path={`${path}/view/:id`}>
            <EditIpForm mode="view" />
         </Route>
         <Route path={`${path}/edit/:id`}>
            <EditIpForm mode="edit" />
         </Route>
         <Route path={`${path}`}>
            <IpsHome path={path} />
         </Route>
      </Switch>
   );
}

export default AllowedIps;