import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Label, Container, Segment } from 'semantic-ui-react';

import classes from './Dashboard.module.css';

const CountCard = ({ count, title, link }) => {

   let labeElement = null;
   if (link) labeElement = Link;
   return (
      <Card centered>
         <Card.Header textAlign="center" className={classes.Header}>
            <Label as={labeElement} to={link} className={classes.Label}>{count}</Label>
         </Card.Header>
         <Card.Content textAlign="center" className={classes.Content}>
            {title}
         </Card.Content>
      </Card>
   );
}

export default CountCard;