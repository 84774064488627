import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import TagForm from './TagForm';
import Tag from '../../models/Tag';
import * as actions from '../../store/actions/index';
import {cloneTag} from '../../utilities/common';
const EditTagForm = ({ mode }) => {
   const emptyTag = cloneTag({id: undefined, name: 'test', level: [], description: ''});
   const tagId = useParams().id;
   const backendErrors = useSelector(state => state.tags.errors);
   let [validationErrors, setValidationErrors] = useState(undefined);
   const selectedTag = useSelector(state => state.tags.tag) || emptyTag;
   const loading = !useSelector(state => state.tags.tagLoaded);
   const [tag, setTag] = useState(selectedTag);
   tag.scenario = Tag.scenarios.UPDATE;
   // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
   validationErrors = validationErrors || backendErrors;
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(actions.tags.fetchTag(tagId));
      return () => dispatch(actions.tags.resetTagsState());
   }, []);

   useEffect(() => {
      setTag(selectedTag);
   }, [selectedTag]);

   const submitHandler = () => {
      const errors = tag.isValid();
      setValidationErrors(errors);
      if (errors) return;
      dispatch(actions.tags.updateTag(tag));
   }

   const updateTag = (name, value) => {
      const updatedTag = cloneTag(tag);
      updatedTag[name] = value;
      setTag(updatedTag);
   }
   const title = mode === 'edit' ? 'Update tag' : 'View tag'
   return (
      <TagForm title={title} tag={tag} disabled={mode === 'view'} errors={backendErrors.global} validationErrors={validationErrors} loading={loading} onChange={updateTag} onSubmit={submitHandler} />
   );
}

export default EditTagForm;