import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'semantic-ui-react';

import Table from '../table/FagcTable';
import { shortDateRenderer } from '../table/cellRenderers';
import { getDateBeforeNDays } from '../../utilities/common';
import * as actions from '../../store/actions/index';
import classes from './Reports.module.css';

const Reports = props => {
   const cType = useParams().cType;
   const statData = useSelector(state => state.stat.impressions.filter(imp => imp.content_type === cType));
   const from = useSelector(state => state.stat.from) || getDateBeforeNDays(new Date, 7);
   const loading = useSelector(state => state.stat.loading);
   const [group, setGroup] = useState('none')
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(actions.stat.fetchImpressions(from));
   }, [dispatch, from]);

   const allColumns = {
      id: "ID",
      content_id: "Content ID",
      content_name: "Content Name",
      date: "Date",
      project_id: "Project ID",
      project_name: "Project Name",
      impressions: "Impressions",
   }

   const groupByOptions = [
      { text: '--------', value: 'none' },
      { text: 'Content', value: 'content' },
      { text: 'Project', value: 'project' },
      { text: 'Content & Project', value: 'content_project' },
   ];
   const pagination = { active: 1, pageSize: 10 }

   const fromChangeHandler = (event, data) => {
      dispatch(actions.stat.setImpressionsFromTime(data.value));
   }

   const groupByChangeHandler = (event, data) => {
      setGroup(data.value);
   }

   const groupBy = fields => {
      let data = statData;
      let toDelete = [];
      const cols = { ...allColumns }

      switch (fields) {
         case 'project':
            data = Object.values(statData.reduce(projectReducer, {}));
            toDelete = ['id', 'content_id', 'content_name', 'date'];
            break;
         case 'content':
            data = Object.values(statData.reduce(contentReducer, {}));
            toDelete = ['id', 'project_id', 'project_name', 'date'];
            break;
         case 'content_project':
            data = Object.values(statData.reduce(contentProjectReducer, {}));
            toDelete = ['id', 'date'];
      }
      toDelete.forEach(field => delete cols[field]);
      return [data, cols];
   }

   const projectReducer = (acc, item) => {
      if (acc[item.project_id]) {
         acc[item.project_id].impressions += +item.impressions;
      } else {
         acc[item.project_id] = {
            project_id: item.project_id,
            project_name: item.project_name,
            impressions: +item.impressions,
         }
      }
      return acc;
   }

   const contentReducer = (acc, item) => {
      if (acc[item.content_id]) {
         acc[item.content_id].impressions += +item.impressions;
      } else {
         acc[item.content_id] = {
            content_id: item.content_id,
            content_name: item.content_name,
            impressions: +item.impressions,
         }
      }
      return acc;
   }

   const contentProjectReducer = (acc, item) => {
      if (acc[`${item.project_id}x${item.content_id}`]) {
         acc[`${item.project_id}x${item.content_id}`].impressions += +item.impressions;
      } else {
         acc[`${item.project_id}x${item.content_id}`] = {
            project_id: item.project_id,
            project_name: item.project_name,
            content_id: item.content_id,
            content_name: item.content_name,
            impressions: +item.impressions,
         }
      }
      return acc;
   }

   const [data, columns] = groupBy(group);

   return (
      <>
         <Form>
            <Form.Input label="From:" type="date" onChange={fromChangeHandler} value={from} />
            <Form.Select label="Group by:" options={groupByOptions} onChange={groupByChangeHandler} value={group} />
         </Form>
         <Table className={classes.ReportsTable} data={data} columns={columns} pagination={pagination} loading={loading} renderer={shortDateRenderer} />
      </>
   );
}

export default Reports;