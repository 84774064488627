import React, { useState } from 'react';
import { Grid, Form, Button, Icon } from 'semantic-ui-react';

import classes from './Instructions.module.css';

const Instructions = ({ name, label, items = [], error, disabled, onChange }) => {
   const [instruction, setInstruction] = useState('');
   const [activeItem, setActiveItem] = useState(undefined);
   
   const addHandler = () => {
      const newItems = [...items];
      newItems.unshift(instruction);
      onChange(name, newItems);
      setInstruction('');

   }

   const saveHandler = () => {
      const newItems = [...items];
      newItems[activeItem] = instruction;
      // setInstruction('');
      onChange(name, newItems);
   }

   const removeHandler = () => {
      const newItems = [...items];
      newItems.splice(activeItem, 1);
      setActiveItem(undefined);
      setInstruction('');
      onChange(name, newItems);
   }

   const keyDownHandler = event => {
      if (event.keyCode === 13) {
         addHandler();
         event.preventDefault();
      }
   }

   const moveDown = () => {
      const newItems = [...items];
      const temp = newItems[activeItem + 1];
      newItems[activeItem + 1] = newItems[activeItem];
      newItems[activeItem] = temp;
      setActiveItem(activeItem + 1);
      onChange(name, newItems);
   }

   const moveUp = () => {
      const newItems = [...items];
      const temp = newItems[activeItem - 1];
      newItems[activeItem - 1] = newItems[activeItem];
      newItems[activeItem] = temp;
      setActiveItem(activeItem - 1);
      onChange(name, newItems);
   }

   const instructionChange = value => {
      setInstruction(value);
   }

   const itemSelected = index => {
      if (activeItem !== index) {
         setActiveItem(index);
         setInstruction(items[index])
      } else {
         setActiveItem(undefined);
         setInstruction('');
      }
   }

   let moveDownDisabled = true;
   let moveUpDisabled = true;
   if (activeItem !== undefined) {
      moveDownDisabled = activeItem >= items.length - 1;
      moveUpDisabled = activeItem === 0;
   }

   const allItems = items.map((item, index) => (
      <div key={index} className={`${classes.Item} ${activeItem === index ? classes.Active : ''}`} onClick={() => itemSelected(index)}>
         <span>{item}</span>
      </div>
   ));

   return (
      <Grid columns={2} className={classes.InstructionsGrid}>
         <Grid.Column width={14}>
            <Form.Input label={label} value={instruction} onChange={(event, { value }) => { instructionChange(value) }} error={error || null} disabled={disabled} onKeyDown={keyDownHandler} />
         </Grid.Column>
         <Grid.Column width={2}>
            <Button icon className={classes.ButtonPlus} onClick={addHandler} disabled={instruction === '' || disabled} onKeyDown={event => event.preventDefault()}>
               <Icon name="plus" size="big" />
            </Button>
            <Button icon className={classes.ButtonPlus} onClick={saveHandler} disabled={activeItem === undefined || instruction === '' || disabled} onKeyDown={event => event.preventDefault()}>
               <Icon name="save outline" size="big" />
            </Button>
         </Grid.Column>
         <Grid.Column width={14}>
            <div id="instructions_container" className={classes.InstructionsContainer}>
               {allItems}
            </div>
         </Grid.Column>
         <Grid.Column width={2}>
            <Button className={classes.ButtonMinus} icon onClick={removeHandler} disabled={activeItem === undefined || disabled} onKeyDown={event => event.preventDefault()}>
               <Icon name="minus" size="big" />
            </Button>
            <Button className={classes.ButtonDecrement} icon onClick={moveUp} disabled={moveUpDisabled} onKeyDown={event => event.preventDefault()}>
               <Icon name="angle up" size="big" />
            </Button>
            <Button className={classes.ButtonIncrement} icon onClick={moveDown} disabled={moveDownDisabled} onKeyDown={event => event.preventDefault()}>
               <Icon name="angle down" size="big" />
            </Button>
         </Grid.Column>
      </Grid>
   );
}

export default Instructions;