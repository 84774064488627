import React from 'react';
import { Form, Header, Segment, Image, Message } from 'semantic-ui-react';

import logo from '../../assets/logo.png';
import classes from './Auth.module.css';

const ForgotPassword = ({ email, loading, onChange, onSubmit, successMsgs, errorMsgs, fieldErrors }) => {

   let successMsg = null;
   if (successMsgs) {
      successMsg = (
         <Message
            success
            header='Reset Password Success'
            content={successMsgs}
         />
      );
   }

   let errorMsg = null;
   if (errorMsgs) {
      errorMsg = (
         <Message
            error
            header='Reset Password Failed'
            content={errorMsgs}
         />
      );
   }

   fieldErrors = fieldErrors || {};
   
   return (
      <Segment className={classes.forgot}>
         <Form onSubmit={onSubmit} className={classes.forgot} success={!!successMsg} error={!!errorMsg} >
            <Header>Reset Password</Header>
            <Form.Field>
               <Form.Input name="email" placeholder="Email" value={email} onChange={onChange} error={fieldErrors.email ? { content: fieldErrors.email[0], pointing: 'below' } : null} />
            </Form.Field>
            {successMsg}
            {errorMsg}
            <Form.Button name='submit' floated="right" primary content='Submit' loading={loading} />
         </Form>
         <Segment circular className={classes.logo}>
            <Image src={logo} size="mini" />
         </Segment>
      </Segment>
   );
}

export default ForgotPassword;