import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import LegalHome from './LegalHome';
import NewLegal from './NewLegalForm';
import EditLegal from './EditLegalForm';

const Legals = props => {

   const {path, url} = useRouteMatch();

   return (
      <Switch>
         <Route path={`${path}/new`}>
            <NewLegal />
         </Route>
         <Route path={`${path}/view/:id`}>
            <EditLegal mode="view" />
         </Route>
         <Route path={`${path}/edit/:id`}>
            <EditLegal mode="edit" />
         </Route>
         <Route path={`${path}`}>
            <LegalHome path={path} />
         </Route>
      </Switch>
   );
}

export default Legals;