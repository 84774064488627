import { call, put } from 'redux-saga/effects';

import * as actions from '../store/actions/index';

export const status = response => {
   if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response)
   } else {
      return Promise.reject(new Error(response.statusText))
   }
}

export const json = response => {
   return response.json()
}

export function* processResponse(response) {
   yield call(status, response);
   const jsonResponse = yield call(json, response);
   if (jsonResponse.status === 'OK' && jsonResponse.data) {
      return jsonResponse;
   } else {
      const messages = jsonResponse.message || jsonResponse.messages;
      if (jsonResponse.status === 'ERROR') {
         if (messages.global && messages.global.includes('Permission denied'))
            yield put(actions.app.addAppMessages({ id: '1', error: true, header: "Permission required", content: "Please check your acount permissions" }));
         if (messages.global && messages.global.includes('It looks that Your session has expired')) {
            yield put(actions.app.addAppMessages({ id: '1', error: true, header: "Session expired", content: "It looks that Your session has expired. Please login again." }));
            yield put(actions.auth.resetAuthState());
         }
      }
      throw jsonResponse;
   }
}