import { FETCH_PROJECTS, FETCH_PROJECTS_SUCCESS, FETCH_PROJECTS_FAILURE, FETCH_PROJECT, FETCH_PROJECT_SUCCESS, FETCH_PROJECT_FAILURE, ADD_NEW_PROJECT, ADD_NEW_PROJECT_SUCCESS, ADD_NEW_PROJECT_FAILURE, UPDATE_PROJECT, UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_FAILURE, RESET_PROJECTS_STATE, CLONE_PROJECT, CLONE_PROJECT_SUCCESS, CLONE_PROJECT_FAILURE } from './actionTypes';

export const fetchProjects = () => {
   return {
      type: FETCH_PROJECTS,
   }
}

export const fetchProjectsSuccess = projects => {
   return {
      type: FETCH_PROJECTS_SUCCESS,
      projects,
   }
}

export const fetchProjectsFailure = () => {
   return {
      type: FETCH_PROJECTS_FAILURE,
   }
}

export const fetchProject = id => {
   return {
      type: FETCH_PROJECT,
      id,
   }
}

export const fetchProjectSuccess = project => {
   return {
      type: FETCH_PROJECT_SUCCESS,
      project,
   }
}

export const fetchProjectFailure = messages => {
   return {
      type: FETCH_PROJECT_FAILURE,
   }
}

export const addNewProject = project => {
   return {
      type: ADD_NEW_PROJECT,
      project,
   }
}

export const addNewProjectSuccess = project => {
   return {
      type: ADD_NEW_PROJECT_SUCCESS,
      project,
   }
}

export const addNewProjectFailure = errors => {
   return {
      type: ADD_NEW_PROJECT_FAILURE,
      errors,
   }
}

export const updateProject = project => {
   return {
      type: UPDATE_PROJECT,
      project,
   }
}

export const updateProjectSuccess = project => {
   return {
      type: UPDATE_PROJECT_SUCCESS,
      project,
   }
}

export const updateProjectFailure = errors => {
   return {
      type: UPDATE_PROJECT_FAILURE,
      errors,
   }
}

export const cloneProject = project => {
   return {
      type: CLONE_PROJECT,
      project,
   }
}

export const cloneProjectSuccess = project => {
   return {
      type: CLONE_PROJECT_SUCCESS,
      project,
   }
}

export const cloneProjectFailure = errors => {
   return {
      type: CLONE_PROJECT_FAILURE,
      errors,
   }
}

export const resetProjectsState = () => {
   return {
      type: RESET_PROJECTS_STATE,
   }
}