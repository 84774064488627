import {
   FETCH_IPS_SUCCESS,
   FETCH_IPS_FAILURE,
   FETCH_IP_SUCCESS,
   FETCH_IP_FAILURE,
   ADD_NEW_IP_SUCCESS,
   ADD_NEW_IP_FAILURE,
   UPDATE_IP_SUCCESS,
   UPDATE_IP_FAILURE,
   RESET_IP_STATE,
} from '../actions/actionTypes';

import AllowedIp from '../../models/AllowedIp';
import { cloneIp } from '../../utilities/common';

const emptyIpData = { id: undefined, ip: '', lebel: '', description: '', username: '', creation_datetime: '', update_datetime: '' };
const initialState = {
   ips: [],
   ipsLoaded: false,
   ipLoaded: false,
   ip: cloneIp(emptyIpData),
   messages: '',
   errors: '',
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case FETCH_IPS_SUCCESS:
         return {
            ...state,
            ips: action.ips,
            ipsLoaded: true,
         }
      case FETCH_IPS_FAILURE:
         return {
            ...state,
            ips: [],
            messages: '',
            errors: action.errors
         }
      case FETCH_IP_SUCCESS:
         return {
            ...state,
            ip: action.ip,
            ipLoaded: true,
         }
      case FETCH_IP_FAILURE:
         return {
            ...state,
            messages: '',
            errors: action.errors,
            ipLoaded: false,
         }
      case ADD_NEW_IP_SUCCESS:
         return {
            ...state,
            ip: action.ip,

         }
      case ADD_NEW_IP_FAILURE:
         return {
            ...state,
            errors: action.errors,
         }
      case UPDATE_IP_SUCCESS:
         return {
            ...state,
            ip: action.ip,

         }
      case UPDATE_IP_FAILURE:
         return {
            ...state,
            errors: action.errors,
         }
      case RESET_IP_STATE:
         return initialState;
      default:
         return state
   }
}

export default reducer;