import * as acTypes from './actionTypes';

export const fetchContents = cType => {
   return {
      type: acTypes.FETCH_CONTENTS,
      cType,
   }
}

export const fetchContentsSuccess = (content, cType) => {
   return {
      type: acTypes.FETCH_CONTENTS_SUCCESS,
      content,
      cType,
   }
}

export const fetchContentsFailure = (errors, cType) => {
   return {
      type: acTypes.FETCH_CONTENTS_FAILURE,
      cType,
      errors,
   }
}

export const fetchContent = (id, cType) => {
   return {
      type: acTypes.FETCH_CONTENT,
      id,
      cType
   }
}

export const fetchContentSuccess = (content, cType) => {
   return {
      type: acTypes.FETCH_CONTENT_SUCCESS,
      content,
      cType,
   }
}

export const fetchContentFailure = (errors, cType) => {
   return {
      type: acTypes.FETCH_CONTENT_FAILURE,
      errors,
      cType,
   }
}

export const updateContent = (content, cType) => {
   return {
      type: acTypes.UPDATE_CONTENT,
      content,
      cType,
   }
}

export const updateContentSuccess = (content, cType) => {
   return {
      type: acTypes.UPDATE_CONTENT_SUCCESS,
      content,
      cType,
   }
}

export const updateContentFailure = (errors, cType) => {
   return {
      type: acTypes.UPDATE_CONTENT_FAILURE,
      errors,
      cType,
   }
}

export const addNewContent = (content, cType) => {
   return {
      type: acTypes.ADD_NEW_CONTENT,
      content,
      cType,
   }
}

export const addNewContentSuccess = (content, cType) => {
   return {
      type: acTypes.ADD_NEW_CONTENT_SUCCESS,
      content,
      cType,
   }
}

export const addNewContentFailure = (errors, cType) => {
   return {
      type: acTypes.ADD_NEW_CONTENT_FAILURE,
      errors,
      cType,
   }
}

export const resetContentState = () => {
   return {
      type: acTypes.RESET_CONTENTS_STATE,
   }
}