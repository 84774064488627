import { REDIRECT_TO, RESET_REDIRECT, ADD_APP_MESSAGES, DISMISS_APP_MESSAGES, CLEAR_APP_MESSAGES } from './actionTypes';

export const redirectTo = path => {
   return {
      type: REDIRECT_TO,
      path,
   }
}

export const resetRedirect = () => {
   return {
      type: RESET_REDIRECT,
   }
}

export const addAppMessages = messages => {
   return {
      type: ADD_APP_MESSAGES,
      messages,
   }
}

export const dismissAppMessages = messageIds => {
   return {
      type: DISMISS_APP_MESSAGES,
      messageIds,
   }
}

export const clearAppMessages = () => {
   return {
      type: CLEAR_APP_MESSAGES,
   }
}