import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Legal from '../../models/LegalTemplate';
import LegalForm from './LegalForm';
import * as actions from '../../store/actions/index';
import { cloneLegal } from '../../utilities/common';

const EditLegalForm = ({ mode }) => {
   const legalId = useParams().id;
   const backendErrors = useSelector(state => state.legals.errors);
   let [validationErrors, setValidationErrors] = useState(undefined);
   const selectedLegal = useSelector(state => state.legals.legal);
   const loading = !useSelector(state => state.legals.legalLoaded);
   const [legal, setLegal] = useState(selectedLegal);
   // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
   validationErrors = validationErrors || backendErrors;
   const title = mode === 'edit' ? 'Update legal document' : 'View legal document'
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(actions.legals.fetchLegal(legalId));
      return () => dispatch(actions.legals.resetLegalState());
   }, []);

   useEffect(() => {
      setLegal(selectedLegal);
      //legal.scenario = Legal.scenarios.UPDATE;
   }, [selectedLegal]);

   const submitHandler = () => {
      const errors = legal.isValid();
     setValidationErrors(errors);
     if (errors) return;
      if (mode === 'edit') {
         dispatch(actions.legals.updateLegal(legal));
       }
   }

   const updateLegal = (name, value) => {
      const updatedLegal = cloneLegal(legal);
      if(name === 'level') {
         updatedLegal['content_types'] = [];
         updatedLegal['projects'] = [];
      }
     // name=name.toLowerCase();
      name = ((name === 'CONTENT_TYPE' || name === 'PROJECT') ? name + 's' : name).toLowerCase();
      updatedLegal[name.toLowerCase()] = value;
      //updatedLegal.scenario = Legal.scenarios.UPDATE;
      setLegal(updatedLegal);
   }

   const handleEditorChange = (content, editor) => {
      const updatedLegal = cloneLegal(legal);
      updatedLegal.content = content;
      //updatedLegal.scenario = Legal.scenarios.UPDATE;
      setLegal(updatedLegal);
   }

   return (
      <LegalForm formTitle={title} legal={legal} validationErrors={validationErrors} errors={backendErrors} loading={loading} onSubmit={submitHandler} onChange={updateLegal} mode={mode} onEditorChange={handleEditorChange} disabled={mode === 'view'}/>
   );
}

export default EditLegalForm;