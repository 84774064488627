import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FagcTable from '../table/FagcTable'
import withModel from '../../hoc/table/withModel';
import AllowedIp from '../../models/AllowedIp';
import AddNewIp from '../common/AddNewButton';
import * as actions from '../../store/actions/index';
import withPermissions from '../../hoc/withPermissions';

const Table = withPermissions(withModel(FagcTable, AllowedIp));

const IpsHome = ({ path }) => {
   const ipsLoaded = useSelector(state => state.ips.ipsLoaded);
   const ips = useSelector(state => state.ips.ips);
   const dispatch = useDispatch();

   useEffect(() => {
      if (!ipsLoaded) {
         dispatch(actions.ips.fetchIps());
      }
   }, [ipsLoaded, dispatch]);

   return (
      <>
         <AddNewIp text="Add new IP" to={`${path}/new`} requiredPermissions={{ any: ['allowed_ip'] }} />
         <Table data={ips} actions={{
            eye: { path: data => `${path}/view/${data.id}`, permissions: { any: ['allowed_ip'] } },
            edit: { path: data => `${path}/edit/${data.id}`, permissions: { any: ['allowed_ip'] } },
         }} requiredPermissions={{ any: ['allowed_ip'] }} />
      </>
   );
}

export default IpsHome;