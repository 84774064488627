import { validate } from 'validate.js';

class Tag {
   id;
   name;
   level;
   description;
   scenario = scenarios.DEFAULT;
   labels = { 
		id : 'ID',
		name : 'Name',
		level : 'Levels',
		description : 'Description',
	};
   
   constructor (id, name, level, description) {
      this.id = id;
      this.name = name;
      this.level = level;
      this.description = description;
   }

   isValid = () => {
      const defaultErr = validate(this, constraints[Tag.scenarios.DEFAULT]);
      if (this.scenario === Tag.scenarios.DEFAULT) return defaultErr;
      const err = validate(this, constraints[this.scenario]);
      if(!defaultErr && !err) return undefined;

      let allErrors = {...defaultErr};
      if (err) {
         Object.keys(err).forEach(key => {
            if(allErrors[key]) allErrors[key] = [...allErrors[key], ...err[key]];
            else allErrors[key] = err[key]; 
         });
      }

      return allErrors;
   }
}

const scenarios = {
   DEFAULT: "DEFAULT",
   CREATE: "CREATE",
   UPDATE: "UPDATE",
}

const constraints = {
    "DEFAULT": {
        "name": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be string at most 45 symbols",
                "maximum": 45
            },
            "format": {
                "pattern": "^[a-z][a-z.]{4,44}$",
                "message": "can contain small letters and dot only, 5-44 chacracters. Must start with letter. Example: attribute.hot"
            }
        },
        "level": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be string at most 255 symbols",
                "maximum": 255
            }
        },
        "description": {
            "length": {
                "message": "should be string at most 255 symbols",
                "maximum": 255
            }
        }
    },
    "CREATE": [],
    "UPDATE": []
}

Tag.scenarios = scenarios;
Tag.constraints = constraints;

export default Tag;
