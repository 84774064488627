import { all, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';
import { loginSaga, logoutSaga, authCheckSaga, passwordReset } from './auth';
import { fetchUsersSaga, changePasswordSaga, addNewUserSaga, fetchPermissionsMapSaga, fetchUserSaga, updateUserSaga } from './user';
import { fetchProjectsSaga, fetchProjectSaga, addNewProjectSaga, updateProjectSaga, cloneProjectSaga } from './project';
import { fetchTagsSaga, addNewTagSaga, fetchTagSaga, updateTagSaga } from './tag';
import { fetchCategoriesSaga, addNewCategorySaga, fetchCategorySaga, updateCategorySaga } from './category';
import { fetchLegalsSaga, fetchLegalSaga, addNewLegalSaga, updateLegalSaga } from './legal';
import { fetchIpsSaga, fetchIpSaga, addNewIpSaga, updateIpSaga } from './ips';
import { fetchContentsSaga, fetchContentSaga, updateContentSaga, addNewContentSaga } from './content';
import { fetchContentCounts, fetchProjectCounts } from './dashboard';
import { cdnClearFilesSaga, fetchCdnZonesSaga, clearCdnZoneSaga } from './cdn';
import { fetchImpressions } from './stat';

export function* watchAuth() {
   yield all([
      yield takeEvery(actionTypes.LOGIN, loginSaga),
      yield takeEvery(actionTypes.LOGOUT, logoutSaga),
      yield takeEvery(actionTypes.AUTH_CHECK, authCheckSaga),
      yield takeEvery(actionTypes.PASSWORD_RESET, passwordReset),
   ]);
}

export function* watchUsers() {
   yield all([
      yield takeEvery(actionTypes.FETCH_USERS, fetchUsersSaga),
      yield takeEvery(actionTypes.CHANGE_PASSWORD, changePasswordSaga),
      yield takeEvery(actionTypes.ADD_NEW_USER, addNewUserSaga),
      yield takeEvery(actionTypes.UPDATE_USER, updateUserSaga),
      yield takeEvery(actionTypes.FETCH_PERMISSIONS_MAP, fetchPermissionsMapSaga),
      yield takeEvery(actionTypes.FETCH_USER, fetchUserSaga),
   ]);
}

export function* watchProjects() {
   yield all([
      yield takeEvery(actionTypes.FETCH_PROJECTS, fetchProjectsSaga),
      yield takeEvery(actionTypes.FETCH_PROJECT, fetchProjectSaga),
      yield takeEvery(actionTypes.ADD_NEW_PROJECT, addNewProjectSaga),
      yield takeEvery(actionTypes.UPDATE_PROJECT, updateProjectSaga),
      yield takeEvery(actionTypes.CLONE_PROJECT, cloneProjectSaga),
   ]);
}

export function* watchTags() {
   yield all([
      yield takeEvery(actionTypes.FETCH_TAGS, fetchTagsSaga),
      yield takeEvery(actionTypes.ADD_NEW_TAG, addNewTagSaga),
      yield takeEvery(actionTypes.FETCH_TAG, fetchTagSaga),
      yield takeEvery(actionTypes.UPDATE_TAG, updateTagSaga),
   ]);
}

export function* watchCategories() {
   yield all([
      yield takeEvery(actionTypes.FETCH_CATEGORIES, fetchCategoriesSaga),
      yield takeEvery(actionTypes.ADD_NEW_CATEGORY, addNewCategorySaga),
      yield takeEvery(actionTypes.FETCH_CATEGORY, fetchCategorySaga),
      yield takeEvery(actionTypes.UPDATE_CATEGORY, updateCategorySaga),
   ]);
}
export function* watchLegals() {
   yield all([
      yield takeEvery(actionTypes.FETCH_LEGALS, fetchLegalsSaga),
      yield takeEvery(actionTypes.ADD_NEW_LEGAL, addNewLegalSaga),
      yield takeEvery(actionTypes.FETCH_LEGAL, fetchLegalSaga),
      yield takeEvery(actionTypes.UPDATE_LEGAL, updateLegalSaga),
   ]);
}
export function* watchIps() {
   yield all([
      yield takeEvery(actionTypes.FETCH_IPS, fetchIpsSaga),
      yield takeEvery(actionTypes.ADD_NEW_IP, addNewIpSaga),
      yield takeEvery(actionTypes.FETCH_IP, fetchIpSaga),
      yield takeEvery(actionTypes.UPDATE_IP, updateIpSaga),
   ]);
}

export function* watchContents() {
   yield all([
      yield takeEvery(actionTypes.FETCH_CONTENTS, fetchContentsSaga),
      yield takeEvery(actionTypes.FETCH_CONTENT, fetchContentSaga),
      yield takeEvery(actionTypes.UPDATE_CONTENT, updateContentSaga),
      yield takeEvery(actionTypes.ADD_NEW_CONTENT, addNewContentSaga),
   ]);
}

export function* watchClearCdn() {
   yield all([
      yield takeEvery(actionTypes.FETCH_CDN_ZONES, fetchCdnZonesSaga),
      yield takeEvery(actionTypes.CDN_CLEAR_FILES, cdnClearFilesSaga),
      yield takeEvery(actionTypes.CDN_CLEAR_ZONE, clearCdnZoneSaga),
   ]);
}

export function* watchDashboard() {
   yield all([
      yield takeEvery(actionTypes.FETCH_CONTENT_COUNTS, fetchContentCounts),
      yield takeEvery(actionTypes.FETCH_PROJECT_COUNTS, fetchProjectCounts),
   ]);
}

export function* watchStat() {
   yield all([
      yield takeEvery(actionTypes.FETCH_IMPRESSIONS, fetchImpressions),
   ]);
}