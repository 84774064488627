import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import TagsTable from './TagsTable';
import NewTag from './NewTagForm';
import EditTag from './EditTagForm';

const Tags = props => {

   const {path, url} = useRouteMatch();

   return (
      <Switch>
         <Route path={`${path}/new`}>
            <NewTag />
         </Route>
         <Route path={`${path}/view/:id`}>
            <EditTag mode="view" />
         </Route>
         <Route path={`${path}/edit/:id`}>
            <EditTag mode="edit" />
         </Route>
         <Route path={`${path}`}>
            <TagsTable path={path} />
         </Route>
      </Switch>
   );
}

export default Tags;