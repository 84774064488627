import { FETCH_CONTENT_COUNTS, FETCH_CONTENT_COUNTS_SUCCESS, FETCH_CONTENT_COUNTS_FAILURE, FETCH_PROJECT_COUNTS, FETCH_PROJECT_COUNTS_SUCCESS, FETCH_PROJECT_COUNTS_FAILURE } from './actionTypes';

export const fetchContentCounts = () => {
   return {
      type: FETCH_CONTENT_COUNTS,
   }
}

export const fetchContentCountsSuccess = contentCounts => {
   return {
      type: FETCH_CONTENT_COUNTS_SUCCESS,
      data: contentCounts,
   }
}

export const fetchContentCountsFailure = errors => {
   return {
      type: FETCH_CONTENT_COUNTS_FAILURE,
      errors, 
   }
}

export const fetchProjectCounts = () => {
   return {
      type: FETCH_PROJECT_COUNTS,
   }
}

export const fetchProjectCountsSuccess = projectCounts => {
   return {
      type: FETCH_PROJECT_COUNTS_SUCCESS,
      data: projectCounts,
   }
}

export const fetchProjectCountsFailure = errors => {
   return {
      type: FETCH_PROJECT_COUNTS_FAILURE,
      errors, 
   }
}