import React from 'react';
import { Form } from 'semantic-ui-react';

export const legalTypes = [
   'about_us',
   'terms_of_use',
   'member_terms_of_use',
   'auto_funding',
   'privacy_policy',
   'copyright_policy',
   'uninstall',
].map(content => ({ text: content.toUpperCase(), value: content.toUpperCase() }));

const legalTypeSelect = ({name='type', error, value='about_us', label='Type:', disabled, onChange}) => {
   return (
      <Form.Select error= {error} label={label} name={name} value={value} options={legalTypes} disabled={disabled} onChange={(e, {name, value}) => onChange(name, value)} />
   );
}

export default legalTypeSelect;