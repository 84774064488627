import React, { useState } from 'react';
import { Form, Segment, Grid } from 'semantic-ui-react';

import FileSelect from '../../common/FileSelect';
import Image from '../../common/Image';
import classes from '../Content.module.css';


const GameWallpaperOptions = ({ loading, gameWallpaper, mode, validationErrors, onChange }) => {
   const [imageSrc, setImageSource] = useState();
   const [previewActive, setPreviewActive] = useState();
   const [mediaUrl, setMediaUrl] = useState();
   const [mediaText, setMediaText] = useState();

   const previewHandler = (name, value) => {
      setPreviewActive(name);
      if (value instanceof File) {
         const reader = new FileReader();
         reader.onload = event => setImageSource(event.target.result);
         reader.readAsDataURL(value);
      } else {
         if (value) {
            if(value.includes(".jpg") || value.includes(".png") || value.includes(".gif")) {
               setImageSource(value);
               setMediaUrl('');
               setMediaText("");
            }
         } else {
            setImageSource('');
            setMediaUrl('');
            setMediaText("");
         }
      }
   }

   const mediaChangeHandler = (name, value) => {
      setPreviewActive(name);
      onChange(name, value);
   }

   const disabled = mode === 'view';
   return (
      <Form loading={loading} className={classes.Form}>
         <Form.Field>
            <label>Media:</label>
            <Segment as="fieldset" className={classes.MediaSegment}>
               <Grid columns={2}>
                  <Grid.Column>
                     <FileSelect label="Image thumb:" name="image_thumb" previewActive={previewActive === 'image_thumb'} value={gameWallpaper.image_thumb} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_thumb || null} disabled={disabled} />
                     <FileSelect label="Image HD:" name="image_hd" previewActive={previewActive === 'image_hd'} value={gameWallpaper.image_hd} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_hd || null} disabled={disabled} />
                     <FileSelect label="Image 4k:" name="image_4k" previewActive={previewActive === 'image_4k'} value={gameWallpaper.image_4k} onChange={mediaChangeHandler} onPreview={previewHandler} error={validationErrors.image_4k || null} disabled={disabled} />     
                  </Grid.Column>
                  <Grid.Column textAlign="center" verticalAlign="middle">
                     <Image src={imageSrc} className={classes.PreviewImage} />
                     <a id="mediaUrl" href={mediaUrl} target="_blank" >{mediaText}</a>
                  </Grid.Column>
               </Grid>
            </Segment>
         </Form.Field>
      </Form>
   );
}

export default GameWallpaperOptions;