import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Project from '../../models/Project';
import ProjectForm from './ProjectForm';
import * as actions from '../../store/actions/index';
import { cloneProject } from '../../utilities/common';

const EditProjectForm = ({ mode }) => {
   const projectId = useParams().id;
   const backendErrors = useSelector(state => state.projects.errors);
   let [validationErrors, setValidationErrors] = useState(undefined);
   const selectedProject = useSelector(state => state.projects.project);
   const loading = useSelector(state => state.projects.requestPending);
   const [project, setProject] = useState(selectedProject);
   project.scenario = Project.scenarios.UPDATE;
   // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
   validationErrors = validationErrors || backendErrors;

   let title = 'Update project';
   if (mode==='view')  title =  'View project';
   if (mode==='clone') title =  'Clone project';

   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(actions.projects.fetchProject(projectId));
      return () => dispatch(actions.projects.resetProjectsState());
   }, []);

   useEffect(() => {
      setProject(selectedProject);
   }, [selectedProject]);

   const submitHandler = () => {
      const errors = project.isValid();
      setValidationErrors(errors);
      if (errors) return;
      if (mode === 'edit') {
         dispatch(actions.projects.updateProject(project));
      } else {
         dispatch(actions.projects.cloneProject(project));
      }
   }

   const updateProject = (name, value) => {
      const updatedProject = cloneProject(project);
      updatedProject[name] = value;
      setProject(updatedProject);
   }
   return (
      <ProjectForm title={title} project={project} validationErrors={validationErrors} errors={backendErrors.global} loading={loading} onSubmit={submitHandler} onChange={updateProject} mode={mode} disabled={mode === 'view'}/>
   );
}

export default EditProjectForm;