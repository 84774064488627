import React from 'react';
import { Accordion, Form, Divider, Menu, Popup, Icon, Segment} from 'semantic-ui-react';

import classes from './Users.module.css';


const PermissionItem = ({ title, index, active, disabled, permissionItems, clickHandler, updatePermissions, userPermissions}) => {

   const getCheckboxItems = (items, updatePermissions) => {
      return items.map((item, index) => {
         const label = item['name'].replace(/(_|^)([^_]?)/g, function(_, prep, letter) {
            return (prep && ' ') + letter.toUpperCase();
        });
         return (
               <Popup key={item['name']} 
                     content={item['description']}
                     basic
                     trigger={
                        <Form.Checkbox disabled={disabled} checked={checkedPermissionState(item['name'])} key={item['name']} label={label} value={item['name']} onChange={updatePermissions} />}
               />
           );
      });
   };

   const checkedPermissionState = (value) => {
      let checkedBox=false;
      userPermissions.forEach(element => {
         if(element === value) {
            checkedBox=true;
         }
      });
      return checkedBox;
   }
      return (
         <Menu.Item>
            <Accordion.Title
               content={title}
               index={index}
               active={active}
               onClick={clickHandler}
               className={classes.Title}
            />
            <Accordion.Content active={active} 
               content={(
                  <>
                     <Divider></Divider>                                   
                     <Form className={classes.Form}>
                        <Form.Group grouped>
                           {getCheckboxItems(permissionItems, updatePermissions)}
                        </Form.Group>
                     </Form>
                  </>
               )} />
         </Menu.Item>
      );

}

export default PermissionItem;