import { validate } from 'validate.js';

class LegalTemplate {
   id;
   type;
   level;
   title;
   content;
   content_types;
   projects;
   comment;
   author;
   version;
   creation_datetime;
   scenario = scenarios.DEFAULT;
   labels = { 
		id : 'ID',
		type : 'Type',
		title : 'Title',
		level : 'Level',
		content_types : 'Content Types',
		projects : 'Projects',
		creation_datetime : 'Creation Datetime',
		version : 'Version',
		author : 'Author',
	};
   
   constructor (id, type, level, title, content, content_types, projects, comment, author, version, creation_datetime) {
      this.id = id;
      this.type = type;
      this.level = level;
      this.title = title;
      this.content = content;
      this.content_types = content_types;
      this.projects = projects;
      this.comment = comment;
      this.author = author;
      this.version = version;
      this.creation_datetime = creation_datetime;
   }

   isValid = () => {
      const defaultErr = validate(this, constraints[LegalTemplate.scenarios.DEFAULT]);
      if (this.scenario === LegalTemplate.scenarios.DEFAULT) return defaultErr;
      const err = validate(this, constraints[this.scenario]);
      if(!defaultErr && !err) return undefined;

      let allErrors = {...defaultErr};
      if (err) {
         Object.keys(err).forEach(key => {
            if(allErrors[key]) allErrors[key] = [...allErrors[key], ...err[key]];
            else allErrors[key] = err[key]; 
         });
      }

      return allErrors;
   }
}

const scenarios = {
   DEFAULT: "DEFAULT",
   CREATE: "CREATE",
   UPDATE: "UPDATE",
}

const constraints = {
    "DEFAULT": {
        "type": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "inclusion": {
                "within": [
                    "ABOUT_US",
                    "TERMS_OF_USE",
                    "PRIVACY_POLICY",
                    "COPYRIGHT_POLICY",
                    "UNINSTALL",
                    "AUTO_FUNDING",
                    "MEMBER_TERMS_OF_USE"
                ],
                "message": "is invalid"
            }
        },
        "title": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be at most 256 symbols",
                "maximum": 256
            }
        },
        "level": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "inclusion": {
                "within": [
                    "GLOBAL",
                    "PROJECT",
                    "CONTENT_TYPE"
                ],
                "message": "is invalid"
            }
        },
        "content": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "author": {
            "length": {
                "message": "should be at most 45 symbols",
                "maximum": 45
            }
        },
        "comment": {
            "length": {
                "message": "should be at most 512 symbols",
                "maximum": 512
            }
        }
    },
    "CREATE": [],
    "UPDATE": []
}

LegalTemplate.scenarios = scenarios;
LegalTemplate.constraints = constraints;

export default LegalTemplate;
