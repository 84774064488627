import React from 'react';

const withModel = (WrappedComponent, Model) => {
   const model = new Model();

   // const columns = [];
   // for (const prop in model) {
   //   columns.push(prop);
   // }
   const columns = model['labels'];
   
   return props => (
      <WrappedComponent columns={columns} {...props} />
   );
}

export default withModel;