import { validate } from 'validate.js';

class Project {
   id;
   factor_id;
   name;
   tld;
   content_type;
   status;
   config;
   http_locked;
   supports_https;
   scenario = scenarios.DEFAULT;
   labels = { 
		id : 'ID',
		factor_id : 'Factor ID',
		name : 'Name',
		tld : 'TLD',
		content_type : 'Content Type',
		supports_https : 'HTTPS',
		http_locked : 'HTTP Lock (bobo)',
		status : 'Status',
	};
   
   constructor (id, factor_id, name, tld, content_type, status, config, http_locked, supports_https) {
      this.id = id;
      this.factor_id = factor_id;
      this.name = name;
      this.tld = tld;
      this.content_type = content_type;
      this.status = status;
      this.config = config;
      this.http_locked = http_locked;
      this.supports_https = supports_https;
   }

   isValid = () => {
      const defaultErr = validate(this, constraints[Project.scenarios.DEFAULT]);
      if (this.scenario === Project.scenarios.DEFAULT) return defaultErr;
      const err = validate(this, constraints[this.scenario]);
      if(!defaultErr && !err) return undefined;

      let allErrors = {...defaultErr};
      if (err) {
         Object.keys(err).forEach(key => {
            if(allErrors[key]) allErrors[key] = [...allErrors[key], ...err[key]];
            else allErrors[key] = err[key]; 
         });
      }

      return allErrors;
   }
}

const scenarios = {
   DEFAULT: "DEFAULT",
   CREATE: "CREATE",
   UPDATE: "UPDATE",
}

const constraints = {
    "DEFAULT": {
        "factor_id": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "numericality": {
                "onlyInteger": true,
                "message": "must be integer"
            }
        },
        "name": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be string at most 45 symbols",
                "maximum": 45
            }
        },
        "tld": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "format": {
                "pattern": "^[a-z]{2,10}$",
                "message": "must be valid top level domain, i.e. com, net, org... without dot"
            }
        },
        "content_type": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "inclusion": {
                "within": [
                    "news",
                    "classifieds",
                    "fileconverter",
                    "apps",
                    "tv",
                    "maps",
                    "package",
                    "coupon",
                    "noads",
                    "forms",
                    "speedtest",
                    "weather",
                    "game",
                    "radio",
                    "recipe",
                    "quiz",
                    "prototype",
                    "astrology",
                    "streaming",
                    "sports",
                    "quotes",
                    "health",
                    "books",
                    "login",
                    "manuals",
                    "vacation",
                    "qrcode"
                ],
                "message": "is invalid"
            }
        },
        "status": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "inclusion": {
                "within": [
                    "ACTIVE",
                    "INACTIVE"
                ],
                "message": "is invalid"
            }
        },
        "http_locked": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "inclusion": {
                "within": [
                    "YES",
                    "NO"
                ],
                "message": "is invalid"
            }
        },
        "supports_https": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "inclusion": {
                "within": [
                    "YES",
                    "NO"
                ],
                "message": "is invalid"
            }
        }
    },
    "CREATE": [],
    "UPDATE": []
}

Project.scenarios = scenarios;
Project.constraints = constraints;

export default Project;
