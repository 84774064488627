import React, { useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../store/actions/index';


export const contentTypes = [
   'game',
   'speedtest',
   'quiz',
   'video',
   'maps',
   'coupon',
   'weather',
   'package',
   'apps',
   'prototype',
   'news',
   'classifieds',
   'fileconverter',
   'tv',
   'noads',
   'forms',
   'radio',
   'recipe',
   'astrology',
   'streaming',
   'sports',
   'quotes',
   'health',
   'books',
   'login',
   'manuals',
   'vacation',
   'qrcode',
   'unknown',
].map(content => ({ text: content.toUpperCase(), value: content }));

const DependentTypeSelect = ({name='CONTENT_TYPE', value='game', label='Content Type:', disabled, onChange, error}) => {
   const projects = useSelector(state => state.projects.projects);

   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(actions.projects.fetchProjects());
   }, [dispatch]);

   const projectTypes = projects.map(content => ({ text: content.name, value: content.id}));

   return (
      <Form.Select search multiple label={label} name={name} value={value} options={(name === 'PROJECT') ? projectTypes : contentTypes} disabled={disabled} onChange={(e, {name, value}) => onChange(name, value)} error={error || null} />
   );
}

export default DependentTypeSelect;