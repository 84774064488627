import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ContentForm from '../ContentForm';

import { cloneGameWallpaper } from '../../../utilities/common';
import useContent from '../../../hooks/useContent';
import * as actions from '../../../store/actions/index';
import GameWallpaperOptions from './GameWallpaperOptions';

const GameWallpaperForm = ({ mode }) => {
   const emptyGameWallpaper = cloneGameWallpaper({
      image_thumb: "",
      image_hd: "",
      image_4k: "",
      id: "",
      content_id: "",
      title: "",
      categories: [],
      tags: {},
      media: "",
      projects: [],
      status: 'ACTIVE',
      keywords: "",
   });

   const gameWallpaperId = useParams().id;
   const {
      projectsLoaded,
      projects,
   } = useContent('game');

   const {
      backendErrors,
      contentLoaded,
      tagsLoaded,
      tags,
      categoriesLoaded,
      categories,
      selectedContent,
      requestPending,
      dispatch
   } = useContent('gamewallpaper');

   let [validationErrors, setValidationErrors] = useState(undefined);
   const [selectedProjectId, setSelectedProjectId] = useState();
   const [gameWallpaper, setGameWallpaper] = useState(emptyGameWallpaper);
   // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
   validationErrors = validationErrors || backendErrors;

   useEffect(() => {
      if (!projectsLoaded) dispatch(actions.projects.fetchProjects());
      if (!tagsLoaded) dispatch(actions.tags.fetchTags());
      if (!categoriesLoaded) dispatch(actions.categories.fetchCategories());
      if (mode !== 'new' && !contentLoaded) dispatch(actions.content.fetchContent(gameWallpaperId, 'gamewallpaper'));
      return () => dispatch(actions.content.resetContentState());
   }, []);

   useEffect(() => {
      if (selectedContent && mode !== 'new')
      setGameWallpaper(selectedContent);
   }, [selectedContent]);

   const updateGameWallpaper = (name, value) => {

    setGameWallpaper((gameWallpaper) => {
         const updatedGameWallpaper = cloneGameWallpaper(gameWallpaper);
         if (name === 'tags') {
            updatedGameWallpaper.tags = { ...updatedGameWallpaper.tags, [selectedProjectId]: value }
         } else if (name === 'keywords') {
            updatedGameWallpaper.keywords = value.join(',');
         } else if (name === 'projects') {
            const updatedTags = {};
            value.forEach(projectId => updatedTags[projectId] = updatedGameWallpaper.tags[projectId]);
            updatedGameWallpaper['projects'] = value;
            updatedGameWallpaper['tags'] = updatedTags;
         } else {
            updatedGameWallpaper[name] = value;
         }
         return updatedGameWallpaper;
      });
   }

   const selectedProjectChangeHandler = projectId => {
      setSelectedProjectId(projectId);
   }

   const submitHandler = () => {
      const errors = gameWallpaper.isValid();
      setValidationErrors(errors);
      if (errors) return;
      switch (mode) {
         case 'new':
            dispatch(actions.content.addNewContent(gameWallpaper, 'gamewallpaper'));
            break;
         case 'edit':
            dispatch(actions.content.updateContent(gameWallpaper, 'gamewallpaper'));
            break;
      }
   }

   const handleEditorChange = (content, editor) => {
    const updatedGameWallpaper = cloneGameWallpaper(gameWallpaper);
    updatedGameWallpaper.body = content;
    setGameWallpaper(updatedGameWallpaper);
 } 

   const loading = !projectsLoaded || !tagsLoaded || !categoriesLoaded || (mode !== 'new' && !contentLoaded) || requestPending;
   return (
      <ContentForm
         content={gameWallpaper}
         contentType="Game Wallpaper"
         validationErrors={validationErrors}
         errors={backendErrors.global}
         onChange={updateGameWallpaper}
         onSubmit={submitHandler}
         projects={projects}
         selectedProjectId={selectedProjectId}
         projectSelectedHandler={selectedProjectChangeHandler}
         tags={tags}
         categories={categories}
         loading={loading}
         mode={mode}
      >
         {(content, onChange, errors, validationErrors, loading, mode) => {
            return (
               <GameWallpaperOptions
                  gameWallpaper={content}
                  onChange={onChange}
                  errors={errors}
                  validationErrors={validationErrors}
                  loading={loading}
                  mode={mode}
                  onEditorChange = {handleEditorChange}
               />
            );
         }}
      </ContentForm>
   );
}

export default GameWallpaperForm;