import { LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_SUCCESS, PASSWORD_RESET, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAILURE, RESET_AUTH_STATE } from '../actions/actionTypes';
import User from '../../models/User';

const initialState = {
   authenticated: null,
   authInProgress: false,
   messages: '',
   errors: '',
   loggedUser: false,
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case LOGIN:
         return {
            ...state,
            authInProgress: true
         };
      case LOGIN_SUCCESS:
         const user = new User(action.id, action.username, null, action.status, action.firstName, action.lastName, action.email, action.permissions, action.note);
         return {
            ...state,
            authenticated: true,
            authInProgress: false,
            errors: '',
            loggedUser: user,
         };
      case LOGIN_FAILURE:
         return {
            ...initialState,
            authenticated: false,
            authInProgress: false,
            errors: action.errors,
         };
      case LOGOUT_SUCCESS:
         return {
            ...initialState,
            authenticated: false,
            authInProgress: false,
            loggedUser: false,
         };
      case PASSWORD_RESET:
         return {
            ...state,
            authInProgress: true,
            errors: ''
         };
      case PASSWORD_RESET_SUCCESS:
         return {
            ...state,
            authInProgress: false,
            messages: action.messages,
         }
      case PASSWORD_RESET_FAILURE:
         return {
            ...state,
            messages: '',
            errors: action.errors,
            authInProgress: false,
         }
      case RESET_AUTH_STATE:
         return initialState;
      default:
         return state;
   }
}

export default reducer;