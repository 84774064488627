import React, { useEffect } from 'react';
import { Container, Form, Divider, Message, Label } from 'semantic-ui-react';

import { Editor } from '@tinymce/tinymce-react';


import SubmitButton from '../common/SubmitButton';
import BackButton from '../common/BackButton';
import DependentTypeSelected from '../common/DependentLegalLevelSelect';
import LegalLevelSelect from '../common/LegalLevelSelect';
import LegalTypeSelect from '../common/LegalTypeSelect';
import classes from './LegalForm.module.css';
import { formatValidationErrors, strip_tags } from '../../utilities/common';

const LegalForm = ({ formTitle, legal, validationErrors, errors, onSubmit, onChange, loading, mode, disabled, onEditorChange }) => {
   validationErrors = formatValidationErrors(validationErrors || {});
   const { title, type, level, content } = validationErrors;
   const { global, projects, content_types } = errors || {};

  
   return (
      <>
         <Container >
            <Divider horizontal>{formTitle}</Divider>
         </Container>
         <Container className={classes.FormContainer}>
            <Form loading={loading} className={classes.Form} disabled={disabled}>
               <Form.Field>
                  <Form.Input label="Title:" type="text" name="title" value={legal.title} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} error={title || null} />
               </Form.Field>

               <Form.Field>
                  <LegalTypeSelect value="about_us" onChange={onChange} value={legal.type} disabled={disabled} error={type || null} />
               </Form.Field>
               <Form.Field>
                  <LegalLevelSelect value="GLOBAL" onChange={onChange} value={legal.level} disabled={disabled} error={level || null}/>
               </Form.Field>
               {(legal.level === 'CONTENT_TYPE' || legal.level === 'PROJECT') ? (
                  <Form.Field>
                     <DependentTypeSelected label={(legal.level === 'CONTENT_TYPE') ? 'Content types' : 'Projects'} name={legal.level} value="game" onChange={onChange} value={(legal.level === 'CONTENT_TYPE') ? legal.content_types : legal.projects} disabled={disabled} error={(legal.level === 'CONTENT_TYPE') ? content_types : projects} />
                  </Form.Field>) : null
               }

               <Form.TextArea label='Comment:' name="comment" value={legal.comment} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)}/>

               <Form.Field>
                  <Form.Input id='editor-tinymce-error' label='Content' error={content || null}
                  input = {
                     <Editor
                     value={legal.content}
                     apiKey="4uuto2u1mlgsb5ofwxag9vuppnwl3ybbawhvrsghnl247w3z"
                     init={{
                        height: 500,
                        menubar: true,
                        plugins: 'textcolor table paste',
                        entity_encoding : 'raw', 
                        force_br_newlines : true, 
                        force_p_newlines : false, 
                        forced_root_block : '',
                        plugins: [
                           'advlist autolink lists link image charmap print preview anchor',
                           'searchreplace visualblocks code fullscreen',
                           'insertdatetime media table paste code help wordcount'
                        ],
                        //paste_word_valid_elemnets: "b, strong, i, em, h1, h2, u, p, ol, ul, li, a[href], span, color, mark",
                        paste_retain_style_properties: "all",
                        paste_preprocess: function(plugin, args) {
                           args.content = strip_tags( args.content,'<b><strong><i><em><h1><h2><u><p><ol><ul><li><a[href]><span><color><mark><br>' );
                         },
                         paste_postprocess: function(plugin, args) {
                           //args.node.setAttribute('id', '42');
                         },
                        toolbar:
                           'paste undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help',
                     }}
                     onEditorChange={onEditorChange}
                     disabled= {disabled}
                  />}/>
               </Form.Field>
            </Form>
            {global ? (
               <Message
                  error
                  header='Legal not saved'
                  content={global}
               ></Message>
            ) : null}
            <Container textAlign="right">
               <BackButton />
               <SubmitButton submitHandler={onSubmit} disabled={disabled} />
            </Container>
         </Container>
      </>
   )
}

export default LegalForm;