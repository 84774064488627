import { put, call } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { status, json, processResponse } from '../../utilities/fetchUtils';

export function* loginSaga(action) {
   try {
      const response = yield call(fetch, './user-service/login', {
         method: 'post',
         headers: {
            "Content-type": "application/json"
         },
         body: JSON.stringify({
            username: action.username,
            password: action.password,
            remember: action.remember,
         }),
      });

      const authData = yield call(processResponse, response);
      yield put(actions.auth.loginSuccess(authData.data));
   } catch (e) {
      console.log(e);
      yield put(actions.auth.loginFailure(e.messages[0]));
   }
}

export function* authCheckSaga(action) {
   try {
      const response = yield call(fetch,'/user-service/is-logged-in', {
         method: 'post',
         headers: {
            "Content-type": "application/json"
         },
      });
      const authData = yield call(processResponse,response);
      yield put(actions.auth.loginSuccess(authData.data));
   } catch (e) {
      console.log(e);
      yield put(actions.auth.loginFailure(''));
   }
}

export function* passwordReset(action) {
   try {
      const response = yield call(fetch, '/user-service/forgot-password', {
         method: 'POST',
         headers: {
            "Content-Type": 'application/json',

         },
         body: JSON.stringify({
            email: action.email,
         }),
      });

      const authData = yield call(processResponse,response);
      yield put(actions.auth.passwordResetSuccess(authData));
   } catch (e) {
      console.log(e);
      yield put(actions.auth.passwordResetFailure(e.messages));
   }
}

export function* logoutSaga(action) {
   try {
      yield call(fetch, '/user-service/logout', {
         method: 'post',
         headers: {
            "Content-type": "application/json"
         },
      });
      
      yield put(actions.auth.logoutSuccess());
   } catch (e) {
      console.log(e);
      yield put(actions.auth.logoutFailure(e.messages[0]));
   }
}




