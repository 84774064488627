import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ContentForm from '../ContentForm';
import CouponOptions from './CouponOptions';
import { cloneCoupon } from '../../../utilities/common';
import useContent from '../../../hooks/useContent';
import * as actions from '../../../store/actions/index';

const CouponForm = ({ mode }) => {

   const emptyCoupon = cloneCoupon({
      render_type: 'IFRAME',
      link: '',
      image_1: '',
      image_2: '',
      id: '',
      content_id: '',
      type: 'coupon',
      title: '',
      description: '',
      keywords: '',
      categories: [],
      tags: {},
      projects: [],
      status: 'ACTIVE',
   });

   const couponId = useParams().id;
   const {
      projectsLoaded,
      projects,
      tagsLoaded,
      tags,
      categoriesLoaded,
      categories,
      backendErrors,
      contentLoaded,
      selectedContent,
      requestPending,
      dispatch
   } = useContent('coupon');

   let [validationErrors, setValidationErrors] = useState(undefined);
   const [selectedProjectId, setSelectedProjectId] = useState();
   const [coupon, setCoupon] = useState(emptyCoupon);
   // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
   validationErrors = validationErrors || backendErrors;

   useEffect(() => {
      if (!projectsLoaded) dispatch(actions.projects.fetchProjects());
      if (!tagsLoaded) dispatch(actions.tags.fetchTags());
      if (!categoriesLoaded) dispatch(actions.categories.fetchCategories());
      if (mode !== 'new' && !contentLoaded) dispatch(actions.content.fetchContent(couponId, 'coupon'));
      return () => dispatch(actions.content.resetContentState());
   }, []);

   useEffect(() => {
      if (selectedContent && mode !== 'new')
         setCoupon(selectedContent);
   }, [selectedContent]);

   const updateCoupon = (name, value) => {

      setCoupon((coupon) => {
         const updatedCoupon = cloneCoupon(coupon);
         if (name === 'tags') {
            updatedCoupon.tags = { ...updatedCoupon.tags, [selectedProjectId]: value }
         } else if (name === 'keywords') {
            updatedCoupon.keywords = value.join(',');
         } else if (name === 'projects') {
            const updatedTags = {};
            value.forEach(projectId => updatedTags[projectId] = updatedCoupon.tags[projectId]);
            updatedCoupon['projects'] = value;
            updatedCoupon['tags'] = updatedTags;
         } else {
            updatedCoupon[name] = value;
         }
         return updatedCoupon;
      });
   }

   const selectedProjectChangeHandler = projectId => {
      setSelectedProjectId(projectId);
   }

   const submitHandler = () => {
      const errors = coupon.isValid();
      setValidationErrors(errors);
      if (errors) return;
      switch (mode) {
         case 'new':
            dispatch(actions.content.addNewContent(coupon, 'coupon'));
            break;
         case 'edit':
            dispatch(actions.content.updateContent(coupon, 'coupon'));
            break;
      }
   }

   const loading = !projectsLoaded || !tagsLoaded || !categoriesLoaded || (mode !== 'new' && !contentLoaded) || requestPending;
   return (
      <ContentForm
         content={coupon}
         contentType="Coupon"
         validationErrors={validationErrors}
         errors={backendErrors.global}
         onChange={updateCoupon}
         onSubmit={submitHandler}
         projects={projects}
         selectedProjectId={selectedProjectId}
         projectSelectedHandler={selectedProjectChangeHandler}
         tags={tags}
         categories={categories}
         loading={loading}
         mode={mode}
      >
         {(content, onChange, errors, validationErrors, loading, mode) => {
            return (
               <CouponOptions
                  coupon={content}
                  onChange={onChange}
                  errors={errors}
                  validationErrors={validationErrors}
                  loading={loading}
                  mode={mode}
               />
            );
         }}
      </ContentForm>
   );
}

export default CouponForm;