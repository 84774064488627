import { FETCH_PROJECTS, FETCH_PROJECTS_SUCCESS, FETCH_PROJECTS_FAILURE, FETCH_PROJECT, FETCH_PROJECT_SUCCESS, FETCH_PROJECT_FAILURE, ADD_NEW_PROJECT, ADD_NEW_PROJECT_SUCCESS, ADD_NEW_PROJECT_FAILURE, RESET_PROJECTS_STATE, UPDATE_PROJECT, UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_FAILURE, CLONE_PROJECT, CLONE_PROJECT_SUCCESS, CLONE_PROJECT_FAILURE } from '../actions/actionTypes';
import { cloneProject } from '../../utilities/common'

const emptyProjectData = { id: '', factor_id: '', name: '', tld: 'com', content_type: 'game', status: 'ACTIVE', config: undefined, http_locked: 'NO', supports_https: 'YES' };
const initialState = {
   projectsLoaded: false,
   requestPending: false,
   projects: [],
   project: cloneProject(emptyProjectData),
   messages: '',
   errors: '',
}

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case FETCH_PROJECTS: 
         return {
            ...state,
            requestPending: true,
         }
      case FETCH_PROJECTS_SUCCESS:
         return {
            ...state,
            projectsLoaded: true,
            projects: action.projects,
            requestPending: false,
         }
      case FETCH_PROJECTS_FAILURE:
         return {
            ...state,
            projectsLoaded: false,
            projects: [],
            requestPending: false,
         }
      case FETCH_PROJECT:
         return {
            ...state,
            requestPending: true,
         }
      case FETCH_PROJECT_SUCCESS:
         return {
            ...state,
            project: action.project,
            requestPending: false,
         }
      case FETCH_PROJECT_FAILURE:
         return {
            ...state,
            project: false,
            requestPending: false,
         }
      case ADD_NEW_PROJECT:
         return {
            ...state,
            requestPending: true,
         }
      case ADD_NEW_PROJECT_SUCCESS:
         return {
            ...state,
            project: action.project,
            requestPending: false,
         }
      case ADD_NEW_PROJECT_FAILURE:
         return {
            ...state,
            project: null,
            errors: action.errors,
            requestPending: false,
         }
      case UPDATE_PROJECT:
         return {
            ...state,
            requestPending: true,
         }
      case UPDATE_PROJECT_SUCCESS:
         return {
            ...state,
            project: action.project,
            projectsLoaded: false,
            requestPending: false,
         }
      case UPDATE_PROJECT_FAILURE:
         return {
            ...state,
            errors: action.errors,
            requestPending: false,
         }
      case CLONE_PROJECT:
         return {
            ...state,
            requestPending: true
         }
      case CLONE_PROJECT_SUCCESS:
         return {
            ...state,
            project: action.project,
            projectsLoaded: false,
            requestPending: false,
         }
      case CLONE_PROJECT_FAILURE:
         return {
            ...state,
            errors: action.errors,
            requestPending: false,
         }
      case RESET_PROJECTS_STATE:
         return initialState;
      default:
         return state;
   }
}

export default reducer;