import { call, put } from 'redux-saga/effects';

import * as actions from '../actions/index';
import { processResponse } from '../../utilities/fetchUtils';


export function* fetchImpressions(action) {

   const body = { content_type: action.cType }
   if (action.from) body.date_from = action.from;

   try {
      const response = yield call(fetch, '/stat-content-impression-service/get-impressions', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify(body),
      });
      const responseData = yield call(processResponse, response);
      yield put(actions.stat.fetchImpressionsSuccess(responseData.data));
   } catch (e) {
      console.log(e);
      yield put(actions.stat.fetchImpressionsFailure(e.messages || e.message))
   }
}