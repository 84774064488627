import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Container, Divider, Form, Button, Message } from 'semantic-ui-react';
import classes from './Users.module.css';
import * as actions from '../../store/actions/index';


const ChangePassword = ({ props }) => {

   const userMessages = useSelector((state) => state.users.messages);
   const userErrors = useSelector((state) => state.users.errors);
   const [oldPassword, setOldPassword] = useState('');
   const [newPassword, setNewPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   let errorMsg = null;
   let confirmMsg = null;

   useEffect(() => {
      errorMsg = null;
      confirmMsg = null;
      return () => {
         dispatch(actions.user.resetUsersState());
      }
   }, []);

   const dispatch = useDispatch();

   const onOldPasswordChange = (e, { name, value }) => {
      confirmMsg = null;
      setOldPassword(value);
   }

   const onNewPasswordChange = (e, { name, value }) => {
      setNewPassword(value);
   }

   const onConfirmPasswordChange = (e, { name, value }) => {
      setConfirmPassword(value);
   }

   const changePasswordHandler = () => {
      dispatch(actions.user.changePassword(oldPassword, newPassword, confirmPassword));
   }

   if (userErrors) {
      let errors = "";
      Object.keys(userErrors).map(key => {
         errors += userErrors[key][0] + '\n';
      });
      confirmMsg = null;
      errorMsg = (
         <Message
            error
            header='Change password Failed'
            content={errors}
         />
      );
   }

   if (userMessages) {
      errorMsg = null;
      confirmMsg = (
         <Message
            positive
            header="You've changed your password successfully"
         />
      );
   }


   return (
      <Container>
         <Divider horizontal>Change password</Divider>
         <Container className={classes.ChangePasswordHolder}>
            <Form error={!!userErrors}>
               <Form.Field required>
                  <label>Old password</label>
                  <Form.Input name="oldpassword" type="password" value={oldPassword} onChange={onOldPasswordChange} />
               </Form.Field>
               <Form.Field required>
                  <label>New password</label>
                  <Form.Input name="newpassword" type="password" value={newPassword} onChange={onNewPasswordChange} />
               </Form.Field>
               <Form.Field required>
                  <label>Confirm new password</label>
                  <Form.Input name="confirmnewpassword" type="password" value={confirmPassword} onChange={onConfirmPasswordChange} />
               </Form.Field>
               {errorMsg}
               {confirmMsg}
               <Container textAlign="right">
                  <Button className={classes.SubmitButton} onClick={changePasswordHandler}>Change password</Button>
               </Container>
            </Form>
         </Container>
      </Container>
   );
}

export default ChangePassword;