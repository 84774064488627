import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Container, Dimmer, Loader } from 'semantic-ui-react';
import { Route, Redirect, Switch } from 'react-router';

import Header from '../../components/header/Header';
//import SideMenuSmall from '../../components/side-menu/SideMenuSmall';
import SideMenu from '../../components/side-menu/SideMenu';
import Footer from '../../components/footer/Footer'
import Auth from '../../components/auth/Auth';
import Messages from '../../components/common/ApplicationMessages';
import classes from './Layout.module.css';

const Layout = props => {
   let { children } = props;
   const authStatus = useSelector(state => state.auth.authenticated);

   const login = (
      <Grid container as="main" className={classes.content}>
         <Switch>
            <Route path='/login' component={Auth} />
            <Redirect to="/login" />
         </Switch>
         <Messages className={classes.Login}/>
      </Grid>
   );

   const grid = (
      <>
         <Header />
         <Container columns={16} className={classes.content}>
            <div className="one wide column">
               <SideMenu />
            </div>
            <div className="fifteen wide column">
               <Container as="main" className={classes.content}>
                  {children}
                  <Messages />
               </Container>
            </div>
         </Container>
         <Footer />
      </>
   );

   return (
      <>
         <Dimmer page active={authStatus === null}>
            <Loader size="massive" content="Loading" />
         </Dimmer>

         {authStatus === false ? login : grid}

      </>
   );
}

export default Layout;