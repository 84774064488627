import React from 'react';
import { Table, Icon } from 'semantic-ui-react';

import ImagePopup from '../common/ImagePopup';
import classes from './Table.module.css';

export const imagePopupRenderer = {
   image_1: {
      header: (key, content) => {
         return (
            <Table.HeaderCell
               key={key}
               className={key}
               singleLine
            >
               {content}
            </Table.HeaderCell>
         )
      },
      body: (data, index, content) => {
         return (
            <ImagePopup
               key={index}
               src={data[index]}
               basic={true}
               positionFixed={true}
               // on="click"
               style={{
                  top: '50vh',
                  left: '50vw',
                  transform: 'translate(-50%, -50%)'
               }}
               // }}
               trigger={(
                  <Table.Cell textAlign="center">
                     <Icon name="image" className={classes.TableCellIcon} />
                  </Table.Cell>
               )}
            />
         )
      }
   }
}

export const shortDateRenderer = {
   date: {
      body: (data, index, content) => {
         return (
            <Table.Cell key={index} textAlign="center">{data[index].slice(0, 10)}</Table.Cell>
         );
      }
   }
}