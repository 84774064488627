import React from 'react';
import { Form } from 'semantic-ui-react';

export const contentTypes = [
   'game',
   'gamedeal',
   'gamenews',
   'gamewallpaper',
   'speedtest',
   'quiz',
   'video',
   'maps',
   'coupon',
   'weather',
   'package',
   'apps',
   'prototype',
   'news',
   'classifieds',
   'fileconverter',
   'tv',
   'noads',
   'forms',
   'radio',
   'recipe',
   'astrology',
   'streaming',
   'sports',
   'quotes',
   'health',
   'books',
   'login',
   'manuals',
   'vacation',
   'qrcode',
   'unknown',
].map(content => ({ text: content.toUpperCase(), value: content }));

const ContentTypeSelect = ({name='content_type', value='game', label='Content Type:', disabled, onChange, error}) => {
   return (
      <Form.Select label={label} name={name} value={value} options={contentTypes} disabled={disabled} onChange={(e, {name, value}) => onChange(name, value)} error={error} />
   );
}

export default ContentTypeSelect;