import { validate } from 'validate.js';

class Game {
   width;
   height;
   technology;
   instructions;
   image_1;
   image_2;
   image_3;
   image_4;
   image_5;
   screenshot_1;
   screenshot_2;
   screenshot_3;
   screenshot_4;
   screenshot_5;
   screenshot_6;
   gif;
   video;
   youtube_video_1;
   youtube_video_2;
   youtube_video_3;
   game_file;
   html5_zip;
   game_url;
   embed_config;
   review_urls;
   guide_urls;
   id;
   content_id;
   type;
   title;
   description;
   keywords;
   categories;
   tags;
   media;
   projects;
   config;
   status;
   creation_datetime;
   update_datetime;
   scenario = scenarios.DEFAULT;
   labels = { 
		description : 'Description',
		instructions : 'Instructions',
		keywords : 'Keywords',
		technology : 'Technology',
		width : 'Width',
		height : 'Height',
		review_urls : 'Review URLs',
		guide_urls : 'Guide URLs',
		video : 'Video 1',
		youtube_video_1 : 'Youtube video 1 ID',
		youtube_video_2 : 'Youtube video 2 ID',
		youtube_video_3 : 'Youtube video 3 ID',
		image_1 : 'Image 1 (100x100)',
		image_2 : 'Image 2 (200x200)',
		image_3 : 'Image 3 (260x320)',
		image_4 : 'Image 4 (556x143)',
		image_5 : 'Image 5 (300x250)',
		gif : 'Gif animation',
		game_file : 'Game file (.unity3d)',
		screenshot_1 : 'Screenshot 1',
		screenshot_2 : 'Screenshot 2',
		screenshot_3 : 'Screenshot 3',
		screenshot_4 : 'Screenshot 4',
		screenshot_5 : 'Screenshot 5',
		screenshot_6 : 'Screenshot 6',
		id : 'ID',
		content_id : 'Content ID',
		type : 'Type',
		title : 'Title',
		categories : 'Categories',
		tags : 'Tags',
		media : 'Media',
		projects : 'Projects',
		config : 'Config',
		status : 'Status',
		creation_datetime : 'Created',
		update_datetime : 'Updated',
	};
   
   constructor (width, height, technology, instructions, image_1, image_2, image_3, image_4, image_5, screenshot_1, screenshot_2, screenshot_3, screenshot_4, screenshot_5, screenshot_6, gif, video, youtube_video_1, youtube_video_2, youtube_video_3, game_file, html5_zip, game_url, embed_config, review_urls, guide_urls, id, content_id, type, title, description, keywords, categories, tags, media, projects, config, status, creation_datetime, update_datetime) {
      this.width = width;
      this.height = height;
      this.technology = technology;
      this.instructions = instructions;
      this.image_1 = image_1;
      this.image_2 = image_2;
      this.image_3 = image_3;
      this.image_4 = image_4;
      this.image_5 = image_5;
      this.screenshot_1 = screenshot_1;
      this.screenshot_2 = screenshot_2;
      this.screenshot_3 = screenshot_3;
      this.screenshot_4 = screenshot_4;
      this.screenshot_5 = screenshot_5;
      this.screenshot_6 = screenshot_6;
      this.gif = gif;
      this.video = video;
      this.youtube_video_1 = youtube_video_1;
      this.youtube_video_2 = youtube_video_2;
      this.youtube_video_3 = youtube_video_3;
      this.game_file = game_file;
      this.html5_zip = html5_zip;
      this.game_url = game_url;
      this.embed_config = embed_config;
      this.review_urls = review_urls;
      this.guide_urls = guide_urls;
      this.id = id;
      this.content_id = content_id;
      this.type = type;
      this.title = title;
      this.description = description;
      this.keywords = keywords;
      this.categories = categories;
      this.tags = tags;
      this.media = media;
      this.projects = projects;
      this.config = config;
      this.status = status;
      this.creation_datetime = creation_datetime;
      this.update_datetime = update_datetime;
   }

   isValid = () => {
      const defaultErr = validate(this, constraints[Game.scenarios.DEFAULT]);
      if (this.scenario === Game.scenarios.DEFAULT) return defaultErr;
      const err = validate(this, constraints[this.scenario]);
      if(!defaultErr && !err) return undefined;

      let allErrors = {...defaultErr};
      if (err) {
         Object.keys(err).forEach(key => {
            if(allErrors[key]) allErrors[key] = [...allErrors[key], ...err[key]];
            else allErrors[key] = err[key]; 
         });
      }

      return allErrors;
   }
}

const scenarios = {
   DEFAULT: "DEFAULT",
}

const constraints = {
    "DEFAULT": {
        "width": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "numericality": {
                "onlyInteger": true,
                "message": "should be positive integer",
                "greaterThanOrEqualTo": 1
            }
        },
        "height": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "numericality": {
                "onlyInteger": true,
                "message": "should be positive integer",
                "greaterThanOrEqualTo": 1
            }
        },
        "technology": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "inclusion": {
                "within": [
                    "external",
                    "html5",
                    "iframe",
                    "unity"
                ],
                "message": "is invalid"
            }
        },
        "instructions": {
            "length": {
                "message": "should be at most 256 symbols",
                "maximum": 256
            }
        },
        "game_url": {
            "length": {
                "message": "should be at most 256 symbols",
                "maximum": 256
            }
        },
        "youtube_video_1": {
            "length": {
                "message": "should be at most 15 symbols",
                "maximum": 15
            }
        },
        "youtube_video_2": {
            "length": {
                "message": "should be at most 15 symbols",
                "maximum": 15
            }
        },
        "youtube_video_3": {
            "length": {
                "message": "should be at most 15 symbols",
                "maximum": 15
            }
        },
        "embed_config": {
            "length": {
                "message": "should be at most 512 symbols",
                "maximum": 512
            }
        },
        "type": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            }
        },
        "title": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "length": {
                "message": "should be at most 2048 symbols",
                "maximum": 2048
            }
        },
        "status": {
            "presence": {
                "allowEmpty": false,
                "message": "is required"
            },
            "inclusion": {
                "within": [
                    "ACTIVE",
                    "INACTIVE"
                ],
                "message": "is invalid"
            }
        },
        "description": {
            "length": {
                "message": "should be at most 4096 symbols",
                "maximum": 4096
            }
        },
        "keywords": {
            "length": {
                "message": "should be at most 512 symbols",
                "maximum": 512
            }
        }
    }
}

Game.scenarios = scenarios;
Game.constraints = constraints;

export default Game;
