import React, { useState } from 'react';
import { Accordion, Menu, Image, Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import SideMenuItem from './SideMenuItem';
import items from './items';
import classes from './SideMenu.module.css';

import logo from './logo.png';

const SideMenu = props => {
   const [activeIndex, setActiveIndex] = useState(-1);

   const clickHandler = (e, { index }) => {
      setActiveIndex(index === activeIndex ? -1 : index);
   }

   const sideMenuItems = items.map((item, index) => {
      return (
         <SideMenuItem
            requiredPermissions={item.permissions}
            key={'side-' + item.title}
            title={item.title}
            index={index}
            icon={item.icon}
            path={item.path}
            menuItems={item.items}
            active={index === activeIndex}
            clickHandler={clickHandler}
         />

      );
   });
   return (
      <Container className={classes.SideMenu} textAlign="center">
         <Accordion as={Menu} fixed='left' vertical className={classes.SideMenu}>
            <Container as={Link} to="/" className="logo container">
               <Image id="project-logo" centered={true} src={logo} size='mini' />
            </Container>
            {sideMenuItems}
         </Accordion>
      </Container>
   );
}

export default SideMenu;