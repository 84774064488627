import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import classes from './BackButton.module.css'
const BackButton = props => {
   const history = useHistory();

   const clickHandler = () => {
      history.goBack();
   }
   return (
      <Button className={classes.BackButton} onClick={clickHandler}>Back</Button>
   );
}

export default BackButton;