import React, { useState, useEffect } from 'react';
import { Image } from 'semantic-ui-react';

import { getUniqueId } from '../../utilities/common';

const NoCacheImage = ({ src, className }) => {

   const [imageSrc, setImageSrc] = useState();

   useEffect(() => {
      let image = `${src}?ver=${getUniqueId()}`;
      if (!src || src.includes('data:')) {
         image = src;
      }
      setImageSrc(image);
   }, [src]);

   return (
      <Image src={imageSrc} className={className} />
   );
}

export default NoCacheImage;