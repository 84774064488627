import React, { useEffect } from 'react';
import { Container, Form, Divider, Message, Label } from 'semantic-ui-react';

import SubmitButton from '../common/SubmitButton';
import BackButton from '../common/BackButton';
import classes from './AllowedIps.module.css';
import { formatValidationErrors } from '../../utilities/common';

const IpForm = ({ formTitle, ip_add, validationErrors, errors, onSubmit, onChange, loading, mode, disabled, onEditorChange }) => {
   validationErrors = formatValidationErrors(validationErrors || {});
   const { ip, description } = validationErrors;
   const { global} = errors || {};

  
   return (
      <>
         <Container >
            <Divider horizontal>{formTitle}</Divider>
         </Container>
         <Container className={classes.FormContainer}>
            <Form loading={loading} className={classes.Form} disabled={disabled}>
               <Form.Field>
                  <Form.Input label="Ip Address:" type="text" name="ip" value={ip_add.ip} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} error={ip || null} />
               </Form.Field>

               <Form.Field>
                  <Form.Input label="Description:" type="text" name="description" value={ip_add.description} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} error={description || null} />
               </Form.Field>

            </Form>
            <Label className={classes.CdnCachExample} horizontal>
               IP address can be entered with mask, i.e. 74.120.18.0/23
            </Label>
            {global ? (
               <Message
                  error
                  header='IP not added'
                  content={global}
               ></Message>
            ) : null}
            <Container textAlign="right">
               <BackButton />
               <SubmitButton submitHandler={onSubmit} disabled={disabled} />
            </Container>
         </Container>
      </>
   )
}

export default IpForm;