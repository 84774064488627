import {
   FETCH_IPS,
   FETCH_IPS_SUCCESS,
   FETCH_IPS_FAILURE,
   FETCH_IP,
   FETCH_IP_SUCCESS,
   FETCH_IP_FAILURE,
   ADD_NEW_IP,
   ADD_NEW_IP_SUCCESS,
   ADD_NEW_IP_FAILURE,
   UPDATE_IP,
   UPDATE_IP_SUCCESS,
   UPDATE_IP_FAILURE,
   RESET_IP_STATE
} from '../actions/actionTypes';

export const fetchIps = () => {
   return {
      type: FETCH_IPS,
   }
}

export const fetchIpsSuccess = ips => {
   return {
      type: FETCH_IPS_SUCCESS,
      ips,
   }
}

export const fetchIpsFailure = errors => {
   return {
      type: FETCH_IPS_FAILURE,
      errors,
   }
}

export const fetchIp = id => {
   return {
      type: FETCH_IP,
      id,
   }
}

export const fetchIpSuccess = ip => {
   return {
      type: FETCH_IP_SUCCESS,
      ip,
   }
}

export const fetchIpFailure = errors => {
   return {
      type: FETCH_IP_FAILURE,
      errors,
   }
}

export const addNewIp = ip => {
   return {
      type: ADD_NEW_IP,
      ip,
   }
}

export const addNewIpSuccess = ip => {
   return {
      type: ADD_NEW_IP_SUCCESS,
      ip,
   }
}

export const addNewIpFailure = errors => {
   return {
      type: ADD_NEW_IP_FAILURE,
      errors,
   }
}

export const updateIp = ip => {
   return {
      type: UPDATE_IP,
      ip,
   }
}

export const updateIpSuccess = ip => {
   return {
      type: UPDATE_IP_SUCCESS,
      ip,
   }
}

export const updateIpFailure = errors => {
   return {
      type: UPDATE_IP_FAILURE,
      errors,
   }
}

export const resetIpState = () => {
   return {
      type: RESET_IP_STATE,
   }
}