import { useSelector } from 'react-redux';

import { checkPermissions } from '../utilities/common'

const AccessControl = ({ requiredPermissions, children }) => {
   const userPermissions = useSelector(state => state.auth.loggedUser).permissions || [];
   let renderProtectedComponent = checkPermissions(requiredPermissions, userPermissions);
   const result = renderProtectedComponent ? children : null;
   return (
      result
   );
}

export default AccessControl;
