import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FagcTable from '../table/FagcTable'
import withModel from '../../hoc/table/withModel';
import LegalTemplate from '../../models/LegalTemplate';
import AddNewLegal from '../common/AddNewButton';
import * as actions from '../../store/actions/index';
import withPermissions from '../../hoc/withPermissions';

const Table = withPermissions(withModel(FagcTable, LegalTemplate));

const LegalHome = ({ path }) => {
   const legalsLoaded = useSelector(state => state.legals.legalsLoaded);
   const legals = useSelector(state => state.legals.legals);
   const dispatch = useDispatch();

   useEffect(() => {
      if (!legalsLoaded) {
         dispatch(actions.legals.fetchLegals());
      }
   }, [legalsLoaded, dispatch]);

   return (
      <>
         <AddNewLegal text="Add new legal document" to={`${path}/new`} requiredPermissions={{ any: ['manage_legal_template'] }} />
         <Table data={legals} actions={{
            eye: { path: data => `${path}/view/${data.id}`, permissions: { any: ['access_all_legals'] } },
            edit: { path: data => `${path}/edit/${data.id}`, permissions: { any: ['manage_legal_template'] } },
         }} requiredPermissions={{ any: ['access_all_legals'] }} />
      </>
   );
}

export default LegalHome;