import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Dashboard from './components/dashboard/Dashboard';
import Reports from './components/reports/Reports';
import Projects from './components/projects/Projects';
import Tags from './components/tags/Tags';
import Categories from './components/categories/Categories';
import Content from './components/content/Content';
import Users from './components/users/Users';
import Legals from './components/legal/Legals';
import CdnCache from './components/tools/CdnCache';
import AllowedIps from './components/allowedip/AllowedIps';
import ChangePassword from './components/users/ChangePassword';
import Layout from './hoc/Layout/Layout';
import * as actions from './store/actions/index';
import AuthorizedRoute from './components/common/AuthorizedRoute';

function App() {
   const dispatch = useDispatch();
   const authStatus = useSelector(state => state.auth.authenticated);
   const redirectPath = useSelector(state => state.app.redirectTo);
   const history = useHistory();
   let routes;

   if (authStatus) {
      routes = (
         <Switch>
            <AuthorizedRoute path="/reports/:cType" component={Reports} requiredPermissions={{ any: ['content_report'] }} />
            <AuthorizedRoute path="/games" component={Content} requiredPermissions={{ any: ['create_content', 'update_content', 'access_all_contents'] }} />
            <AuthorizedRoute path="/game-deals" component={Content} requiredPermissions={{ any: ['create_content', 'update_content', 'access_all_contents'] }} />
            <AuthorizedRoute path="/all-game-news" component={Content} requiredPermissions={{ any: ['create_content', 'update_content', 'access_all_contents'] }} />
            <AuthorizedRoute path="/game-wallpapers" component={Content} requiredPermissions={{ any: ['create_content', 'update_content', 'access_all_contents'] }} />
            <AuthorizedRoute path="/quizzes" component={Content} requiredPermissions={{ any: ['create_content', 'update_content', 'access_all_contents'] }} />
            <AuthorizedRoute path="/coupons" component={Content} requiredPermissions={{ any: ['create_content', 'update_content', 'access_all_contents'] }} />
            <AuthorizedRoute path="/recipes" component={Content} requiredPermissions={{ any: ['create_content', 'update_content', 'access_all_contents'] }} />
            <AuthorizedRoute path="/tools" component={Content} requiredPermissions={{ any: ['create_content', 'update_content', 'access_all_contents'] }} />
            <AuthorizedRoute path="/categories" component={Categories} requiredPermissions={{ any: ['create_category', 'update_category', 'access_all_categories'] }} />
            <AuthorizedRoute path="/tags" component={Tags} requiredPermissions={{ any: ['create_tag', 'update_tag', 'access_all_tags'] }} />
            <AuthorizedRoute path="/projects" component={Projects} requiredPermissions={{ any: ['create_project', 'update_project', 'access_all_projects'] }} />
            <AuthorizedRoute path="/legal-docs" component={Legals} requiredPermissions={{ any: ['access_all_legals', 'manage_legal_template'] }} />
            <AuthorizedRoute path="/users" component={Users} requiredPermissions={{ any: ['access_all_users', 'update_user', 'create_user'] }} />
            <AuthorizedRoute path="/cdn-cache" component={CdnCache} requiredPermissions={{ any: ['clear_cdn'] }} />
            <AuthorizedRoute path="/allowed-ips" component={AllowedIps} requiredPermissions={{ any: ['allowed_ip'] }} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/" exact component={Dashboard} />
            <Redirect to='/' />
         </Switch>
      );
   }

   useEffect(() => {
      if (authStatus === null) {
         dispatch(actions.auth.authCheck());
      }
   }, [dispatch, authStatus]);

   // this effect handles redirecting using actions.app.redirectTo(path) action creator
   useEffect(() => {
      if (redirectPath && redirectPath !== history.location.pathname) {
         history.push(redirectPath);
         dispatch(actions.app.resetRedirect());
      }
   }, [redirectPath, dispatch, history]);

   return (
      <Layout>
         {routes}
      </Layout>
   );
}

export default App;
