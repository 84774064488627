import { FETCH_IMPRESSIONS, FETCH_IMPRESSIONS_SUCCESS, FETCH_IMPRESSIONS_FAILURE, SET_IMPRESSIONS_FROM_TIME } from '../actions/actionTypes';

export const setImpressionsFromTime = from => (
   {
      type: SET_IMPRESSIONS_FROM_TIME,
      from,
   }
);

export const fetchImpressions = from => (
   {
      type: FETCH_IMPRESSIONS,
      from,
   }
);

export const fetchImpressionsSuccess = impressions => (
   {
      type: FETCH_IMPRESSIONS_SUCCESS,
      impressions,
   }
);

export const fetchImpressionsFailure = error => (
   {
      type: FETCH_IMPRESSIONS_FAILURE,
      error,
   }
);