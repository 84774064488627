import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ContentForm from '../ContentForm';
import GameOptions from './GameOptions';
import { cloneGame } from '../../../utilities/common';
import useContent from '../../../hooks/useContent';
import * as actions from '../../../store/actions/index';

const GameForm = ({ mode }) => {

   const emptyGame = cloneGame({
       width: "",
       height: "",
       technology: "",
       image_1: "",
       image_2: "",
       image_3: "",
       image_4: "",
       image_5: "",
       id: "",
       content_id: "",
       title: "",
       categories: [],
       projects: [],
       status: "ACTIVE",
       tags: {},
       keywords: "",
       review_urls: [],
       guide_urls: [],
       instructions: "",
       youtube_video_1: "",
       youtube_video_2: "",
       youtube_video_3: "",
   });

   const gameId = useParams().id;
   const {
      projectsLoaded,
      projects,
      tagsLoaded,
      tags,
      categoriesLoaded,
      categories,
      backendErrors,
      contentLoaded,
      selectedContent,
      requestPending,
      dispatch
   } = useContent('game');

   let [validationErrors, setValidationErrors] = useState(undefined);
   const [selectedProjectId, setSelectedProjectId] = useState();
   const [game, setGame] = useState(emptyGame);
   // form can not be submitted until validationErrors === undefined, after submit backend can send aditional errors related to fields 
   validationErrors = validationErrors || backendErrors;

   useEffect(() => {
      if (!projectsLoaded) dispatch(actions.projects.fetchProjects());
      if (!tagsLoaded) dispatch(actions.tags.fetchTags());
      if (!categoriesLoaded) dispatch(actions.categories.fetchCategories());
      if (mode !== 'new' && !contentLoaded) dispatch(actions.content.fetchContent(gameId, 'game'));
      return () => dispatch(actions.content.resetContentState());
   }, []);

   useEffect(() => {
      if (selectedContent && mode !== 'new')
         setGame(selectedContent);
   }, [selectedContent])

   const updateGame = (name, value) => {

      setGame((game) => {
         const updatedGame = cloneGame(game);
         if (name === 'tags') {
            updatedGame.tags = { ...updatedGame.tags, [selectedProjectId]: value }
         } else if (name === 'keywords') {
            updatedGame.keywords = value.join(',');
         } else if (name === 'projects') {
            const updatedTags = {};
            value.forEach(projectId => updatedTags[projectId] = updatedGame.tags[projectId]);
            updatedGame['projects'] = value;
            updatedGame['tags'] = updatedTags;
         } else {
            updatedGame[name] = value;
         }
         return updatedGame;
      });
   }

   const selectedProjectChangeHandler = projectId => {
      setSelectedProjectId(projectId);
   }

   const submitHandler = () => {
      const errors = game.isValid();
      setValidationErrors(errors);
      if (errors) return;
      // delete all game technology extra values
      const technologyOpt = {
         'unity': 'game_file',
         'html5': 'html5_zip',
         'iframe': 'embed_config',
         'external': 'game_url',
      }
      delete technologyOpt[game.technology];
      Object.values(technologyOpt).forEach(prop => delete game[prop]);

      switch (mode) {
         case 'new':
            dispatch(actions.content.addNewContent(game, 'game'));
            break;
         case 'edit':
            dispatch(actions.content.updateContent(game, 'game'));
            break;
      }
   }

   const loading = !projectsLoaded || !tagsLoaded || !categoriesLoaded || (mode !== 'new' && !contentLoaded) || requestPending;
   return (
      <ContentForm
         content={game}
         contentType='Game'
         validationErrors={validationErrors}
         errors={backendErrors.global}
         onChange={updateGame}
         onSubmit={submitHandler}
         projects={projects}
         selectedProjectId={selectedProjectId}
         projectSelectedHandler={selectedProjectChangeHandler}
         tags={tags}
         categories={categories}
         loading={loading}
         mode={mode}
      >
         {(content, onChange, errors, validationErrors, loading, mode) => {
            return (
               <GameOptions
                  game={content}
                  onChange={onChange}
                  errors={errors}
                  validationErrors={validationErrors}
                  loading={loading}
                  mode={mode}
               />
            );
         }}
      </ContentForm>
   );
}

export default GameForm;