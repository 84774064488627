import { call, put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { cloneTag, cloneTags } from '../../utilities/common';
import { processResponse } from '../../utilities/fetchUtils';

export function* fetchTagsSaga(action) {
   try {
      const response = yield call(fetch, '/tag-service/get-tags', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            level: action.level,
         }),
      });
      const tagsData = yield call(processResponse, response);
      const tags = yield call(cloneTags, tagsData.data);
      yield put(actions.tags.fetchTagsSuccess(tags));
   } catch (e) {
      console.log(e);
      yield put(actions.tags.fetchTagsFailure(e.messages || e.message));
   }
}

export function* addNewTagSaga(action) {
   const { name, level, description } = action.tag;
   try {
      const response = yield call(fetch, '/tag-service/create-tag', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            name,
            level,
            description,
         }),
      });
      const tagData = yield call(processResponse, response);
      const tag = yield call(cloneTag, tagData.data);
      yield put(actions.tags.addNewTagSuccess(tag));
      yield put(actions.app.redirectTo('/tags'));
   } catch (e) {
      console.log(e);
      yield put(actions.tags.addNewTagFailure(e.message || e.messages));
   }
}

export function* fetchTagSaga(action) {
   try {
      const response = yield call(fetch, '/tag-service/get-tag', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            id: action.id,
         }),
      });
      const tagData = yield call(processResponse, response);
      const tag = yield call(cloneTag, tagData.data);
      yield put(actions.tags.fetchTagSuccess(tag));
   } catch (e) {
      console.log(e);
      yield put(actions.tags.fetchTagsFailure(e.message || e.messages));
   }
}

export function* updateTagSaga(action) {
   const { id, name, level, description } = action.tag;
   try {
      const response = yield call(fetch, '/tag-service/update-tag', {
         method: 'POST',
         headers: {
            'Content-type': 'application/json',
         },
         body: JSON.stringify({
            id,
            name,
            level,
            description,
         }),
      });
      const tagData = yield call(processResponse, response);
      const tag = yield call(cloneTag, tagData.data);
      yield put(actions.tags.updateTagSuccess(tag));
      yield put(actions.app.redirectTo('/tags'));

   } catch (e) {
      console.log(e);
      yield put(actions.tags.updateTagFailure(e.message || e.messages))
   }
}
