import { FETCH_TAGS, FETCH_TAGS_SUCCESS, FETCH_TAGS_FAILURE, FETCH_TAG, FETCH_TAG_SUCCESS, FETCH_TAG_FAILURE, ADD_NEW_TAG, ADD_NEW_TAG_SUCCESS, ADD_NEW_TAG_FAILURE, UPDATE_TAG, UPDATE_TAG_SUCCESS, UPDATE_TAG_FAILURE, RESET_TAGS_STATE } from './actionTypes'

export const fetchTags = (level='all') => {
   return {
      type: FETCH_TAGS,
      level,
   }
}

export const fetchTagsSuccess = (tags) => {
   return {
      type: FETCH_TAGS_SUCCESS,
      tags,
   }
}

export const fetchTagsFailure = (errors) => {
   return {
      type: FETCH_TAGS_FAILURE,
      errors,
   }
}

export const fetchTag = (id) => {
   return {
      type: FETCH_TAG,
      id,
   }
}

export const fetchTagSuccess = (tag) => {
   return {
      type: FETCH_TAG_SUCCESS,
      tag,
   }
}

export const fetchTagFailure = (errors) => {
   return {
      type: FETCH_TAG_FAILURE,
      errors,
   }
}

export const updateTag = tag => {
   return {
      type: UPDATE_TAG,
      tag,
   }
}

export const updateTagSuccess = tag => {
   return {
      type: UPDATE_TAG_SUCCESS,
      tag: tag,
   }
}

export const updateTagFailure = errors => {
   return {
      type: UPDATE_TAG_FAILURE,
      errors,
   }
}

export const addNewTag = tag => {
   return {
      type: ADD_NEW_TAG,
      tag,
   }
}
export const addNewTagSuccess = tag => {
   return {
      type: ADD_NEW_TAG_SUCCESS,
      tag,
   }
}
export const addNewTagFailure = errors => {
   return {
      type: ADD_NEW_TAG_FAILURE,
      errors,
   }
}

export const resetTagsState = () => {
   return {
      type: RESET_TAGS_STATE
   }
}