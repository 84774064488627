import React from 'react';
import { Container, Form, Divider, Message } from 'semantic-ui-react';

import SubmitButton from '../common/SubmitButton';
import BackButton from '../common/BackButton';
import ContentTypeSelect from '../common/ContentTypeSelect';
import classes from './ProjectForm.module.css';
import { formatValidationErrors } from '../../utilities/common';

const ProjectForm = ({ title, project, validationErrors, errors, onSubmit, onChange, loading, mode, disabled }) => {
   validationErrors = formatValidationErrors(validationErrors || {});
   const { name, factor_id, content_type, tld, http_locked, supports_https, status } = validationErrors ;

   let errorList;
   if (Array.isArray(errors)) {
      errorList = errors
   } else {
      errorList = [errors]
   }

   return (
      <>
         <Container >
            <Divider horizontal>{title}</Divider>
         </Container>
         <Container className={classes.FormContainer}>
            <Form loading={loading} className={classes.Form} disabled={disabled}>
               <Form.Field>
                  <Form.Input label="Project Name:" type="text" name="name" value={project.name} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} error={name || null} />
               </Form.Field>
               <Form.Field>
                  <Form.Input label="Factor ID:" type="text" name="factor_id" value={project.factor_id} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} error={factor_id || null} />
               </Form.Field>
               <Form.Field>
                  <ContentTypeSelect value="game" onChange={onChange} value={project.content_type} disabled={disabled} error={content_type || null} disabled={mode === 'clone' || disabled}/>
               </Form.Field>
               <Form.Field>
                  <Form.Input label="Top level domain:" type="text" name="tld" value={project.tld} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} error={tld || null} />
               </Form.Field>
               <Form.Field>
                  <Form.Select label="HTTP Locked:" name="http_locked" value={project.http_locked} disabled={disabled} options={[{ text: 'YES', value: 'YES' }, { text: 'NO', value: 'NO' }]} onChange={(e, { name, value }) => onChange(name, value)} error={http_locked || null} />
               </Form.Field>
               <Form.Field>
                  <Form.Select label="HTTPS:" name="supports_https" value={project.supports_https} disabled={disabled} options={[{ text: 'YES', value: 'YES' }, { text: 'NO', value: 'NO' }]} onChange={(e, { name, value }) => onChange(name, value)} error={supports_https || null} />
               </Form.Field>
               <Form.Field>
                  <Form.Select label="Project Status:" name="status" value={project.status} disabled={disabled} options={[{ text: 'ACTIVE', value: 'ACTIVE' }, { text: 'INACTIVE', value: 'INACTIVE' }]} onChange={(e, { name, value }) => onChange(name, value)} error={status || null} />
               </Form.Field>
            </Form>
            {errors ? (
               <Message
                  error
                  header='Project not saved'
                  list={errorList}
               ></Message>
            ) : null}
            <Container textAlign="right">
               <BackButton />
               <SubmitButton submitHandler={onSubmit} disabled={disabled}/>
            </Container>
         </Container>
      </>
   )
}

export default ProjectForm;