import React from 'react';

import { useState } from 'react';
import * as actions from '../../store/actions/index';
import { Link} from 'react-router-dom';
import { useDispatch,  } from 'react-redux';
import { Button, Icon, Label, Card } from 'semantic-ui-react';

import classes from './Header.module.css';


const LoggedUser = ({loggedUser}) => {

   const dispatch = useDispatch();
   let expandLoggedUserComponent = '';
   const [logoutCard, setLogoutCard] = useState(false);
   const fullName = `${loggedUser.first_name} ${loggedUser.last_name}`
   
   const logoutHandler = () => {
      dispatch(actions.auth.logout());
      setLogoutCard(!logoutCard);
   }
   
   if ( logoutCard ) {
      expandLoggedUserComponent = (
         <Card className={classes.LogoutHolder}>
            <Button as={Link} to="/change-password" icon
               id='change-password-button' 
               className={classes.LogoutButton}
               labelPosition='left'
               onClick={() => setLogoutCard(!logoutCard)}>
               <Label id='change-password-label' content={'Change password'}/>
               <Icon name='edit' color='green' size='large'/>
            </Button>
            <Button icon
               id='my-custom-logout-button' 
               className={classes.LogoutButton}
               onClick={logoutHandler}
               labelPosition='left'>
               <Label id='logout-label' content={'Logout'}/>
               <Icon name='sign-out' color='green' size='large'/>
            </Button>
         </Card>
      )
   }

   return (
      <div>
         <Button icon
            id='expand-logged-user-component'
            className={classes.LoggedUserButton}
            onClick={() => setLogoutCard(!logoutCard)}
            labelPosition='right'>
            <Label>{fullName}</Label>
            <Icon name='user circle' color='green' size='large'/>
         </Button>
         {expandLoggedUserComponent}
      </div>
   );
}

export default LoggedUser;