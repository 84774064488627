import React from 'react';
import { Container, Form, Divider, Message } from 'semantic-ui-react';

import SubmitButton from '../common/SubmitButton';
import BackButton from '../common/BackButton';
import classes from './CategoriesForm.module.css';
import { formatValidationErrors } from '../../utilities/common';

const CategoryForm = ({ title, category, loading, mode, disabled, errors, validationErrors, onChange, onSubmit }) => {
   validationErrors = formatValidationErrors(validationErrors || {});
   const { id, name, level } = validationErrors;
   const options = [
      'coupon',
      'game',
      'recipe',
      'quiz',
      'apps',
      'gamedeal',
      'gamenews'
   ].map(item => ({ key: item, text: item, value: item }))

   let errorList;
   if (Array.isArray(errors)) {
      errorList = errors
   } else {
      errorList = [errors]
   }

   return (
      <>
         <Container>
            <Divider horizontal>{title}</Divider>
         </Container>
         <Container className={classes.FormContainer} >
            <Form className={classes.Form} loading={loading} disabled={disabled}>
               <Form.Input label="Name:" type="text" name="name" value={category.name} disabled={disabled} onChange={(e, { name, value }) => onChange(name, value)} error={name || null} />
               <Form.Select label="Levels:" placeholder='Levels' name="level" value={category.level} disabled={disabled} multiple selection options={options} onChange={(e, { name, value }) => onChange(name, value)} error={level || null} />
            </Form>
            {errors ? (
               <Message
                  error
                  header='Category not saved'
                  list={errorList}
               ></Message>
            ) : null}
            <Container textAlign="right">
               <BackButton />
               <SubmitButton submitHandler={onSubmit} disabled={disabled} />
            </Container>
         </Container>
      </>
   );
}

export default CategoryForm;