import { LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILURE, AUTH_CHECK, PASSWORD_RESET, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAILURE, RESET_AUTH_STATE } from './actionTypes';

export const login = (username, password, remember) => {
   return {
      type: LOGIN,
      username,
      password,
      remember,
   }
}

export const loginSuccess = ({ id, username, status, first_name, last_name, email, permissions, note }) => {
   return {
      type: LOGIN_SUCCESS,
      id,
      username,
      status,
      firstName: first_name,
      lastName: last_name,
      email,
      permissions,
      note,
   }
}

export const loginFailure = errors => {
   return {
      type: LOGIN_FAILURE,
      errors,
   }
}

export const logout = () => {
   return {
      type: LOGOUT,
   }
}

export const logoutSuccess = () => {
   return {
      type: LOGOUT_SUCCESS,
   }
}

export const logoutFailure = () => {
   return {
      type: LOGOUT_FAILURE,
   }
}

export const passwordReset = email => {
   return {
      type: PASSWORD_RESET,
      email,
   }
}

export const passwordResetSuccess = data => {
   return {
      type: PASSWORD_RESET_SUCCESS,
      messages: data.messages,
   }
}

export const passwordResetFailure = errors => {
   return {
      type: PASSWORD_RESET_FAILURE,
      errors,
   }
}

export const authCheck = () => {
   return {
      type: AUTH_CHECK,
   };
}

export const resetAuthState = () => {
   return {
      type: RESET_AUTH_STATE,
   }
}
